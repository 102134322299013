


































































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { AdditionalCostPresaleDetailsView } from '@/services/iOrderService';

@Component({})
export default class PgaOfferEvaluationAdditionalCostsPresaleDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: AdditionalCostPresaleDetailsView;

	get businessLine() : string
	{
		return this.Model.BusinessLine;
	}

	get techArea() : string
	{
		return this.Model.TechArea;
	}

	get supplyTipology() : string
	{
		return this.Model.SupplyTipology;
	}

	get professionalFigure() : string
	{
		return this.Model.Professional;
	}

	get company() : string
	{
		return this.Model.Company;
	}

	get resourceName() : string
	{
		return this.Model.ResourceName;
	}

	get daysMen() : number
	{
		return this.Model.DaysMen;
	}

	get dailyCost() : number
	{
		return this.Model.DailyCost;
	}

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}
}
