

















































import Vue from "vue";
import { Component, Model} from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { PgaValutazioneOffertaHeaderCreateModel } from "../store";

@Component({
  computed: {
    cliente: {
      get() {
        return this.$props.Model.Cliente;
      }
    },
    descrizione: {
      get() {
        return this.$props.Model.Descrizione;
      }
    },
    protocollo: {
      get() {
        return this.$props.Model.Protocollo;
      }
    },
    tipologia: {
      get() {
        return this.$props.Model.TipologiaOfferta;
      }
    },
    imponibileComplessivo: {
      get() {
        //  return this.$props.Model2.ImponibileComplessivo;
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaProgettoACorpoCreate
          .ImponibileComplessivo;
      }
    }
  }
})
export default class HeaderOfferta extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaHeaderCreateModel;
  // @Model("", { type: Object }) readonly Model2!: ProgettoACorpo;
}
