

















import Vue from "vue";
import { Component} from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Business Line",
          align: "start",
          sortable: false,
          value: "BusinessLinePresale"
        },
        { text: "TechArea", value: "TechAreaPresale" },
        { text: "Tipologia Fornitura", value: "TipologiaFornituraPresale" },
        { text: "Figura Professionale", value: "FiguraProfessionalePresale" },
        { text: "Società", value: "SocietaPresale" },
        { text: "Nome Risorsa", value: "NomeRisorsaPresale" },
        { text: "GG/Uomo Offerta", value: "GGUomoOffPresale" },
        {
          text: "Costo Giornaliero",
          value: "CostoGiornalieroPresale"
        },
        { text: "Costo Atteso", value: "CostoAttesoPresale" }
      ]
    };
  },
  computed: {
    AggiuntiviPresaleItems: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate;
      }
    }
  }
})
export default class PgaValutazioneOffertaAggiuntiviPresaleDetails extends Vue {}
