




























































































































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import PgaValutazioneOffertaHeaderCreate from "./Components/PgaValutazioneOffertaHeaderCreate.vue";
import PgaValutazioneOffertaRisorseUmaneCreate from "./Components/PgaValutazioneOffertaRisorseUmaneCreate.vue";
import PgaValutazioneOffertaSubtotaliRisorseUmane from "./Components/PgaValutazioneOffertaSubtotaliRisorseUmane.vue";
import PgaValutazioneOffertaSubtotaliAltreComponenti from "./Components/PgaValutazioneOffertaSubtotaliAltreComponenti.vue";
import PgaValutazioneOffertaAltreComponentiCreate from "./Components/PgaValutazioneOffertaAltreComponentiCreate.vue";
import PgaValutazioneOffertaTotaliOffertaCreate from "./Components/PgaValutazioneOffertaTotaliOffertaCreate.vue";
import PgaValutazioneOffertaTotaliOffertaValutazione from "./Components/PgaValutazioneOffertaTotaliOffertaValutazione.vue";
import PgaValutazioneOffertaProgettoACorpoCreate from "./Components/PgaValutazioneOffertaProgettoACorpoCreate.vue";
import PgaValutazioneOffertaAggiuntiviPresaleCreate from "./Components/PgaValutazioneOffertaAggiuntiviPresaleCreate.vue";
import PgaValutazioneOffertaSubtotaliAggiuntiviPresale from "./Components/PgaValutazioneOffertaSubtotaliAggiuntiviPresale.vue";
import PgaValutazioneOffertaAltriPresaleCreate from "./Components/PgaValutazioneOffertaAltriPresaleCreate.vue";
import PgaValutazioneOffertaSubtotaliAltriPresale from "./Components/PgaValutazioneOffertaSubtotaliAltriPresale.vue";
import PgaValutazioneOffertaPostsaleCreate from "./Components/PgaValutazioneOffertaPostsaleCreate.vue";
import PgaValutazioneOffertaSubtotaliPostsale from "./Components/PgaValutazioneOffertaSubtotaliPostsale.vue";
import { CreateOfferModule } from "./store";

@Component({
  components: {
    PgaValutazioneOffertaHeaderCreate,
    PgaValutazioneOffertaSubtotaliRisorseUmane,
    PgaValutazioneOffertaSubtotaliAltreComponenti,
    PgaValutazioneOffertaRisorseUmaneCreate,
    PgaValutazioneOffertaAltreComponentiCreate,
    PgaValutazioneOffertaProgettoACorpoCreate,
    PgaValutazioneOffertaAggiuntiviPresaleCreate,
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale,
    PgaValutazioneOffertaAltriPresaleCreate,
    PgaValutazioneOffertaSubtotaliAltriPresale,
    PgaValutazioneOffertaPostsaleCreate,
    PgaValutazioneOffertaSubtotaliPostsale,
    PgaValutazioneOffertaTotaliOffertaCreate,
    PgaValutazioneOffertaTotaliOffertaValutazione
  },
  data: () => ({
    e1: 1,
    counter: 0,
    vertical: true,
    editable: true
  }),
  computed: {
    PgaValutazioneOffertaRisorseUmaneCreate: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaRisorseUmaneCreate;
      }
    },
    PgaValutazioneOffertaAltreComponentiCreate: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaAltreComponentiCreate;
      }
    },
    PgaValutazioneOffertaAggiuntiviPresaleCreate: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaAggiuntiviPresaleCreate;
      }
    },
    PgaValutazioneOffertaAltriPresaleCreate: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaAltriPresaleCreate;
      }
    },
    PgaValutazioneOffertaPostsaleCreate: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaPostsaleCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliPostsale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliPostsale;
      }
    },
    PgaValutazioneOffertaSubtotaliRisorseUmane: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliRisorseUmane;
      }
    },
    PgaValutazioneOffertaSubtotaliAltreComponenti: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAltreComponenti;
      }
    },
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAggiuntiviPresale;
      }
    },
    PgaValutazioneOffertaSubtotaliAltriPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale;
      }
    },
  },
  methods: {
    addRisorsa() {
      CreateOfferModule.ADD_RISORSA_UMANA();
    },
    addAltreComponenti() {
      CreateOfferModule.ADD_ALTRE_COMPONENTI();
    },
    addAggiuntiviPresale() {
      CreateOfferModule.ADD_AGGIUNTIVI_PRESALE();
    },
    addAltriPresale() {
      CreateOfferModule.ADD_ALTRI_PRESALE();
    },
    addPostsale() {
      CreateOfferModule.ADD_POSTSALE();
    }
  } 
})
export default class OffertePageCreate extends Vue {}
