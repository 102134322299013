











































	import { Component, Prop } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { TotalsOfferEvaluationSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalsOfferEvaluationDetails extends mixins( SharedMixin )
	{
		@Prop({ type: Object, default: function(){ return {} } }) readonly totalsOffer!: TotalsOfferEvaluationSectionView;

        // Totals Offer Evaluation
		get offerAmounts() : number
		{
			return this.totalsOffer.OfferAmounts;
		}

		get expectedCosts() : number
		{
			return this.totalsOffer.ExpectedCosts;
		}

        get expectedMargins (): number
		{
			return this.totalsOffer.ExpectedMargins;
		}

        get expectedMarginsPerc (): string
		{
            return this.totalsOffer.ExpectedMarginsPercentage.toFixed(2);
		}
	}
