


















    import { Component } from "vue-property-decorator";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    //  Order Requirements Review section
    import PgaOrderRequirementsReviewDetails from "./sections/PgaOrderRequirementsReviewDetails.vue";
    import { BusinessStartupCustomerOrderDetailsView } from "@/services/iOrderService";
    import { CustomerOrderStore } from "../../store";
    

	@Component( {
        components:
        {
            PgaOrderRequirementsReviewDetails,
        }
    })
    export default class PgaBusinessStartupCustomerOrderDetails extends mixins(SharedMixin)
    {
        private get customerOrder() : BusinessStartupCustomerOrderDetailsView
        {
            return CustomerOrderStore.CustomerOrderDetails;
        }

        get panelsOpened() : Array<number>
        {
            return [0];
        }
    }
