import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import '@/App.container';
import MutationPayload from '@/types/Payload';

export interface CreateOfferState {
    ModuloValutazioneOfferta: ModuloValutazioneOfferta;
}

export interface ModuloValutazioneOfferta {
    PgaValutazioneOffertaHeaderCreate: PgaValutazioneOffertaHeaderCreateModel;
    PgaValutazioneOffertaRisorseUmaneCreate: Array<PgaValutazioneOffertaRisorseUmaneCreateModel>;
    PgaValutazioneOffertaAltreComponentiCreate: Array<PgaValutazioneOffertaAltreComponentiCreateModel>;
    PgaValutazioneOffertaSubtotaliRisorseUmane: PgaValutazioneOffertaSubtotaliRisorseUmaneModel;
    PgaValutazioneOffertaSubtotaliAltreComponenti: PgaValutazioneOffertaSubtotaliAltreComponentiModel;
    PgaValutazioneOffertaAggiuntiviPresaleCreate: Array<PgaValutazioneOffertaAggiuntiviPresaleCreateModel>;
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale: PgaValutazioneOffertaSubtotaliAggiuntiviPresaleModel;
    PgaValutazioneOffertaAltriPresaleCreate: Array<PgaValutazioneOffertaAltriPresaleCreateModel>;
    PgaValutazioneOffertaSubtotaliAltriPresale: PgaValutazioneOffertaSubtotaliAltriPresaleModel;
    PgaValutazioneOffertaPostsaleCreate: Array<PgaValutazioneOffertaPostsaleCreateModel>;
    PgaValutazioneOffertaSubtotaliPostsale: PgaValutazioneOffertaSubtotaliPostsaleModel;
    PgaValutazioneOffertaTotaliOffertaCreate: PgaValutazioneOffertaTotaliOffertaCreateModel;
    PgaValutazioneOffertaTotaliOffertaValutazione: PgaValutazioneOffertaTotaliOffertaValutazioneModel;
    PgaValutazioneOffertaProgettoACorpoCreate: PgaValutazioneOffertaProgettoACorpoCreateModel;
}

export interface PgaValutazioneOffertaRisorseUmaneCreateModel {
    BusinessLine: string;
    TechArea: string;
    TipologiaFornitura: string;
    FiguraProfessionale: string;
    NomeRisorsa: string;
    Societa: string;
    GGUomoPrev: number;
    GGUomoOff: number;
    TariffaOfferta: number;
    ImportoOfferta: number;
    MargineRischio: number;
    TariffaMinima: number;
    CostoAtteso: number;
    MargineAtteso: number;
    MargineAttesoPerc: number;
}
export interface PgaValutazioneOffertaAltreComponentiCreateModel {
    TipologiaComponente: string;
    DescrizioneComponente: string;
    ImportoOffertaComponente: number;
    CostoAttesoComponente: number;
    MargineAttesoComponente: number;
    MargineAttesoPercComponente: number;
    BusinessLineComponente: string;
    TechAreaComponente: string;
    TipologiaFornituraComponente: string;
}
export interface PgaValutazioneOffertaAggiuntiviPresaleCreateModel {
    BusinessLinePresale: string;
    TechAreaPresale: string;
    TipologiaFornituraPresale: string;
    FiguraProfessionalePresale: string;
    NomeRisorsaPresale: string;
    SocietaPresale: string;
    GGUomoOffPresale: number;
    CostoGiornalieroPresale: number;
    CostoAttesoPresale: number;
}
export interface PgaValutazioneOffertaHeaderCreateModel {
    Cliente: string;
    Protocollo: string;
    TipologiaOfferta: string;
    Descrizione: string;
}
export interface PgaValutazioneOffertaSubtotaliRisorseUmaneModel {
    SubGGUomoPrevisti: number;
    SubGGOfferta: number;
    SubMargineRischio: number;
    SubImportoOfferta: number;
    SubCostoAtteso: number;
    SubMargineAtteso: number;
    SubMargineAttesoPerc: number;
}
export interface PgaValutazioneOffertaSubtotaliAggiuntiviPresaleModel {
    SubGGOffertaPresale: number;
    SubCostoAttesoPresale: number;
}
export interface PgaValutazioneOffertaProgettoACorpoCreateModel {
    ImponibileComplessivo: number;
}
export interface PgaValutazioneOffertaSubtotaliAltreComponentiModel {
    SubImportoOffertaComponenti: number;
    SubCostoAttesoComponenti: number;
    SubMargineAttesoComponenti: number;
    SubMargineAttesoPercComponenti: number;
}
export interface PgaValutazioneOffertaSubtotaliAltriPresaleModel {
    SubCostoAttesoAltriPresale: number;
    SubCostoAttesoAltriPresaleTotale: number;
}
export interface PgaValutazioneOffertaSubtotaliPostsaleModel {
    SubCostoAttesoPostsale: number;
}
export interface PgaValutazioneOffertaAltriPresaleCreateModel {
    DescrizioneAltriPresale: string;
    CostoAttesoAltriPresale: number;
}
export interface PgaValutazioneOffertaPostsaleCreateModel {
    DescrizionePostsale: string;
    TipologiaPostsale: string;
    CostoAttesoPostsale: number;
}
export interface PgaValutazioneOffertaTotaliOffertaCreateModel {
    ImportoOffertaTotale: number;
    CostoAttesoTotale: number;
    MargineAttesoTotale: number;
    MargineAttesoPercTotale: number;
    Sconto: number;
    ImportoOffertaTotaleScontato: number;
    CostoAttesoTotaleScontato: number;
    MargineAttesoTotaleScontato: number;
    MargineAttesoPercTotaleScontato: number;
}
export interface PgaValutazioneOffertaTotaliOffertaValutazioneModel {
    ImportoOffertaTotaleValutazione: number;
    CostoAttesoTotaleValutazione: number;
    MargineAttesoTotaleValutazione: number;
    MargineAttesoPercTotaleValutazione: number;
}
@Module({ namespaced: true, dynamic: true, store, name: 'CreateOfferModule', })
class CreateOfferStore extends VuexModule implements CreateOfferState {
    ModuloValutazioneOfferta: ModuloValutazioneOfferta = {
        PgaValutazioneOffertaProgettoACorpoCreate: {
            ImponibileComplessivo: 0,
        },
        PgaValutazioneOffertaHeaderCreate: {
            Cliente: "",
            Protocollo: "",
            TipologiaOfferta: "",
            Descrizione: "",
        },
        PgaValutazioneOffertaRisorseUmaneCreate: [],
        PgaValutazioneOffertaAltreComponentiCreate: [],
        PgaValutazioneOffertaAggiuntiviPresaleCreate: [],
        PgaValutazioneOffertaAltriPresaleCreate: [],
        PgaValutazioneOffertaPostsaleCreate: [],
        PgaValutazioneOffertaSubtotaliRisorseUmane: {
            SubGGUomoPrevisti: 0,
            SubGGOfferta: 0,
            SubMargineRischio: 0,
            SubImportoOfferta: 0,
            SubCostoAtteso: 0,
            SubMargineAtteso: 0,
            SubMargineAttesoPerc: 0,
        },
        PgaValutazioneOffertaSubtotaliAltreComponenti: {
            SubImportoOffertaComponenti: 0,
            SubCostoAttesoComponenti: 0,
            SubMargineAttesoComponenti: 0,
            SubMargineAttesoPercComponenti: 0,
        },
        PgaValutazioneOffertaSubtotaliAggiuntiviPresale: {
            SubGGOffertaPresale: 0,
            SubCostoAttesoPresale: 0,
        },
        PgaValutazioneOffertaSubtotaliAltriPresale: {
            SubCostoAttesoAltriPresale: 0,
            SubCostoAttesoAltriPresaleTotale: 0,
        },
        PgaValutazioneOffertaSubtotaliPostsale: {
            SubCostoAttesoPostsale: 0,
        },
        PgaValutazioneOffertaTotaliOffertaCreate: {
            ImportoOffertaTotale: 0,
            CostoAttesoTotale: 0,
            MargineAttesoTotale: 0,
            MargineAttesoPercTotale: 0,
            Sconto: 0,
            ImportoOffertaTotaleScontato: 0,
            CostoAttesoTotaleScontato: 0,
            MargineAttesoTotaleScontato: 0,
            MargineAttesoPercTotaleScontato: 0,
        },
        PgaValutazioneOffertaTotaliOffertaValutazione: {
            ImportoOffertaTotaleValutazione: 0,
            CostoAttesoTotaleValutazione: 0,
            MargineAttesoTotaleValutazione: 0,
            MargineAttesoPercTotaleValutazione: 0,
        },
    }

    @Action({ rawError: true })
    public FormatMoney(value: string): string{
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    }

    //Progetto a corpo
    @Action({ rawError: true })
    public SET_IMPORTONIBILECOMPLESSIVO(ImponibileComplessivo: string): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaProgettoACorpoCreate.ImponibileComplessivo = parseFloat(ImponibileComplessivo);
        this.SET_SUBIMPORTOOFFERTATOTALE();
        this.SET_SUBIMPORTOOFFERTATOTALESCONTATO();
        this.SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    //Header
    @Mutation
    public SET_HEADER(PgaValutazioneOffertaHeaderCreate: PgaValutazioneOffertaHeaderCreateModel): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate = PgaValutazioneOffertaHeaderCreate;
    }
    @Mutation
    public SET_PROTOCOLLO(Protocollo: string): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate.Protocollo = Protocollo;
    }
    @Mutation
    public SET_CLIENTE(Cliente: string): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate.Cliente = Cliente;
    }
    @Mutation
    public SET_TIPOLOGIAOFFERTA(TipologiaOfferta: string): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate.TipologiaOfferta = TipologiaOfferta;
    }
    @Mutation
    public SET_DESCRIZIONE(Descrizione: string): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate.Descrizione = Descrizione;
    }

    //PgaValutazioneOffertaRisorseUmaneCreate
    @Mutation
    public SET_NOMERISORSA(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].NomeRisorsa = payload.value;
    }

    @Action({ rawError: true })
    public UpdateGiorniUomoOfferta(payload: MutationPayload<number>): void {
        this.SET_GGUOMOOFF(payload);
        this.SET_IMPORTOOFFERTA(payload.index);
        this.SET_MARGINERISCHIO(payload.index);
        this.SET_COSTOATTESO(payload.index);
        this.SET_MARGINEATTESO(payload.index);
        this.SET_MARGINEATTESOPERC(payload.index);
        this.SET_SUBGGOFFERTI();
        this.SET_SUBMARGINERISCHIO();
        this.SET_SUBIMPORTOOFFERTA();
        this.SET_SUBCOSTOATTESO();
        this.SET_SUBMARGINEATTESO();
        this.SET_SUBMARGINEATTESOPERC();
        this.SET_SUBIMPORTOOFFERTATOTALE();
        this.SET_SUBIMPORTOOFFERTATOTALESCONTATO();
        this.SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_COSTOATTESOTOTALE();
        this.SET_SUBCOSTOATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    @Action({ rawError: true })
    public UpdateGiorniUomoPrevisti(payload: MutationPayload<number>): void {
        this.SET_GGUOMOPREV(payload);
        this.SET_MARGINERISCHIO(payload.index);
        this.SET_COSTOATTESO(payload.index);
        this.SET_SUBGGPREVISTI();
        this.SET_MARGINEATTESO(payload.index);
        this.SET_MARGINEATTESOPERC(payload.index);
        this.SET_SUBMARGINERISCHIO();
        this.SET_SUBCOSTOATTESO();
        this.SET_COSTOATTESOTOTALE();
        this.SET_SUBCOSTOATTESOTOTALESCONTATO();
        this.SET_SUBMARGINEATTESO();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_SUBMARGINEATTESOPERC();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }

    @Action({ rawError: true })
    public UpdateTariffaOfferta(payload: MutationPayload<number>): void {
        this.SET_TARIFFAOFFERTA(payload);
        this.SET_IMPORTOOFFERTA(payload.index);
        this.SET_MARGINEATTESO(payload.index);
        this.SET_MARGINEATTESOPERC(payload.index);
        this.SET_SUBIMPORTOOFFERTA();
        this.SET_SUBIMPORTOOFFERTATOTALE();
        this.SET_SUBIMPORTOOFFERTATOTALESCONTATO();
        this.SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
        this.SET_SUBMARGINEATTESO();
        this.SET_SUBMARGINEATTESOPERC();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();

    }
    @Action({ rawError: true })
    public UpdateTariffaMinima(payload: MutationPayload<number>): void {
        this.SET_TARIFFAMINIMA(payload);
        this.SET_COSTOATTESO(payload.index);
        this.SET_MARGINEATTESO(payload.index);
        this.SET_MARGINEATTESOPERC(payload.index);
        this.SET_SUBCOSTOATTESO();
        this.SET_SUBMARGINEATTESO();
        this.SET_SUBMARGINEATTESOPERC();
        this.SET_COSTOATTESOTOTALE();
        this.SET_SUBCOSTOATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    @Mutation
    public SET_SUBGGPREVISTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubGGUomoPrevisti = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.reduce((sum, current) => sum + current.GGUomoPrev, 0);
    }
    @Mutation
    public SET_SUBGGOFFERTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubGGOfferta = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.reduce((sum, current) => sum + current.GGUomoOff, 0);
    }
    @Mutation
    public SET_SUBMARGINERISCHIO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubMargineRischio = parseFloat((((this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubGGOfferta - this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubGGUomoPrevisti) / this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubGGOfferta) * 100).toFixed(1));
    }
    @Mutation
    public SET_SUBIMPORTOOFFERTA(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubImportoOfferta = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.reduce((sum, current) => sum + current.ImportoOfferta, 0);
    }
    @Mutation
    public SET_SUBCOSTOATTESO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubCostoAtteso = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.reduce((sum, current) => sum + current.CostoAtteso, 0);
    }
    @Mutation
    public SET_SUBMARGINEATTESO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubMargineAtteso = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.reduce((sum, current) => sum + current.MargineAtteso, 0);
    }
    @Mutation
    public SET_SUBMARGINEATTESOPERC(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubMargineAttesoPerc = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubMargineAtteso / this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubImportoOfferta) * 100).toFixed(1))
    }
    @Mutation
    public SET_BUSINESSLINE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].BusinessLine = payload.value;
    }
    @Mutation
    public SET_TECHAREA(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].TechArea = payload.value;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURA(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].TipologiaFornitura = payload.value;
    }
    @Mutation
    public SET_FIGURAPROFESSIONALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].FiguraProfessionale = payload.value;
    }
    @Mutation
    public SET_SOCIETA(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].Societa = payload.value;
    }
    @Mutation
    public SET_GGUOMOPREV(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].GGUomoPrev = payload.value;
    }

    @Mutation
    public SET_GGUOMOOFF(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].GGUomoOff = payload.value
    }
    @Mutation
    public SET_MARGINERISCHIO(index: number): void {
        if (this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff == 0)
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineRischio = 0;
        else
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineRischio = parseFloat((((this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff - this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoPrev) / this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff) * 100).toFixed(1));
    }
    @Mutation
    public SET_TARIFFAOFFERTA(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].TariffaOfferta = payload.value as number;
    }
    @Mutation
    public SET_IMPORTOOFFERTA(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].ImportoOfferta = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff * this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TariffaOfferta;
    }
    @Mutation
    public SET_TARIFFAMINIMA(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[payload.index].TariffaMinima = payload.value as number;
    }
    @Mutation
    public SET_COSTOATTESO(index: number): void {
        if (this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoPrev > 0)
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].CostoAtteso = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TariffaMinima * this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoPrev;
        else
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].CostoAtteso = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TariffaMinima * this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff;
    }
    @Mutation
    public SET_MARGINEATTESO(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAtteso = this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].ImportoOfferta - this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].CostoAtteso;
    }
    @Mutation
    public SET_MARGINEATTESOPERC(index: number): void {
        if (this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].ImportoOfferta == 0)
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAttesoPerc = 0;
        else
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAttesoPerc = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAtteso / this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].ImportoOfferta) * 100).toFixed(1));
    }
    @Mutation
    public REMOVE_RISORSA_UMANA(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.splice(index, 1);
    }
    @Mutation
    public REMOVE_ALTRE_COMPONENTI(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate.splice(index, 1);
    }
   @Mutation
    public DUPLICATE_RISORSA_UMANA(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.push({
            BusinessLine: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].BusinessLine,
            TechArea: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TechArea,
            TipologiaFornitura: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TipologiaFornitura,
            FiguraProfessionale: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].FiguraProfessionale,
            Societa: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].Societa,
            GGUomoPrev: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoPrev,
            GGUomoOff: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].GGUomoOff,
            ImportoOfferta: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].ImportoOfferta,
            MargineRischio: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineRischio,
            TariffaOfferta: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TariffaOfferta,
            TariffaMinima: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].TariffaMinima,
            CostoAtteso: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].CostoAtteso,
            MargineAtteso: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAtteso,
            MargineAttesoPerc: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].MargineAttesoPerc,
            NomeRisorsa: this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate[index].NomeRisorsa,
        } as PgaValutazioneOffertaRisorseUmaneCreateModel);
    }
    @Mutation
    public ADD_RISORSA_UMANA(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate.push({
            BusinessLine: "",
            TechArea: "",
            TipologiaFornitura: "",
            FiguraProfessionale: "",
            Societa: "",
            GGUomoPrev: 0,
            GGUomoOff: 0,
            ImportoOfferta: 0,
            MargineRischio: 0,
            TariffaOfferta: 0,
            TariffaMinima: 0,
            CostoAtteso: 0,
            MargineAtteso: 0,
            MargineAttesoPerc: 0,
            NomeRisorsa: "",
        } as PgaValutazioneOffertaRisorseUmaneCreateModel);
    }

    //PgaValutazioneOffertaAltreComponentiCreate

    @Action({ rawError: true })
    public UpdateImportoOffertaComponente(payload: MutationPayload<number>): void {
        this.SET_IMPORTOOFFERTACOMPONENTE(payload);
        this.SET_MARGINEATTESOCOMPONENTE(payload.index);
        this.SET_MARGINEATTESOPERCCOMPONENTE(payload.index);
        this.SET_SUBIMPORTOOFFERTACOMPONENTI();
        this.SET_MARGINEATTESOCOMPONENTI();
        this.SET_MARGINEATTESOPERCCOMPONENTI();
        this.SET_SUBIMPORTOOFFERTATOTALE();
        this.SET_SUBIMPORTOOFFERTATOTALESCONTATO();
        this.SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
    }
    @Action({ rawError: true })
    public UpdateCostoAttesoComponente(payload: MutationPayload<number>): void {
        this.SET_COSTOATTESOCOMPONENTE(payload);
        this.SET_MARGINEATTESOCOMPONENTE(payload.index);
        this.SET_MARGINEATTESOPERCCOMPONENTE(payload.index);
        this.SET_SUBCOSTOATTESOCOMPONENTI();
        this.SET_MARGINEATTESOCOMPONENTI();
        this.SET_MARGINEATTESOPERCCOMPONENTI();
        this.SET_COSTOATTESOTOTALE();
        this.SET_SUBCOSTOATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOTOTALE();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_MARGINEATTESOPERCTOTALE();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    @Mutation
    public SET_BUSINESSLINECOMPONENTE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].BusinessLineComponente = payload.value;
    }
    @Mutation
    public SET_TECHAREACOMPONENTE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].TechAreaComponente = payload.value;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURACOMPONENTE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].TipologiaFornituraComponente = payload.value;
    }
    @Mutation
    public SET_IMPORTOOFFERTACOMPONENTE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].ImportoOffertaComponente = payload.value;
    }
    @Mutation
    public SET_COSTOATTESOCOMPONENTE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].CostoAttesoComponente = payload.value;
    }
    @Mutation
    public SET_MARGINEATTESOCOMPONENTE(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].MargineAttesoComponente = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].ImportoOffertaComponente - this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].CostoAttesoComponente;
    }
    @Mutation
    public SET_MARGINEATTESOPERCCOMPONENTE(index: number): void {
        if (this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].ImportoOffertaComponente == 0)
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].MargineAttesoPercComponente = 0;
        else
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].MargineAttesoPercComponente = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].MargineAttesoComponente / this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[index].ImportoOffertaComponente) * 100).toFixed(1));
    }
    @Mutation
    public SET_TIPOLOGIA_COMPONENTE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].TipologiaComponente = payload.value;
    }

    @Mutation
    public SET_DESCRIZIONE_COMPONENTE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[payload.index].DescrizioneComponente = payload.value;
    }
    @Mutation
    public SET_SUBIMPORTOOFFERTACOMPONENTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubImportoOffertaComponenti = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate.reduce((sum, current) => sum + current.ImportoOffertaComponente, 0);
    }
    @Mutation
    public SET_SUBCOSTOATTESOCOMPONENTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubCostoAttesoComponenti = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate.reduce((sum, current) => sum + current.CostoAttesoComponente, 0);
    }
    @Mutation
    public SET_MARGINEATTESOCOMPONENTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubMargineAttesoComponenti = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate.reduce((sum, current) => sum + current.MargineAttesoComponente, 0);
    }
    @Mutation
    public SET_MARGINEATTESOPERCCOMPONENTI(): void {
        if (this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubImportoOffertaComponenti == 0)
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubMargineAttesoPercComponenti = 0;
        else
            this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubMargineAttesoPercComponenti = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubMargineAttesoComponenti / this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubImportoOffertaComponenti) * 100).toFixed(1))
    }
    @Mutation
    public ADD_ALTRE_COMPONENTI(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate.push({
            BusinessLineComponente: "",
            TechAreaComponente: "",
            TipologiaFornituraComponente: "",
            TipologiaComponente: "",
            DescrizioneComponente: "",
            ImportoOffertaComponente: 0,
            CostoAttesoComponente: 0,
            MargineAttesoComponente: 0,
            MargineAttesoPercComponente: 0,
        } as PgaValutazioneOffertaAltreComponentiCreateModel);
    }

    //Costi Aggiuntivi Presale
    @Action({ rawError: true })
    public UpdateGiorniUomoOffertaPresale(payload: MutationPayload<number>): void {
        this.SET_GGUOMOOFFPRESALE(payload);
        this.SET_COSTOATTESOPRESALE(payload.index);
        this.SET_SUBGGOFFERTIPRESALE();
        this.SET_SUBCOSTOATTESOPRESALE();
        this.SET_SUBCOSTOATTESOALTRIPRESALETOTALE();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    @Action({ rawError: true })
    public UpdateCostoGiornalieroPresale(payload: MutationPayload<number>): void {
        this.SET_COSTOGIORNALIEROPRESALE(payload);
        this.SET_COSTOATTESOPRESALE(payload.index);
        this.SET_SUBCOSTOATTESOPRESALE();
        this.SET_SUBCOSTOATTESOALTRIPRESALETOTALE();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }

    @Mutation
    public SET_NOMERISORSAPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].NomeRisorsaPresale = payload.value;
    }
    @Mutation
    public SET_BUSINESSLINEPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].BusinessLinePresale = payload.value;
    }
    @Mutation
    public SET_TECHAREAPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].TechAreaPresale = payload.value;
    }
    @Mutation
    public SET_TIPOLOGIAFORNITURAPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].TipologiaFornituraPresale = payload.value;
    }
    @Mutation
    public SET_FIGURAPROFESSIONALEPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].FiguraProfessionalePresale = payload.value;
    }
    @Mutation
    public SET_SOCIETAPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].SocietaPresale = payload.value;
    }
    @Mutation
    public SET_GGUOMOOFFPRESALE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].GGUomoOffPresale = payload.value;
    }
    @Mutation
    public SET_COSTOATTESOPRESALE(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[index].CostoAttesoPresale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[index].GGUomoOffPresale * this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[index].CostoGiornalieroPresale;
    }
    @Mutation
    public SET_COSTOGIORNALIEROPRESALE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[payload.index].CostoGiornalieroPresale = payload.value;
    }
    @Mutation
    public SET_SUBGGOFFERTIPRESALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAggiuntiviPresale.SubGGOffertaPresale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate.reduce((sum, current) => sum + current.GGUomoOffPresale, 0);
    }
    @Mutation
    public SET_SUBCOSTOATTESOPRESALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAggiuntiviPresale.SubCostoAttesoPresale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate.reduce((sum, current) => sum + current.CostoAttesoPresale, 0);
    }
    @Mutation
    public REMOVE_AGGIUNTIVI_PRESALE(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate.splice(index, 1);
    }
    @Mutation
    public ADD_AGGIUNTIVI_PRESALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate.push({
            BusinessLinePresale: "",
            TechAreaPresale: "",
            TipologiaFornituraPresale: "",
            FiguraProfessionalePresale: "",
            SocietaPresale: "",
            GGUomoOffPresale: 0,
            CostoAttesoPresale: 0,
            CostoGiornalieroPresale: 0,
            NomeRisorsaPresale: "",
        } as PgaValutazioneOffertaAggiuntiviPresaleCreateModel);
    }

    //Altri Costi Presale
    @Action({ rawError: true })
    public UpdateCostoAttesoAltriPresale(payload: MutationPayload<number>): void {
        this.SET_COSTOATTESOALTRIPRESALE(payload);
        this.SET_SUBCOSTOATTESOALTRIPRESALE();
        this.SET_SUBCOSTOATTESOALTRIPRESALETOTALE();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }

    @Mutation
    public SET_DESCRIZIONEALTRIPRESALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate[payload.index].DescrizioneAltriPresale = payload.value;
    }
    @Mutation
    public SET_COSTOATTESOALTRIPRESALE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate[payload.index].CostoAttesoAltriPresale = payload.value;
    }
    @Mutation
    public SET_SUBCOSTOATTESOALTRIPRESALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale.SubCostoAttesoAltriPresale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate.reduce((sum, current) => sum + current.CostoAttesoAltriPresale, 0);
    }
    @Mutation
    public SET_SUBCOSTOATTESOALTRIPRESALETOTALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale.SubCostoAttesoAltriPresaleTotale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale.SubCostoAttesoAltriPresale + this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAggiuntiviPresale.SubCostoAttesoPresale
    }
    @Mutation
    public REMOVE_ALTRI_PRESALE(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate.splice(index, 1);
    }
    @Mutation
    public ADD_ALTRI_PRESALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate.push({
            DescrizioneAltriPresale: "",
            CostoAttesoAltriPresale: 0,
        } as PgaValutazioneOffertaAltriPresaleCreateModel);
    }

    //PgaValutazioneOffertaPostsaleCreate
    @Action({ rawError: true })
    public UpdateCostoAttesoPostsale(payload: MutationPayload<number>): void {
        this.SET_COSTOATTESOPOSTSALE(payload);
        this.SET_SUBCOSTOATTESOPOSTSALE();
        this.SET_COSTOATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }

    @Mutation
    public SET_DESCRIZIONEPOSTSALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate[payload.index].DescrizionePostsale = payload.value;
    }
    @Mutation
    public SET_TIPOLOGIAPOSTSALE(payload: MutationPayload<string>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate[payload.index].TipologiaPostsale = payload.value;
    }
    @Mutation
    public SET_COSTOATTESOPOSTSALE(payload: MutationPayload<number>): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate[payload.index].CostoAttesoPostsale = payload.value;
    }
    @Mutation
    public SET_SUBCOSTOATTESOPOSTSALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliPostsale.SubCostoAttesoPostsale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate.reduce((sum, current) => sum + current.CostoAttesoPostsale, 0);
    }
    @Mutation
    public REMOVE_POSTSALE(index: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate.splice(index, 1);
    }
    @Mutation
    public ADD_POSTSALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate.push({
            DescrizionePostsale: "",
            CostoAttesoPostsale: 0,
            TipologiaPostsale: "",
        } as PgaValutazioneOffertaPostsaleCreateModel);
    }

    //Totali Offerta
    @Mutation
    public SET_SUBIMPORTOOFFERTATOTALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubImportoOfferta + this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubImportoOffertaComponenti + this.ModuloValutazioneOfferta.PgaValutazioneOffertaProgettoACorpoCreate.ImponibileComplessivo;
    }
    @Mutation
    public SET_COSTOATTESOTOTALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane.SubCostoAtteso + this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltreComponenti.SubCostoAttesoComponenti;
    }
    @Mutation
    public SET_MARGINEATTESOTOTALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoTotale = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotale - this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotale;
    }
    @Mutation
    public SET_MARGINEATTESOPERCTOTALE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoPercTotale = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoTotale / this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotale) * 100).toFixed(1));
    }
    @Action({ rawError: true })
    public UpdateSconto(value: number): void {
        this.SET_SCONTO(value);
        this.SET_SUBIMPORTOOFFERTATOTALESCONTATO();
        this.SET_SUBCOSTOATTESOTOTALESCONTATO();
        this.SET_SUBMARGINEATTESOTOTALESCONTATO();
        this.SET_SUBMARGINEATTESOPERCTOTALESCONTATO();
        this.SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE();
        this.SET_MARGINEATTESOTOTALE_VALUTAZIONE();
        this.SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE();
    }
    @Mutation
    public SET_SCONTO(Sconto: number): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.Sconto = Sconto;
    }
    @Mutation
    public SET_SUBIMPORTOOFFERTATOTALESCONTATO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotaleScontato = parseFloat((this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotale - (this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotale * (this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.Sconto / 100))).toFixed(1));
    }
    @Mutation
    public SET_SUBCOSTOATTESOTOTALESCONTATO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotaleScontato = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotale;
    }
    @Mutation
    public SET_SUBMARGINEATTESOTOTALESCONTATO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoTotaleScontato = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotaleScontato - this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotaleScontato;
    }
    @Mutation
    public SET_SUBMARGINEATTESOPERCTOTALESCONTATO(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoPercTotaleScontato = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.MargineAttesoTotaleScontato / this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotaleScontato) * 100).toFixed(1));
    }

    //Totali offerta valutazione
    @Mutation
    public SET_SUBIMPORTOOFFERTATOTALEVALUTAZIONE_VALUTAZIONE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.ImportoOffertaTotaleValutazione = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.ImportoOffertaTotaleScontato;
    }
    @Mutation
    public SET_COSTOATTESOTOTALE_VALUTAZIONE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.CostoAttesoTotaleValutazione = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.CostoAttesoTotaleScontato + this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale.SubCostoAttesoAltriPresaleTotale + this.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliPostsale.SubCostoAttesoPostsale;
    }
    @Mutation
    public SET_MARGINEATTESOTOTALE_VALUTAZIONE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.MargineAttesoTotaleValutazione = this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.ImportoOffertaTotaleValutazione - this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.CostoAttesoTotaleValutazione;
    }
    @Mutation
    public SET_MARGINEATTESOPERCTOTALE_VALUTAZIONE(): void {
        this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.MargineAttesoPercTotaleValutazione = parseFloat(((this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.MargineAttesoTotaleValutazione / this.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaValutazione.ImportoOffertaTotaleValutazione) * 100).toFixed(1));
    }
}

export const CreateOfferModule = getModule(CreateOfferStore);
