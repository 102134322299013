





























import Vue from "vue";
import { Component, Model} from "vue-property-decorator";
import { PgaValutazioneOffertaSubtotaliAltriPresaleModel } from "../store";

@Component({
  computed: {
    subCostoAttesoAltriPresale: {
      get() {
        return this.$props.Model.SubCostoAttesoAltriPresale;
      }
    },
    subCostoAttesoAltriPresaleTotale: {
      get() {
         return this.$props.Model.SubCostoAttesoAltriPresaleTotale;
      }
    },
  }
})
export default class PgaValutazioneOffertaSubtotaliAltriPresale extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaSubtotaliAltriPresaleModel;
}
