






















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  computed: {
    ImportoOffertaTotaleValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione.ImportoOffertaTotaleValutazione;
      }
    },
    CostoAttesoTotaleValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione.CostoAttesoTotaleValutazione;
      }
    },
    MargineAttesoTotaleValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione.MargineAttesoTotaleValutazione;
      }
    },
    MargineAttesoPercTotaleValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione.MargineAttesoPercTotaleValutazione;
      }
    }
  }
})
export default class PgaValutazioneOffertaTotaliOffertaValutazione extends Vue {}
