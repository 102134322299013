














	import { Component, Model } from "vue-property-decorator";
	
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { FixedPriceProjectSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationFixedPriceProjectDetails extends mixins(SharedMixin)
	{
        @Model("", { type: Object }) readonly Model!: FixedPriceProjectSectionView;
		
		get totalTaxableAmount() : number
		{
			return this.Model.TotalTaxableAmount;
		}
	}
