



















import Vue from "vue";
import { Component} from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Descrizione",
          align: "start",
          sortable: false,
          value: "DescrizionePostsale"
        },
        { text: "Costo Atteso", value: "CostoAttesoPostsale" }
      ]
    };
  },
  computed: {
    PostsaleItems: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaPostsaleCreate;
      }
    }
  }
})
export default class PgaValutazioneOffertaPostsaleDetails extends Vue {}
