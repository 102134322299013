

























































































































































	import { Component, Model } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { ResourceDetailsView } from '@/services/iOrderService';

	@Component({})
	export default class PgaOfferEvaluationHumanResourcesDetails extends mixins( SharedMixin )
	{
        @Model("", { type: Object }) readonly Model!: ResourceDetailsView;

		//Details Human Resource: first row
		get businessLine() : string
		{
			return this.Model.BusinessLine;
		}

		get techArea() : string
		{
			return this.Model.TechArea;
		}

		get supplyTipology() : string
		{
			return this.Model.SupplyTipology;
		}

		//Details Human Resource: second row
		get professionalFigure() : string
		{
			return this.Model.Professional;
		}

		get company() : string
		{
			return this.Model.Company;
		}

		get resourceName() : string
		{
			return this.Model.ResourceName;
		}

		//Details Human Resource: third row
		get expectedMen() : number
		{
			return this.Model.ExpectedMen;
		}

		get offeredMen() : number
		{
			return this.Model.OfferedMen;
		}

		get riskMargin() : string
		{
			return this.Model.MarginRisk.toFixed(2);
		}

		get dailyPriceOffer() : number
		{
			return this.Model.DailyPriceOffer;
		}

		get offerAmount() : number
		{
			return this.Model.OfferAmount;
		}

		get miniumDailyPrice() : number
		{
			return this.Model.MiniumDailyPrice;
		}

		//Details Human Resource: fourth row
		get expectedCost() : number
		{
			return this.Model.ExpectedCost;
		}

		get expectedMargin() : number
		{
			return this.Model.ExpectedMargin;
		}

		get expectedMarginPerc() : string
		{	
			return this.Model.ExpectedMarginPercentage.toFixed(2);
		}
	}
