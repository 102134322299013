import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/infrastructure/store';
import '@/App.container';
import pgaDiContainer from '@/App.container';

import IOrderService,
    {   ListBusinessStartupCustomerOrderTable, BusinessStartupCustomerOrderViewTable, DeliverablesModel, PlaceType,
            BusinessStartupCustomerOrderDetailsView,
        CreateBusinessStartupCustomerOrderCommand, EditOrderRequirementsReviewCommand,
        CreateBusinessStartupCustomerOrderResponse, EditBusinessStartupCustomerOrderResponse } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
import { BusinessLineModel, TechAreaModel, TipologiaFornituraModel, CustomerView } from '@/services/iOrderService';

import { parseErrors, SharedModule } from '@/feature/Shared/store';

export interface CustomerOrdersState
{
    CustomerOrders: Array<BusinessStartupCustomerOrderViewTable>;
    CustomerOrderDetails: BusinessStartupCustomerOrderDetailsView;
    CustomerOrdersClosed: Array<BusinessStartupCustomerOrderViewTable>;
    CustomerOrderCreate: CreateBusinessStartupCustomerOrderModel;
    OrderRequirementsReviewEdit: EditOrderRequirementsReviewModel;

    BusinessLinesDropdown: Array<BusinessLineModel>;
    TechAreasDropdown: Array<TechAreaModel>;
    SupplyTipologiesDropdown: Array<TipologiaFornituraModel>;
    CustomersDropdown: Array<CustomerView>;
}

export interface CreateBusinessStartupCustomerOrderModel
{
    Command: CreateBusinessStartupCustomerOrderCommand;
    Response: CreateBusinessStartupCustomerOrderResponse;
    Errors: Map<string, Array<string>>
}

export interface EditOrderRequirementsReviewModel
{
    Command: EditOrderRequirementsReviewCommand;
    Response: EditBusinessStartupCustomerOrderResponse;
    Errors: Map<string, Array<string>>
}

@Module( { namespaced: true, dynamic: true, store, name: 'CustomerOrderModule', } )
class CustomerOrdersStore extends VuexModule implements CustomerOrdersState
{
    private deliverableEmpty: DeliverablesModel =
    {
        DeliverableName: "",
        DeliveryBy: new Date(),
    };

    private orderService: IOrderService = pgaDiContainer.get<IOrderService>( OrderService );

    // Dropdowns' list
    public BusinessLinesDropdown: Array<BusinessLineModel> = new Array<BusinessLineModel>();
    public TechAreasDropdown: Array<TechAreaModel> = new Array<TechAreaModel>();
    public SupplyTipologiesDropdown: Array<TipologiaFornituraModel> = new Array<TipologiaFornituraModel>();
    public CustomersDropdown: Array<CustomerView> = new Array<CustomerView>();

    // Table State
    public CustomerOrders: Array<BusinessStartupCustomerOrderViewTable> = new Array<BusinessStartupCustomerOrderViewTable>();
    public CustomerOrdersClosed: Array<BusinessStartupCustomerOrderViewTable> = new Array<BusinessStartupCustomerOrderViewTable>();

    // Details State
    public CustomerOrderDetails: BusinessStartupCustomerOrderDetailsView =
    {
        Id: Guid.createEmpty(),
        Status: 1,
        OrderRequirementsReview:
        {
            ACOM: "",
            Customer: "",
            FinalCustomer: "",
            Description: "",

            AdvanceSupply: false,
            StartDate: new Date(),
            ExpectedEndDate: new Date(),
            Place: 1,
            Address: null,
            CustomerContractManager: "",
            OtherCustomerReferrals: new Array<string>(),

            BusinessLine: "",
            TechArea: "",
            SupplyTipology: "",

            Deliverables: new Array<DeliverablesModel>(),
            AreaManager: "",
            Privacy: false,
            Notes: "",
        },
    }

    // Create State
    public CustomerOrderCreate: CreateBusinessStartupCustomerOrderModel =
    {
        Command:
        {
            Customer: "",
            FinalCustomer: "",
            Description: "",
            AdvanceSupply: false,
            StartDate: new Date(),
            ExpectedEndDate: new Date(),
            Place: 1,
            Address: null,
            CustomerContractManager: "",
            OtherCustomerReferrals: Array<string>(""),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
            Deliverables: Array<DeliverablesModel>({...this.deliverableEmpty}),
            Privacy: false,
            Notes: "",
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Edit Order Requirements State
    public OrderRequirementsReviewEdit: EditOrderRequirementsReviewModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            FinalCustomer: "",
            Description: "",
            AdvanceSupply: false,
            StartDate: new Date(),
            ExpectedEndDate: new Date(),
            Place: 1,
            Address: null,
            CustomerContractManager: "",
            OtherCustomerReferrals: Array<string>(),
            Deliverables: Array<DeliverablesModel>(),
            Privacy: false,
            Notes: "",
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Dropdown BusinessLines
    @Action({ rawError: true })
    public async GetBusinessLinesDropdown()
    {
        const businessLines = await this.orderService.GetBusinessLinesDropdown();
        this.SET_BUSINESSLINES_DROPDOWN(businessLines);
    }
    @Mutation
    public SET_BUSINESSLINES_DROPDOWN(businessLines: Array<BusinessLineModel>)
    {
        this.BusinessLinesDropdown = businessLines;
    }

    // Dropdown TechAreas
    @Action({ rawError: true })
    public async GetTechAreasDropdown()
    {
        const techAreas = await this.orderService.GetAllTechAreasDropdown();
        this.SET_TECHAREAS_DROPDOWN(techAreas.TechAreas);
    }
    @Mutation
    public SET_TECHAREAS_DROPDOWN(techAreas: Array<TechAreaModel>)
    {
        this.TechAreasDropdown = techAreas;
    }

    // Dropdown SupplyTipologies
    @Action({ rawError: true })
    public async GetSupplyTipologiesDropdown()
    {
        const supplyTipologies = await this.orderService.GetAllSupplyTipologiesDropdown();
        this.SET_SUPPLYTIPOLOGIES_DROPDOWN(supplyTipologies.TipologiaFornitura);
    }
    @Mutation
    public SET_SUPPLYTIPOLOGIES_DROPDOWN(supplyTipologies: Array<TipologiaFornituraModel>)
    {
        this.SupplyTipologiesDropdown = supplyTipologies;
    }

    // Dropdown Customers
    @Action({ rawError: true })
    public async GetCustomersDropdown(businessName: string)
    {
        const customers = await this.orderService.GetCustomersByBusinessName(businessName);
        this.SET_COSTUMERS_DROPDOWN(customers.Customers);
    }
    @Mutation
    public SET_COSTUMERS_DROPDOWN(customers: Array<CustomerView>)
    {
        this.CustomersDropdown = customers;
    }

    // CUSTOMERORDERS TABLE
    @Action( { rawError: true } )
    public async ListBusinessStartupCustomerOrders (): Promise<void>
    {
        const customerOrders: ListBusinessStartupCustomerOrderTable = await this.orderService.GetAllBusinessStartupCustomerOrders();
        this.SET_CUSTOMERORDERS_TABLE( customerOrders.CustomerOrders );
    }
    @Mutation
    public SET_CUSTOMERORDERS_TABLE ( businessStartups: Array<BusinessStartupCustomerOrderViewTable> )
    {
        this.CustomerOrders = businessStartups;
    }

    // CUSTOMERORDERS TABLE CLOSED
    @Action( { rawError: true } )
    public async ListBusinessStartupCustomerOrdersClosed (): Promise<void>
    {
        const customerOrdersClosed: ListBusinessStartupCustomerOrderTable = await this.orderService.GetAllBusinessStartupCustomerOrdersClosed();
        this.SET_CUSTOMERORDERS_TABLE_CLOSED( customerOrdersClosed.CustomerOrders );
    }
    @Mutation
    public SET_CUSTOMERORDERS_TABLE_CLOSED ( businessStartups: Array<BusinessStartupCustomerOrderViewTable> )
    {
        this.CustomerOrdersClosed = businessStartups;
    }
    
    // CUSTOMERORDER DETAILS
    @Action( { rawError: true } )
    public async GetCustomerOrdersById (id: Guid): Promise<void>
    {
        const customerOrder: BusinessStartupCustomerOrderDetailsView = await this.orderService.GetBusinessStartupCustomerOrderById(id);
        this.SET_CUSTOMERORDERS_DETAILS( customerOrder );
    }
    @Mutation
    public SET_CUSTOMERORDERS_DETAILS ( customerOrder: BusinessStartupCustomerOrderDetailsView )
    {
        this.CustomerOrderDetails = customerOrder;
    }

    // CREATE CUSTOMERORDER
    @Action({ rawError: true })
    public async CreateBusinessStartupCustomerOrder(): Promise<CreateBusinessStartupCustomerOrderResponse>
    {
        let response;
        try
        {
            SharedModule.SET_ISLOADING(true);
            response = await this.orderService.CreateBusinessStartupCustomerOrder(this.CustomerOrderCreate.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_CREATE_CUSTOMERORDER_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public ResetBusinessStartupCustomerOrderCreate()
    {
        const emptyCommand: CreateBusinessStartupCustomerOrderCommand =
        {
            Customer: "",
            FinalCustomer: "",
            Description: "",
            AdvanceSupply: false,
            StartDate: new Date(),
            ExpectedEndDate: new Date(),
            Place: 1,
            Address: null,
            CustomerContractManager: "",
            OtherCustomerReferrals: Array<string>(""),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
            Deliverables: Array<DeliverablesModel>({...this.deliverableEmpty}),
            Privacy: false,
            Notes: "",
        };
        this.SET_CREATE_CUSTOMERORDER_COMMAND(emptyCommand);
        this.SET_CREATE_CUSTOMERORDER_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_COMMAND(command: CreateBusinessStartupCustomerOrderCommand)
    {
        this.CustomerOrderCreate.Command = command;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_CUSTOMER(customer: string)
    {
        this.CustomerOrderCreate.Command.Customer = customer;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_FINAL_CUSTOMER(finalCustomer: string)
    {
        this.CustomerOrderCreate.Command.FinalCustomer = finalCustomer;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_DESCRIPTION(description: string)
    {
        this.CustomerOrderCreate.Command.Description = description;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_ADVANCE_SUPPLY(advanceSupply: boolean)
    {
        this.CustomerOrderCreate.Command.AdvanceSupply = advanceSupply;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_START_DATE(startDate: Date)
    {
        this.CustomerOrderCreate.Command.StartDate = startDate;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_EXPECTED_END_DATE(expectedEndDate: Date)
    {
        this.CustomerOrderCreate.Command.ExpectedEndDate = expectedEndDate;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_PLACE(place: PlaceType)
    {
        this.CustomerOrderCreate.Command.Place = place;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_ADDRESS(address: string | null)
    {
        this.CustomerOrderCreate.Command.Address = address;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_CUSTOMER_CONTRACT_MANAGER(customerContractManager: string)
    {
        this.CustomerOrderCreate.Command.CustomerContractManager = customerContractManager;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_OTHER_CUSTOMER_REFERRALS(payload: any)
    {
        this.CustomerOrderCreate.Command.OtherCustomerReferrals[payload.index] = payload.otherCustomerReferrals;
    }
    @Mutation
    public ADD_CREATE_CUSTOMERORDER_OTHER_CUSTOMER_REFERRALS()
    {
        this.CustomerOrderCreate.Command.OtherCustomerReferrals.push("");
    }
    @Mutation
    public REMOVE_CREATE_CUSTOMERORDER_OTHER_CUSTOMER_REFERRALS(index: number)
    {
        this.CustomerOrderCreate.Command.OtherCustomerReferrals.splice(index, 1);
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_BUSINESSLINEID(businessLineId: Guid | null)
    {
        this.CustomerOrderCreate.Command.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_TECHAREAID(techAreaId: Guid | null)
    {
        this.CustomerOrderCreate.Command.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_SUPPLYTIPOLOGYID(supplyTipologyId: Guid | null)
    {
        this.CustomerOrderCreate.Command.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_PRIVACY(privacy: boolean)
    {
        this.CustomerOrderCreate.Command.Privacy = privacy;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_NOTES(notes: string)
    {
        this.CustomerOrderCreate.Command.Notes = notes;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_DELIVERABLES(payload: any)
    {
        this.CustomerOrderCreate.Command.Deliverables[payload.index] = payload.deliverables;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_DELIVERABLES_NAME(payload: any)
    {
        this.CustomerOrderCreate.Command.Deliverables[payload.index].DeliverableName = payload.deliverableName;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_DELIVERABLES_DATE(payload: any)
    {
        this.CustomerOrderCreate.Command.Deliverables[payload.index].DeliveryBy = payload.deliveryBy;
    }
    @Mutation
    public ADD_CREATE_CUSTOMERORDER_DELIVERABLES()
    {
        const deliverable: DeliverablesModel =
        {
            DeliverableName: "",
            DeliveryBy: new Date(),
        };
        this.CustomerOrderCreate.Command.Deliverables.push(deliverable);
    }
    @Mutation
    public REMOVE_CREATE_CUSTOMERORDER_DELIVERABLES(index: number)
    {
        this.CustomerOrderCreate.Command.Deliverables.splice(index, 1);
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_ERRORS(errors: Map<string, Array<string>>)
    {
        this.CustomerOrderCreate.Errors = errors;
    }

    // EDIT ORDER REQUIREMENTS REVIEW
    @Action({ rawError: true })
    public async EditOrderRequirementsReview(): Promise<EditBusinessStartupCustomerOrderResponse>
    {
        try
        {
            const response = await this.orderService.EditOrderRequirementsReview(this.OrderRequirementsReviewEdit.Command);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public ResetOrderRequirementsReviewEdit()
    {
        const emptyCommand: EditOrderRequirementsReviewCommand =
        {
            Id: Guid.createEmpty(),
            FinalCustomer: "",
            Description: "",
            AdvanceSupply: false,
            StartDate: new Date(),
            ExpectedEndDate: new Date(),
            Place: 1,
            Address: null,
            CustomerContractManager: "",
            OtherCustomerReferrals: new Array<string>(),
            Deliverables: new Array<DeliverablesModel>(),
            Privacy: false,
            Notes: "",
        };
        this.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_COMMAND(emptyCommand);
        this.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ERRORS(new Map<string, Array<string>>());
    }
    @Action({ rawError: true })
    public InitOrderRequirementsReviewEdit()
    {
        const initCommand: EditOrderRequirementsReviewCommand =
        {
            Id: this.CustomerOrderDetails.Id,
            FinalCustomer: this.CustomerOrderDetails.OrderRequirementsReview.FinalCustomer,
            Description: this.CustomerOrderDetails.OrderRequirementsReview.Description,
            AdvanceSupply: this.CustomerOrderDetails.OrderRequirementsReview.AdvanceSupply,
            StartDate: this.CustomerOrderDetails.OrderRequirementsReview.StartDate,
            ExpectedEndDate: this.CustomerOrderDetails.OrderRequirementsReview.ExpectedEndDate,
            Place: this.CustomerOrderDetails.OrderRequirementsReview.Place,
            Address: this.CustomerOrderDetails.OrderRequirementsReview.Address,
            CustomerContractManager: this.CustomerOrderDetails.OrderRequirementsReview.CustomerContractManager,
            OtherCustomerReferrals: this.CustomerOrderDetails.OrderRequirementsReview.OtherCustomerReferrals,
            Deliverables: this.CustomerOrderDetails.OrderRequirementsReview.Deliverables,
            Privacy: this.CustomerOrderDetails.OrderRequirementsReview.Privacy,
            Notes: this.CustomerOrderDetails.OrderRequirementsReview.Notes,
        };
        this.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_COMMAND(initCommand);
        this.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_COMMAND(command: EditOrderRequirementsReviewCommand)
    {
        this.OrderRequirementsReviewEdit.Command = command;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_FINAL_CUSTOMER(finalCustomer: string)
    {
        this.OrderRequirementsReviewEdit.Command.FinalCustomer = finalCustomer;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DESCRIPTION(description: string)
    {
        this.OrderRequirementsReviewEdit.Command.Description = description;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ADVANCE_SUPPLY(advanceSupply: boolean)
    {
        this.OrderRequirementsReviewEdit.Command.AdvanceSupply = advanceSupply;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_START_DATE(startDate: Date)
    {
        this.OrderRequirementsReviewEdit.Command.StartDate = startDate;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_END_DATE(expectedEndDate: Date)
    {
        this.OrderRequirementsReviewEdit.Command.ExpectedEndDate = expectedEndDate;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_PLACE(place: PlaceType)
    {
        this.OrderRequirementsReviewEdit.Command.Place = place;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ADDRESS(address: string | null)
    {
        this.OrderRequirementsReviewEdit.Command.Address = address;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_CUSTOMER_CONTRACT_MANAGER(customerContractManager: string)
    {
        this.OrderRequirementsReviewEdit.Command.CustomerContractManager = customerContractManager;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS(payload: any)
    {
        this.OrderRequirementsReviewEdit.Command.OtherCustomerReferrals[payload.index] = payload.otherCustomerReferrals;
    }
    @Mutation
    public ADD_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS()
    {
        this.OrderRequirementsReviewEdit.Command.OtherCustomerReferrals.push("");
    }
    @Mutation
    public REMOVE_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS(index: number)
    {
        this.OrderRequirementsReviewEdit.Command.OtherCustomerReferrals.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_PRIVACY(privacy: boolean)
    {
        this.OrderRequirementsReviewEdit.Command.Privacy = privacy;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_NOTES(notes: string)
    {
        this.OrderRequirementsReviewEdit.Command.Notes = notes;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES(payload: any)
    {
        this.OrderRequirementsReviewEdit.Command.Deliverables[payload.index] = payload.deliverables;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES_NAME(payload: any)
    {
        this.OrderRequirementsReviewEdit.Command.Deliverables[payload.index].DeliverableName = payload.deliverableName;
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES_DATE(payload: any)
    {
        this.OrderRequirementsReviewEdit.Command.Deliverables[payload.index].DeliveryBy = payload.deliveryBy;
    }
    @Mutation
    public ADD_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES()
    {
        const deliverable: DeliverablesModel =
        {
            DeliverableName: "",
            DeliveryBy: new Date(),
        };
        this.OrderRequirementsReviewEdit.Command.Deliverables.push(deliverable);
    }
    @Mutation
    public REMOVE_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES(index: number)
    {
        this.OrderRequirementsReviewEdit.Command.Deliverables.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ERRORS(errors: Map<string, Array<string>>)
    {
        this.OrderRequirementsReviewEdit.Errors = errors;
    }
}

export const CustomerOrderStore = getModule( CustomerOrdersStore );