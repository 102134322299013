

































	import { Component, Model } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { OfferEvaluationCustomerOrderDetailsView } from "@/services/iOrderService";
	import PgaOfferEvaluationTotalCostsPresaleDetails from "./PgaOfferEvaluationTotalCostsPresaleDetails.vue";
	import PgaOfferEvaluationTotalCostsPostsaleDetails from "./PgaOfferEvaluationTotalCostsPostsaleDetails.vue";

	@Component( {
		components:
		{
			PgaOfferEvaluationTotalCostsPresaleDetails,
			PgaOfferEvaluationTotalCostsPostsaleDetails,
		}
	} )
	export default class PgaOfferEvaluationTotalCostsDetails extends mixins( SharedMixin )
	{
        @Model("", { type: Object }) readonly Model!: OfferEvaluationCustomerOrderDetailsView;

		get presale() : number
		{
			return this.Model.TotalCostsPresale.TotalCosts;
		}

		get postsale() : number
		{
			return this.Model.TotalCostsPostsale.TotalCosts;
		}
	}
