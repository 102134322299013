






































































	import { Component, Prop } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { FeeTotalExpectedRevenueSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationExpectedRevenueDetails extends mixins( SharedMixin )
	{
		@Prop({ type: Object, default: function(){ return {} } }) readonly feeTotalExpectedRevenue!: FeeTotalExpectedRevenueSectionView;

        // Expected Fee
        get expectedFee() : number
        {
            return this.feeTotalExpectedRevenue.ExpectedFee;
        }

        // Total Expected Revenue
        get expectedRevenue (): number
		{
            return this.feeTotalExpectedRevenue.TotalExpectedRevenue;
		}

		get expectedCosts (): number
		{
            return this.feeTotalExpectedRevenue.ExpectedCosts;
		}

        get expectedMarginsRevenue (): number
		{
			return this.feeTotalExpectedRevenue.ExpectedMargins;
		}

        get expectedMarginsRevenuePerc (): string
		{
            return this.feeTotalExpectedRevenue.ExpectedMarginsPercentage.toFixed(2);
		}
	}
