import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/infrastructure/store';
import '@/App.container';
import pgaDiContainer from '@/App.container';

import IOrderService,
    {   ListOfferEvaluationCustomerOrder, OfferEvaluationCustomerOrderTableView, OrderTipology, 
            OfferEvaluationCustomerOrderDetailsView, ComponentDetailsView, AdditionalCostPresaleDetailsView,
            OtherCostPresaleDetailsView, AdditionalCostPostsaleDetailsView, OfferEvaluationCustomerOrderVersionView,
        CreateOfferEvaluationCustomerOrderHeaderCommand, SaveOfferEvaluationCustomerOrderCommand, EditOfferEvaluationCustomerOrderHeaderCommand,
            ActiveOfferEvaluationCustomerOrderCommand, RequestApprovalOfferEvaluationCustomerOrderCommand, ReassignmentACOMCustomerOrderCommand,
            ReassignmentSingleACOMCommand, PreCheckReassignmentACOMCustomerOrderCommand,
        CreateOfferEvaluationCustomerOrderHeaderResponse, SaveOfferEvaluationCustomerOrderResponse, EditOfferEvaluationCustomerOrderHeaderResponse,
            ReassignmentACOMCustomerOrderResponse, CheckIfExistsCustomerOrderByCRMDealIdResponse,
        ResourceDetailsView, BlobMetaDataView,
        StatusOfferEvaluation } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
        
import iIdentityService, { UserSimple } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';

import { SharedModule, parseErrors, deepCopy } from '@/feature/Shared/store';
import { SectionStore } from './SectionStore';

export interface CustomerOrdersState
{
    CustomerOrders: Array<OfferEvaluationCustomerOrderTableView>;
    CustomerOrdersClosed: Array<OfferEvaluationCustomerOrderTableView>;
    CustomerOrderDetails: OfferEvaluationCustomerOrderDetailsView;
    CreateCustomerOrderHeader: CreateCustomerOrderHeaderModel;
    EditCustomerOrderHeader: EditCustomerOrderHeaderModel;
    SaveCustomerOrder: SaveCustomerOrderModel;
    ReassignACOMCustomerOrder: ReassignACOMCustomerOrderModel;

    ACOMDropdown: Array<UserSimple>;
}

export interface CreateCustomerOrderHeaderModel
{
    Command: CreateOfferEvaluationCustomerOrderHeaderCommand;
    Response: CreateOfferEvaluationCustomerOrderHeaderResponse;
    Errors: Map<string, Array<string>>;
}

export interface EditCustomerOrderHeaderModel
{
    Command: EditOfferEvaluationCustomerOrderHeaderCommand;
    Response: EditOfferEvaluationCustomerOrderHeaderResponse;
    Errors: Map<string, Array<string>>;
}

export interface SaveCustomerOrderModel
{
    Command: SaveOfferEvaluationCustomerOrderCommand;
    Response: SaveOfferEvaluationCustomerOrderResponse;
    Errors: Map<string, Array<string>>;
}

export interface ReassignACOMCustomerOrderModel
{
    Command: ReassignmentACOMCustomerOrderCommand;
    Response: ReassignmentACOMCustomerOrderResponse;
    Errors: Map<string, Array<string>>;
}

@Module( { namespaced: true, dynamic: true, store, name: 'CustomerOrderModule', } )
class CustomerOrdersStore extends VuexModule implements CustomerOrdersState
{
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>( OrderService );
    private identityService: iIdentityService = pgaDiContainer.get<iIdentityService>(IdentityService);

    //Table State
    public CustomerOrders: Array<OfferEvaluationCustomerOrderTableView> = new Array<OfferEvaluationCustomerOrderTableView>();
    public CustomerOrdersClosed: Array<OfferEvaluationCustomerOrderTableView> = new Array<OfferEvaluationCustomerOrderTableView>();

    //Details State
    public CustomerOrderDetails: OfferEvaluationCustomerOrderDetailsView =
    {
        Id: Guid.createEmpty(),
        ACOM: 
        {
            Id: Guid.createEmpty(),
            FullName: ""
        },
        CustomerName: "" ,
        Description: "",
        OrderProtocol: "",
        Type: "",
        MasterAgreement: false,
        // CRM
        CRMDealId: "",
        CRMAmount: 0,
        CRMLastUpdate: new Date(),
        // Approval
        ApprovalOfferDC: false,
        ApprovalOfferLimitDC: false,
        ApprovalOfferLimitDG: false,
        ApprovalMOLDC: false,
        ApprovalMOLDG: false,
        ApprovalMinimumMOLDC: false,
        ApprovalMinimumMOLDG: false,
        ReviewSG: false,
        ApprovalDHRG: false,
        // Exceeded Limits
        ExceedOfferLimit: false,
        NotExceedMOL: false,
        // Status
        Status: StatusOfferEvaluation.Draft,
        // Sections
        FixedPriceProject:
        {
            TotalTaxableAmount: 0,
        },
        HumanResources:
        {
            ExpectedMen: 0,
            OfferedMen: 0,
            MarginRisk: 0,
            OfferAmount: 0,
            ExpectedCost: 0,
            ExpectedMargin: 0,
            ExpectedMarginPercentage: 0,
            Resources: new Array<ResourceDetailsView>(),
        },
        OtherComponents:
        {
            OfferAmount: 0,
            ExpectedCost: 0,
            ExpectedMargin: 0,
            ExpectedMarginPercentage: 0,
            ProductsPurchase: 
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                SupplierCode: "",
                SupplierBusinessName: "",
                ProductCatalog: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
                ExpectedMargin: 0,
                ExpectedMarginPercentage: 0,
            },
            SolutionsPurchase: 
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                SupplierCode: "",
                SupplierBusinessName: "",
                SolutionCatalog: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
                ExpectedMargin: 0,
                ExpectedMarginPercentage: 0,
            },
            ServicesPurchase:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                SupplierCode: "",
                SupplierBusinessName: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
                ExpectedMargin: 0,
                ExpectedMarginPercentage: 0,
            },
            TransferCosts:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
                ExpectedMargin: 0,
                ExpectedMarginPercentage: 0,
            },
            SecurityCosts:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
                ExpectedMargin: 0,
                ExpectedMarginPercentage: 0,
            },
            Others: new Array<ComponentDetailsView>(),
        },
        TotalAmounts:
        {
            OfferAmounts: 0,
            ExpectedCosts: 0,
            ExpectedMargins:0,
            ExpectedMarginsPercentage: 0,
            Discount: 0,
        },
        TotalAmountsDiscounted:
        {
            OfferAmounts: 0,
            ExpectedCosts: 0,
            ExpectedMargins:0,
            ExpectedMarginsPercentage: 0,
        },
        AdditionalCostsPresale:
        {
            AdditionalCosts: new Array<AdditionalCostPresaleDetailsView>(),
            DaysMen: 0,
            ExpectedCost: 0,
        },
        OtherCostsPresale:
        {
            ExpectedCost: 0,
            OtherCosts: new Array<OtherCostPresaleDetailsView>(),
        },
        TotalCostsPresale:
        {
            TotalCosts: 0,
        },
        AdditionalCostsPostsale:
        {
            Maintenance:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                Description: "",
                ExpectedCost: 0,
            },
            Warranty:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                Description: "",
                ExpectedCost: 0,
            },
            Support:
            {
                BusinessLine: "",
                TechArea: "",
                SupplyTipology: "",
                Description: "",
                ExpectedCost: 0,
            },
            Others: new Array<AdditionalCostPostsaleDetailsView>(),
        },
        TotalCostsPostsale:
        {
            TotalCosts: 0,
        },
        TotalsOfferEvaluation:
        {
            OfferAmounts: 0,
            ExpectedCosts: 0,
            ExpectedMargins: 0,
            ExpectedMarginsPercentage: 0,
        },
        FeeTotalExpectedRevenue:
        {
            ExpectedFee: 0,
            TotalExpectedRevenue: 0,
            ExpectedCosts: 0,
            ExpectedMargins: 0,
            ExpectedMarginsPercentage: 0,
        },
        Attachments:
        {
            Notes: "",
            Blobs: new Array<BlobMetaDataView>(),
        },
        Versions: new Array<OfferEvaluationCustomerOrderVersionView>(),
    }

    // Create State
    public CreateCustomerOrderHeader: CreateCustomerOrderHeaderModel =
    {
        Command: 
        {
            ACOMId: null,
            CustomerName: "",
            Description: "",
            Type: OrderTipology.New,
            MasterAgreement: false,
            // CRM
            CRMDealId: SectionStore?.CRMDeal?.Id ?? "",
            CRMAmount: SectionStore?.CRMDeal?.Properties.Amount ?? 0,
            CRMLastUpdate: SectionStore?.CRMDeal?.Properties.HsLastModifiedDate ?? new Date(),
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string,Array<string>>(),
    };

    // Edit State
    public EditCustomerOrderHeader: EditCustomerOrderHeaderModel =
    {
        Command: 
        {
            Id: Guid.createEmpty(),
            ACOMId: null,
            CustomerName: "",
            Description: "",
            OrderProtocol: "",
            Type: OrderTipology.New,
            MasterAgreement: false,
            // CRM
            CRMDealId: "",
            CRMAmount: 0,
            CRMLastUpdate: new Date(Date.now()),
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string,Array<string>>(),
    };

    // Save State
    public SaveCustomerOrder: SaveCustomerOrderModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
        },
        Response:
        {
            Id: Guid.createEmpty(),
            WarningMessages: new Array<string>(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Reassignment ACOM State
    public ReassignACOMCustomerOrder: ReassignACOMCustomerOrderModel =
    {
        Command:
        {
            SendMailACOMOutcoming: false,
            ACOMOutcomingId: Guid.createEmpty(),
            Reassignments: new Array<ReassignmentSingleACOMCommand>(),
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // ACOM Dropdown
    public ACOMDropdown = new Array<UserSimple>();
    @Action({ rawError: true })
    public async GetACOMDropdown(): Promise<void>
    {
        const acomDropdown: Array<UserSimple> = await this.identityService.GetACOMs();
        const ACOM_DC: UserSimple =
        {
            Id: SharedModule.UserId,
            FullName: SharedModule.Name.toUpperCase(),
        } 
        acomDropdown.push(ACOM_DC);
        this.SET_ACOM_DROPDOWN(acomDropdown);
    }
    @Mutation
    public SET_ACOM_DROPDOWN(acoms: Array<UserSimple>)
    {
        this.ACOMDropdown = acoms;
    }

    // CUSTOMERORDERS TABLE
    @Action( { rawError: true } )
    public async ListOfferEvaluationCustomerOrders (): Promise<void>
    {
        const offers: ListOfferEvaluationCustomerOrder = await this.orderService.GetAllOfferEvaluationCustomerOrders();
        this.SET_CUSTOMERORDERS_TABLE( offers.OfferEvaluationCustomerOrders );
    }
    @Mutation
    public SET_CUSTOMERORDERS_TABLE ( offers: Array<OfferEvaluationCustomerOrderTableView> )
    {
        this.CustomerOrders = offers;
    }

    // CUSTOMERORDERS BY ACOM ID TABLE
    @Action( { rawError: true } )
    public async ListOfferEvaluationCustomerOrdersByACOMId (acomId: Guid): Promise<Array<OfferEvaluationCustomerOrderTableView>>
    {
        const customerOrders: ListOfferEvaluationCustomerOrder = await this.orderService.GetAllOfferEvaluationCustomerOrdersByACOMId(acomId);
        return customerOrders.OfferEvaluationCustomerOrders;
    }

    // CUSTOMERORDER CLOSED TABLE
    @Action( { rawError: true } )
    public async ListOfferEvaluationCustomerOrdersClosed (): Promise<void>
    {
        const offersClosed: ListOfferEvaluationCustomerOrder = await this.orderService.GetAllOfferEvaluationCustomerOrdersClosed();
        this.SET_CUSTOMERORDERS_CLOSED_TABLE( offersClosed.OfferEvaluationCustomerOrders );
    }
    @Mutation
    public SET_CUSTOMERORDERS_CLOSED_TABLE ( offersClosed: Array<OfferEvaluationCustomerOrderTableView> )
    {
        this.CustomerOrdersClosed = offersClosed;
    }

    // CUSTOMERORDER DETAILS
    @Action( { rawError: true } )
    public async GetOfferEvaluationCustomerOrder (id: Guid): Promise<void>
    {
        const offer: OfferEvaluationCustomerOrderDetailsView = await this.orderService.GetOfferEvaluationCustomerOrderById(id);
        offer.CRMLastUpdate = new Date(offer.CRMLastUpdate);
        this.SET_CUSTOMERORDERS_DETAILS( offer );
    }
    @Mutation
    public SET_CUSTOMERORDERS_DETAILS ( offer: OfferEvaluationCustomerOrderDetailsView )
    {
        this.CustomerOrderDetails = offer;
    }

    // CREATE CUSTOMERORDER
    @Action( { rawError: true } )
    public async CreateOfferEvaluationCustomerOrder (): Promise<CreateOfferEvaluationCustomerOrderHeaderResponse>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.CreateOfferEvaluationCustomerOrderHeader(this.CreateCustomerOrderHeader.Command);
            SharedModule.SET_ISLOADING(false);
            this.SET_CREATE_CUSTOMERORDER_HEADER_RESPONSE(response);
            this.SET_CREATE_CUSTOMERORDER_HEADER_ERRORS(new Map<string, Array<string>>());
            this.SetCommandToEditCustomerOrderHeader(response.Id);
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_CREATE_CUSTOMERORDER_HEADER_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetCreateCustomerOrderCommand()
    {
        const emptyCommand: CreateOfferEvaluationCustomerOrderHeaderCommand =
        {
            ACOMId: null,
            CustomerName: "",
            Description: "",
            Type: OrderTipology.New,
            MasterAgreement: false,
            // CRM
            CRMDealId: SectionStore?.CRMDeal?.Id ?? "",
            CRMAmount: SectionStore?.CRMDeal?.Properties.Amount ?? 0,
            CRMLastUpdate: SectionStore?.CRMDeal?.Properties.HsLastModifiedDate ?? new Date(),
        };
        this.SET_CREATE_CUSTOMERORDER_HEADER_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_COMMAND ( command: CreateOfferEvaluationCustomerOrderHeaderCommand )
    {
        this.CreateCustomerOrderHeader.Command = command;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_RESPONSE ( response: CreateOfferEvaluationCustomerOrderHeaderResponse )
    {
        this.CreateCustomerOrderHeader.Response = response;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_ACOMID ( acomId: Guid | null )
    {
        this.CreateCustomerOrderHeader.Command.ACOMId = acomId;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_CUSTOMERNAME ( customerName: string )
    {
        this.CreateCustomerOrderHeader.Command.CustomerName = customerName;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_DESCRIPTION ( description: string )
    {
        this.CreateCustomerOrderHeader.Command.Description = description;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_ORDERTYPE ( type: OrderTipology )
    {
        this.CreateCustomerOrderHeader.Command.Type = type;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_MASTERAGREEMENT ( masterAgreement: boolean )
    {
        this.CreateCustomerOrderHeader.Command.MasterAgreement = masterAgreement;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_CRMDEALID ( crmDealId: string )
    {
        this.CreateCustomerOrderHeader.Command.CRMDealId = crmDealId;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_CRMAMOUNT ( crmAmount: number )
    {
        this.CreateCustomerOrderHeader.Command.CRMAmount = crmAmount;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_CRMLASTUPDATE ( crmLastUpdate: Date )
    {
        this.CreateCustomerOrderHeader.Command.CRMLastUpdate = crmLastUpdate;
    }
    @Mutation
    public SET_CREATE_CUSTOMERORDER_HEADER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.CreateCustomerOrderHeader.Errors = errors;
    }

    // EDIT CUSTOMERORDER
    @Action( { rawError: true } )
    public async EditOfferEvaluationCustomerOrder (): Promise<EditOfferEvaluationCustomerOrderHeaderResponse>
    {
        try
        {
            const response = await this.orderService.EditOfferEvaluationCustomerOrderHeader(this.EditCustomerOrderHeader.Command);
            this.SET_EDIT_CUSTOMERORDER_HEADER_RESPONSE(response);
            this.SET_EDIT_CUSTOMERORDER_HEADER_ERRORS(new Map<string, Array<string>>());
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_CUSTOMERORDER_HEADER_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async InitEditCustomerOrderCommand(id: Guid) : Promise<void>
    {
        const customerOrderToEdit = await this.orderService.GetOfferEvaluationCustomerOrderByIdToEdit(id);
        const headerCommand: EditOfferEvaluationCustomerOrderHeaderCommand =
        {
            Id: customerOrderToEdit.Id,
            ACOMId: null,
            CustomerName: customerOrderToEdit.CustomerName,
            Description: customerOrderToEdit.Description,
            OrderProtocol: customerOrderToEdit.OrderProtocol,
            Type: customerOrderToEdit.Type,
            MasterAgreement: customerOrderToEdit.MasterAgreement,
            // CRM
            CRMDealId: customerOrderToEdit.CRMDealId,
            CRMAmount: customerOrderToEdit.CRMAmount,
            CRMLastUpdate: new Date(customerOrderToEdit.CRMLastUpdate),
        }
        this.SET_EDIT_CUSTOMERORDER_HEADER_COMMAND(headerCommand);
        SectionStore.SET_EDIT_FIXEDPRICEPROJECT_COMMAND(customerOrderToEdit.FixedPriceProject);
        SectionStore.SET_EDIT_HUMANRESOURCES_COMMAND(customerOrderToEdit.HumanResources);
        SectionStore.SET_EDIT_OTHERCOMPONENTS_COMMAND(customerOrderToEdit.OtherComponents);
        SectionStore.SET_EDIT_TOTALAMOUNTS_COMMAND(customerOrderToEdit.TotalAmounts);
        SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND(customerOrderToEdit.AdditionalCostsPresale);
        SectionStore.SET_EDIT_OTHERCOSTSPRESALE_COMMAND(customerOrderToEdit.OtherCostsPresale);
        SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND(customerOrderToEdit.AdditionalCostsPostsale);
        SectionStore.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_COMMAND(customerOrderToEdit.FeeTotalExpectedRevenue);
        SectionStore.SET_EDIT_ATTACHMENTS_COMMAND(customerOrderToEdit.Attachments);

        // Setting commands copies
        SectionStore.SET_EDIT_FIXEDPRICEPROJECT_COMMAND_COPY(deepCopy(customerOrderToEdit.FixedPriceProject));
        SectionStore.SET_EDIT_HUMANRESOURCES_COMMAND_COPY(deepCopy(customerOrderToEdit.HumanResources));
        SectionStore.SET_EDIT_OTHERCOMPONENTS_COMMAND_COPY(deepCopy(customerOrderToEdit.OtherComponents));
        SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND_COPY(deepCopy(customerOrderToEdit.AdditionalCostsPresale));
        SectionStore.SET_EDIT_OTHERCOSTSPRESALE_COMMAND_COPY(deepCopy(customerOrderToEdit.OtherCostsPresale));
        SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND_COPY(deepCopy(customerOrderToEdit.AdditionalCostsPostsale));
        SectionStore.SET_EDIT_ATTACHMENTS_COMMAND_COPY(deepCopy(customerOrderToEdit.Attachments));
        SectionStore.SET_UPLOAD_FILES_ID(customerOrderToEdit.Id);
        this.SET_SAVE_CUSTOMERORDER_ID(customerOrderToEdit.Id);
    }
    @Action( { rawError: true } )
    public ResetEditCustomerOrderCommand()
    {
        const emptyCommand: EditOfferEvaluationCustomerOrderHeaderCommand =
        {
            Id: Guid.createEmpty(),
            ACOMId: null,
            CustomerName: "",
            Description: "",
            OrderProtocol: "",
            Type: OrderTipology.New,
            MasterAgreement: false,
            // CRM
            CRMDealId: "",
            CRMAmount: 0,
            CRMLastUpdate: new Date(),
        };
        this.SET_EDIT_CUSTOMERORDER_HEADER_COMMAND(emptyCommand);
    }
    @Action( { rawError: true } )
    private SetCommandToEditCustomerOrderHeader(id: Guid) : void
    {
        const createCommand = this.CreateCustomerOrderHeader.Command;
        this.SET_EDIT_CUSTOMERORDER_HEADER_ID(id);
        this.SET_EDIT_CUSTOMERORDER_HEADER_ACOMID(createCommand.ACOMId);
        this.SET_EDIT_CUSTOMERORDER_HEADER_CUSTOMERNAME(createCommand.CustomerName);
        this.SET_EDIT_CUSTOMERORDER_HEADER_DESCRIPTION(createCommand.Description);
        this.SET_EDIT_CUSTOMERORDER_HEADER_ORDERTYPE(createCommand.Type);
        this.SET_EDIT_CUSTOMERORDER_HEADER_MASTERAGREEMENT(createCommand.MasterAgreement);
        this.SET_EDIT_CUSTOMERORDER_HEADER_CRMDEALID(createCommand.CRMDealId);
        this.SET_EDIT_CUSTOMERORDER_HEADER_CRMAMOUNT(createCommand.CRMAmount);
        this.SET_EDIT_CUSTOMERORDER_HEADER_CRMLASTUPDATE(createCommand.CRMLastUpdate);

    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_COMMAND ( command: EditOfferEvaluationCustomerOrderHeaderCommand )
    {
        this.EditCustomerOrderHeader.Command = command;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_RESPONSE ( response: EditOfferEvaluationCustomerOrderHeaderResponse )
    {
        this.EditCustomerOrderHeader.Response = response;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_ID ( id: Guid )
    {
        this.EditCustomerOrderHeader.Command.Id = id;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_ACOMID ( acomId: Guid | null )
    {
        this.EditCustomerOrderHeader.Command.ACOMId = acomId;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_CUSTOMERNAME ( customerName: string )
    {
        this.EditCustomerOrderHeader.Command.CustomerName = customerName;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_DESCRIPTION ( description: string )
    {
        this.EditCustomerOrderHeader.Command.Description = description;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_PROTOCOL ( protocol: string )
    {
        this.EditCustomerOrderHeader.Command.OrderProtocol = protocol;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_ORDERTYPE ( type: OrderTipology )
    {
        this.EditCustomerOrderHeader.Command.Type = type;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_MASTERAGREEMENT ( masterAgreement: boolean )
    {
        this.EditCustomerOrderHeader.Command.MasterAgreement = masterAgreement;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_CRMDEALID ( crmDealId: string )
    {
        this.EditCustomerOrderHeader.Command.CRMDealId = crmDealId;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_CRMAMOUNT ( crmAmount: number )
    {
        this.EditCustomerOrderHeader.Command.CRMAmount = crmAmount;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_CRMLASTUPDATE ( crmLastUpdate: Date )
    {
        this.EditCustomerOrderHeader.Command.CRMLastUpdate = crmLastUpdate;
    }
    @Mutation
    public SET_EDIT_CUSTOMERORDER_HEADER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditCustomerOrderHeader.Errors = errors;
    }
    
    // SAVE CUSTOMERORDER
    @Action( { rawError: true } )
    public async SaveOfferEvaluationCustomerOrder (): Promise<SaveOfferEvaluationCustomerOrderResponse>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.SaveOfferEvaluationCustomerOrder(this.SaveCustomerOrder.Command);
            this.SET_SAVE_CUSTOMERORDER_ERRORS(new Map<string, Array<string>>());
            SharedModule.SET_ISLOADING(false);
            if(response.WarningMessages.length > 0)
            {
                const message = response.WarningMessages.join("<br>");
                SharedModule.SET_ALERT_WARNING_MESSAGE(message);
            }
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_SAVE_CUSTOMERORDER_ERRORS(errorParsed);
            SharedModule.SET_ALERT_ERROR_MESSAGE(errorParsed.get('id')?.[0] ?? "");
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            errorParsed
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetSaveCustomerOrderCommand()
    {
        const emptyCommand: SaveOfferEvaluationCustomerOrderCommand =
        {
            Id: Guid.createEmpty(),
        };
        this.SET_SAVE_CUSTOMERORDER_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_SAVE_CUSTOMERORDER_COMMAND ( command: SaveOfferEvaluationCustomerOrderCommand )
    {
        this.SaveCustomerOrder.Command = command;
    }
    @Mutation
    public SET_SAVE_CUSTOMERORDER_ID ( id: Guid )
    {
        this.SaveCustomerOrder.Command.Id = id;
    }
    @Mutation
    public SET_SAVE_CUSTOMERORDER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.SaveCustomerOrder.Errors = errors;
    }

    @Action( { rawError: true } )
    public async DeleteCustomerOrder() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            await this.orderService.DeleteOfferEvaluationCustomerOrder(customerOrder.Id);
            SharedModule.SET_ISLOADING(false);
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            SharedModule.SET_ALERT_ERROR_MESSAGE(errorParsed.get('id')?.join('<br> - ') ?? "");
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            throw errorParsed;
        }
    }

    @Action( { rawError: true } )
    public async ActiveCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            const cmd: ActiveOfferEvaluationCustomerOrderCommand =
            {
                Id: customerOrder.Id,
            };
            const response = await this.orderService.ActiveOfferEvaluationCustomerOrder(cmd);

            if(response.WarningMessages.length > 0)
            {
                const msg = response.WarningMessages.join("<br>");
                SharedModule.SET_ALERT_WARNING_MESSAGE(msg);
                customerOrder.Status = StatusOfferEvaluation.ApprovalRequestable;
            }
            else
            {
                customerOrder.Status = StatusOfferEvaluation.Activating;
            }
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async RequestApprovalCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            const cmd: RequestApprovalOfferEvaluationCustomerOrderCommand =
            {
                Id: customerOrder.Id,
            };
            await this.orderService.RequestApprovalOfferEvaluationCustomerOrder(cmd);
            if(SharedModule.Permissions.includes('OfferEvaluationCustomerOrderReviewSG'))
                customerOrder.Status = StatusOfferEvaluation.RequestApprovalDHRG;
            else
                customerOrder.Status = StatusOfferEvaluation.Activating;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async ApproveCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            await this.orderService.ApproveOfferEvaluationCustomerOrder(customerOrder.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async CreateAssessmentCustomerOrder() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const response = await this.orderService.CreateAssessmentOfferEvaluationCustomerOrder(this.CustomerOrderDetails.Id);
            await this.GetOfferEvaluationCustomerOrder(response.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async NotifyAssessmentCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            const response = await this.orderService.NotifyAssessmentOfferEvaluationCustomerOrder(customerOrder.Id);

            if(response.WarningMessages.length > 0)
            {
                const msg = response.WarningMessages.join("<br>");
                SharedModule.SET_ALERT_WARNING_MESSAGE(msg);
                customerOrder.Status = StatusOfferEvaluation.ApprovalRequestable;
            }
            else
            {
                customerOrder.Status = StatusOfferEvaluation.Activating;
            }
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async RequestApprovalAssessmentCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            await this.orderService.RequestApprovalAssessmentOfferEvaluationCustomerOrder(customerOrder.Id);
            customerOrder.Status = StatusOfferEvaluation.Activating;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async ApproveAssessmentCustomerOrderCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.CustomerOrderDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const customerOrder = this.CustomerOrderDetails;
            await this.orderService.ApproveAssessmentOfferEvaluationCustomerOrder(customerOrder.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }


    // REASSIGN ACOM TO CUSTOMERORDER
    @Action( { rawError: true } )
    public async ReassignACOMOfferEvaluationCustomerOrder (): Promise<ReassignmentACOMCustomerOrderResponse>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.ReassignmentACOMCustomerOrder(this.ReassignACOMCustomerOrder.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_REASSIGN_ACOM_CUSTOMERORDER_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetReassignmentCustomerOrderCommand()
    {
        const emptyCommand: ReassignmentACOMCustomerOrderCommand =
        {
        SendMailACOMOutcoming: false,
        ACOMOutcomingId: Guid.createEmpty(),
        Reassignments: new Array<ReassignmentSingleACOMCommand>(),
        };
        this.SET_REASSIGN_ACOM_CUSTOMERORDER_COMMAND(emptyCommand);
        this.SET_REASSIGN_ACOM_CUSTOMERORDER_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_COMMAND ( command: ReassignmentACOMCustomerOrderCommand )
    {
        this.ReassignACOMCustomerOrder.Command = command;
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_ACOM_OUT_ID ( acomId: Guid )
    {
        this.ReassignACOMCustomerOrder.Command.ACOMOutcomingId = acomId;
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_SEND_EMAIL_ACOM_OUTCOMING ( value: boolean )
    {
        this.ReassignACOMCustomerOrder.Command.SendMailACOMOutcoming = value;
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_REASSIGNMENTS(reassignments: Array<ReassignmentSingleACOMCommand>)
    {
        this.ReassignACOMCustomerOrder.Command.Reassignments = reassignments;
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_REASSIGNMENT_ROW(reassignment: ReassignmentSingleACOMCommand)
    {
        this.ReassignACOMCustomerOrder.Command.Reassignments
            .forEach(r =>
                {
                    if(r.CustomerOrderId === reassignment.CustomerOrderId)
                    {
                        r.ACOMIncomingId = reassignment.ACOMIncomingId;
                    }
                });
    }
    @Mutation
    public SET_REASSIGN_ACOM_CUSTOMERORDER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.ReassignACOMCustomerOrder.Errors = errors;
    }

    // PRECHECK REASSIGNMENT ACOM TO CUSTOMERORDER
    @Action( { rawError: true } )
    public async PreCheckReassignACOMOfferEvaluationCustomerOrder (): Promise<boolean>
    {
        try
        {
            const commandPrecheck: PreCheckReassignmentACOMCustomerOrderCommand =
            {
                Reassingments: this.ReassignACOMCustomerOrder.Command.Reassignments,
            }
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.PreCheckReassignmentACOMCustomerOrder(commandPrecheck);
            SharedModule.SET_ISLOADING(false);
            return response.Checked;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            let messageWarn = "";
            this.ReassignACOMCustomerOrder.Command.Reassignments
                .forEach((_, index) => 
                {
                    const warnRow = errorParsed.get(`Reassingments[${index}].CustomerOrderId`)?.[0] ?? "";
                    if(warnRow !== "")
                        messageWarn += `- ${warnRow}<br>`;
                });
            throw messageWarn;
        }
    }

    // CHECK IF EXISTS CUSTOMER ORDER BY CRM DEAL ID
    @Action( { rawError: true } )
    public async CheckIfExistsCustomerOrderByCRMDealId (): Promise<boolean>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.CheckIfExistsCustomerOrderByCRMDealId(SectionStore.CRMDeal.Id);
            SharedModule.SET_ISLOADING(false);
            return response.Checked;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            const errMessage = errorParsed.get('crmDealId')?.[0] ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE(errMessage);
            throw error;
        }
    }
}

export const CustomerOrderStore = getModule( CustomerOrdersStore );