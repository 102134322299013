


































































































































import { Component, Prop } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { TechAreaModel, TipologiaFornituraModel, BusinessLineTableView } from '@/services/iOrderService';
import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";

@Component({})
export default class PgaOfferEvaluationOtherComponentsOthersCreate extends mixins(SharedMixin)
{
	@Prop({ type: Number, default: 0 }) readonly index!: number;
	@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;
	
	get errorSubSection() : string
	{
		let color = "";
		this.Errors.forEach((value, key) =>
		{
			if(key.includes(`Others[${this.index}]`))
				color = "error";
		});
		return color;
	}

	//Details Other Components: first row
	get businessLine() : Guid | null
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].BusinessLineId;
	}
	set businessLine(value: Guid | null)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_TECHAREA_ID({ index: this.index, techAreaId: null });
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_BUSINESSLINE_ID({ index: this.index, businessLineId: value });
	}

	get techArea() : Guid | null
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].TechAreaId;
	}
	set techArea(value: Guid | null)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_TECHAREA_ID({ index: this.index, techAreaId: value });
	}

	get supplyTipology() : Guid | null
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].SupplyTipologyId;
	}
	set supplyTipology(value: Guid | null)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_SUPPLYTIPOLOGY_ID({ index: this.index, supplyTipologyId: value });
	}

	get componentTipology() : string
	{
		return "OTHERS";
	}

	get description() : string
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].Description;
	}
	set description(value: string)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_DESCRIPTION({ index: this.index, description: value });
	}
	
	get offerAmount() : number
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].OfferAmount;
	}
	set offerAmount(value: number)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_OFFERAMOUNT({ index: this.index, offerAmount: value });
	}

	get expectedCost() : number
	{
		return SectionStore.EditOtherComponents.Command.Others[this.index].ExpectedCost;
	}
	set expectedCost(value: number)
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_OTHER_EXPECTEDCOST({ index: this.index, expectedCost: value });
	}

	get expectedMargin() : number
	{
		return this.offerAmount - this.expectedCost;
	}

	get expectedMarginPerc() : string
	{
		if(this.offerAmount == 0)
			return "0";
		return ((this.expectedMargin / this.offerAmount) * 100).toFixed(2);
	}

	//Dropdown Lists
	get businessLineDropdown() : Array<BusinessLineTableView>
	{
		return SectionStore.BusinessLinesDropdown;
	}
	get techAreaDropdown() : Array<TechAreaModel>
	{		
		return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
	}
	get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
	{
		return SectionStore.SupplyTipologiesDropdown;
	}

	get Errors() : Map<string, Array<string>>
	{
		return SectionStore.EditOtherComponents.Errors;
	}

	private removeComponent() : void
	{
		SectionStore.SET_EDIT_OTHERCOMPONENTS_REMOVE_OTHER_COMPONENT(this.index);
	}
}
