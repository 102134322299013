











	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaBusinessStartupCustomerOrderCreate from './components/create/PgaBusinessStartupCustomerOrderCreate.vue';
    import { CustomerOrderStore } from "./store";

	@Component( {
		components:
		{
			PgaBusinessStartupCustomerOrderCreate,
		},
	} )
    export default class BusinessStartupPageCustomerOrderCreate extends mixins( SharedMixin )
    {
		private isLoaded = false;

        private undo()
        {
            this.$router.go(-1);
        }

		async mounted() : Promise<void>
        {
			await Promise.all([
				CustomerOrderStore.GetBusinessLinesDropdown(),
				CustomerOrderStore.GetTechAreasDropdown(),
				CustomerOrderStore.GetSupplyTipologiesDropdown(),
			]);
			this.isLoaded = true;
        }

		beforeDestroy() : void
		{
			CustomerOrderStore.ResetBusinessStartupCustomerOrderCreate();
		}
    }
