

















































































































































































































































































    import { Component, Model } from "vue-property-decorator";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    import { OfferEvaluationCustomerOrderDetailsView } from "@/services/iOrderService";
    import { IsMobile } from "@/feature/Shared/store";
    
    // Fixed Price Project
    import PgaOfferEvaluationFixedPriceProjectDetails from "./sub-components/PgaOfferEvaluationFixedPriceProjectDetails.vue";
    // Human Resources
    import PgaOfferEvaluationHumanResourcesDetails from "./sub-components/PgaOfferEvaluationHumanResourcesDetails.vue";
    import PgaOfferEvaluationHumanResourcesSubtotalsDetails from "./sub-components/sub-totals/PgaOfferEvaluationHumanResourcesSubtotalsDetails.vue";
    // Other Components
    import PgaOfferEvaluationOtherComponentsProductDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsProductDetails.vue";
    import PgaOfferEvaluationOtherComponentsSolutionDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsSolutionDetails.vue";
    import PgaOfferEvaluationOtherComponentsServiceDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsServiceDetails.vue";
    import PgaOfferEvaluationOtherComponentsTransferDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsTransferDetails.vue";
    import PgaOfferEvaluationOtherComponentsSecurityDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsSecurityDetails.vue";
    import PgaOfferEvaluationOtherComponentsOthersDetails from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsOthersDetails.vue";
    import PgaOfferEvaluationOtherComponentsSubtotals from "./sub-components/sub-totals/PgaOfferEvaluationOtherComponentsSubtotals.vue";
    // Total Amounts
    import PgaOfferEvaluationTotalAmountsDetails from "./sub-components/PgaOfferEvaluationTotalAmountsDetails.vue";
    import PgaOfferEvaluationTotalAmountsDiscountedDetails from "./sub-components/PgaOfferEvaluationTotalAmountsDiscountedDetails.vue";
    // Additional Costs Presale
    import PgaOfferEvaluationAdditionalCostsPresaleDetails from "./sub-components/PgaOfferEvaluationAdditionalCostsDetails.vue";
    import PgaOfferEvaluationAdditionalCostsPresaleSubtotalsDetails from "./sub-components/sub-totals/PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue";
    // Other Costs Presale
    import PgaOfferEvaluationOtherCostsPresaleDetails from "./sub-components/PgaOfferEvaluationOtherCostsDetails.vue";
    import PgaOfferEvaluationOtherCostsPresaleSubtotalsDetails from "./sub-components/sub-totals/PgaOfferEvaluationOtherCostsPresaleSubtotalsDetails.vue";
    // Total Costs Presale/Postsale
    import PgaOfferEvaluationTotalCostsDetails from "./sub-components/PgaOfferEvaluationTotalCostsDetails.vue";
    // Additional Costs Postsale
    import PgaOfferEvaluationAdditionalCostsPostsaleDetails from "./sub-components/PgaOfferEvaluationAdditionalCostsPostsaleDetails.vue";
    // Totals OfferEvaluation
    import PgaOfferEvaluationTotalsOfferEvaluationDetails from "./sub-components/PgaOfferEvaluationTotalsOfferEvaluationDetails.vue";
    import PgaOfferEvaluationExpectedRevenueDetails from "./sub-components/PgaOfferEvaluationExpectedRevenueDetails.vue";
    // Attachments
    import PgaOfferEvaluationAttachmentsDetails from "./sub-components/PgaOfferEvaluationAttachmentsDetails.vue";

	@Component( {   
        components:
        {
            PgaOfferEvaluationFixedPriceProjectDetails,

            PgaOfferEvaluationHumanResourcesDetails,
            PgaOfferEvaluationHumanResourcesSubtotalsDetails,

            PgaOfferEvaluationOtherComponentsProductDetails,
            PgaOfferEvaluationOtherComponentsSolutionDetails,
            PgaOfferEvaluationOtherComponentsServiceDetails,
            PgaOfferEvaluationOtherComponentsTransferDetails,
            PgaOfferEvaluationOtherComponentsSecurityDetails,
            PgaOfferEvaluationOtherComponentsOthersDetails,
            PgaOfferEvaluationOtherComponentsSubtotals,

            PgaOfferEvaluationTotalAmountsDetails,
            PgaOfferEvaluationTotalAmountsDiscountedDetails,

            PgaOfferEvaluationAdditionalCostsPresaleDetails,
            PgaOfferEvaluationAdditionalCostsPresaleSubtotalsDetails,

            PgaOfferEvaluationOtherCostsPresaleDetails,
            PgaOfferEvaluationOtherCostsPresaleSubtotalsDetails,

            PgaOfferEvaluationTotalCostsDetails,

            PgaOfferEvaluationAdditionalCostsPostsaleDetails,

            PgaOfferEvaluationTotalsOfferEvaluationDetails,
            PgaOfferEvaluationExpectedRevenueDetails,

            PgaOfferEvaluationAttachmentsDetails,
        }
    })
    export default class PgaOfferEvaluationCustomerOrderDetails extends mixins(SharedMixin)
    {
        @Model("", { type: Object }) readonly Model!: OfferEvaluationCustomerOrderDetailsView;

        private panelsOpened = [0, 1, 2, 3, 4, 5, 6];
        private isMiniVariant = this.isMobile;

        get acom() : string
        {
            return this.Model.ACOM.FullName;
        }

        get customerName() : string
        {
            return this.Model.CustomerName;
        }

        get description() : string
        {
            return this.Model.Description;
        }

        get orderProtocol() : string
        {
            return this.Model.OrderProtocol;
        }

        get type() : string
        {
            return this.Model.Type;
        }

        get masterAgreement() : boolean
        {
            return this.Model.MasterAgreement;
        }

        // CRM
        get crmDealId() : string
        {
            return this.Model.CRMDealId;
        }

        get crmAmount() : number
        {
            return this.Model.CRMAmount;
        }

        get crmLastUpdate() : Date
        {
            return this.Model.CRMLastUpdate;
        }

        //Approval
        get approvalOfferDC() : boolean
        {
            return this.Model.ApprovalOfferDC;
        }

        get approvalOfferLimitDC() : boolean
        {
            return this.Model.ApprovalOfferLimitDC;
        }

        get approvalOfferLimitDG() : boolean
        {
            return this.Model.ApprovalOfferLimitDG;
        }

        get approvalMOLDC() : boolean
        {
            return this.Model.ApprovalMOLDC;
        }

        get approvalMOLDG() : boolean
        {
            return this.Model.ApprovalMOLDG;
        }

        get approvalMinimumMOLDC() : boolean
        {
            return this.Model.ApprovalMinimumMOLDC;
        }

        get approvalMinimumMOLDG() : boolean
        {
            return this.Model.ApprovalMinimumMOLDG;
        }

        get reviewSG() : boolean
        {
            return this.Model.ReviewSG;
        }

        get approvalDHRG() : boolean
        {
            return this.Model.ApprovalDHRG;
        }

        get isMobile() : boolean
        {
            return IsMobile(this.$vuetify.breakpoint.name);
        }

        get showMiniVariant() : boolean
        {
            return this.isMobile && this.isMiniVariant;
        }

        private changeMiniVariant() : void
        {
            this.isMiniVariant = !this.isMiniVariant;
        }
    }
