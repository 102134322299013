






















































































	import { Component, Prop } from "vue-property-decorator";
	import { clearTimeout, setTimeout } from 'timers';
	
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { SectionStore } from "../../../SectionStore";
	import { SupplierView } from "@/services/iOrderService";

	@Component({})
	export default class PgaOfferEvaluationOtherCostsPresaleCreate extends mixins(SharedMixin)
	{
		@Prop({ type: Number, default: 0 }) readonly index!: number;
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

		private dropdownLoading = false;
		private showDynamicDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

		get errorSubSection() : string
		{
			let color = "";
			this.Errors.forEach((value, key) =>
			{
				if(key.includes(`OtherCosts[${this.index}]`))
					color = "error";
			});
			return color;
		}

		get description() : string
		{
			return SectionStore.EditOtherCostsPresale.Command.OtherCosts[this.index].Description;
		}
		set description(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_DESCRIPTION({ index: this.index, description: value });
		}

		get supplierCode() : string
		{
			return SectionStore.EditOtherCostsPresale.Command.OtherCosts[this.index].SupplierCode;
		}
		set supplierCode(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERCODE({ index: this.index, supplierCode: value });
		}

		get supplierBusinessName() : string
		{
			return SectionStore.EditOtherCostsPresale.Command.OtherCosts[this.index].SupplierBusinessName;
		}
		set supplierBusinessName(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERBUSINESSNAME({ index: this.index, supplierBusinessName: value});
		}

		get supplier() : SupplierView | null
		{
			return this.suppliersDropdown.find(s => s.Code === this.supplierCode) ?? null;
		}
		set supplier(value: SupplierView | null)
		{
			this.showDynamicDropdown = false;
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERCODE({ index: this.index, supplierCode: value?.Code ?? "" });
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERBUSINESSNAME({ index: this.index, supplierBusinessName: value?.BusinessName ?? "" });
		}
		private requestSuppliersDebounced(value: string)
		{
			// cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 
				&& !this.suppliersDropdown.some(s => s.BusinessName == value)
				&& this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestSuppliers(value);
                }, 500);
				this.showDynamicDropdown = true;
            }
			else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

		get expectedCost() : number
		{
			return SectionStore.EditOtherCostsPresale.Command.OtherCosts[this.index].ExpectedCost;
		}
		set expectedCost(value: number)
		{
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_EXPECTEDCOST({ index: this.index, expectedCost: value});
		}

		get suppliersDropdown() : Array<SupplierView>
		{
			return SectionStore.SuppliersDropdown;
		}

		private async requestSuppliers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await SectionStore.GetSuppliersDropdown(search);
			this.dropdownLoading = false;
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditOtherCostsPresale.Errors;
		}

		private removeElement() : void
		{
			SectionStore.SET_EDIT_OTHERCOSTSPRESALE_REMOVE_ELEMENT(this.index);
		}
	}
