


























import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { PgaValutazioneOffertaSubtotaliAggiuntiviPresaleModel } from "../store";

@Component({
  computed: {
    subGGUomoOffPresale: {
      get() {
        return this.$props.Model.SubGGOffertaPresale;
      } 
    },
    subCostoAttesoPresale: {
      get() {
         return this.$props.Model.SubCostoAttesoPresale;
      }
    },
  }
})
export default class PgaValutazioneOffertaSubtotaliAggiuntiviPresale extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaSubtotaliAggiuntiviPresaleModel;
}
