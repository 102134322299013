

















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Business Line",
          align: "start",
          sortable: false,
          value: "BusinessLineComponente"
        },
        { text: "TechArea", value: "TechAreaComponente" },
        { text: "Tipologia Fornitura", value: "TipologiaFornituraComponente" },
        { text: "Tipologia Componente", value: "TipologiaComponente" },
        { text: "Descrizione Componente", value: "DescrizioneComponente" },
        {
          text: "Importo Offerta Componente",
          value: "ImportoOffertaComponente"
        },
        { text: "Costo Atteso Componente", value: "CostoAttesoComponente" },
        { text: "Margine Atteso Componente", value: "MargineAttesoComponente" },
        {
          text: "Margine Atteso Componente %",
          value: "MargineAttesoPercComponente"
        }
      ]
    };
  },
  computed: {
    AltreComponentiItems: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate;
      }
    }
  }
})
export default class AltreComponentiDisplay extends Vue {}
