





































import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaReassignmentAcom from "./components/reassignment-acom/PgaReassignmentAcom.vue";
import { CustomerOrderStore } from "./CustomerOrderStore";

@Component( {
	components: {
		PgaReassignmentAcom,
	},
} )
export default class OfferEvaluationPageCustomerOrderReassignACOM extends mixins(SharedMixin)
{
	private showFirstDialog = false;
	private showSecondDialog = false;
	private warningMessage = "";

	private async ResponseFirstDialog(value: boolean) : Promise<void>
	{
		CustomerOrderStore.SET_REASSIGN_ACOM_CUSTOMERORDER_SEND_EMAIL_ACOM_OUTCOMING(value);
		this.showFirstDialog = false;
		const responsePreCheck =  await this.PreCheckReassignACOM();
		if(!responsePreCheck)
			this.showSecondDialog = true;
		else
			await this.ReassignACOM();
	}

	private async PreCheckReassignACOM() : Promise<boolean>
	{
		let checked = false;
		try
		{
			checked = await CustomerOrderStore.PreCheckReassignACOMOfferEvaluationCustomerOrder();
		}
		catch(exception)
		{
			this.warningMessage = exception;
		}
		return checked;
	}

	private async ReassignACOM() : Promise<void>
	{
		this.showSecondDialog = false;
		await CustomerOrderStore.ReassignACOMOfferEvaluationCustomerOrder();
		this.undo();
	}

	private undo ()
	{
		this.$router.go( -1 );
	}

	async mounted() : Promise<void>
	{
		await CustomerOrderStore.GetACOMDropdown();
	}

	beforeDestroy() : void
	{
		CustomerOrderStore.ResetReassignmentCustomerOrderCommand();
	}
}
