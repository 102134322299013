import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';

import store from '@/infrastructure/store';
import '@/App.container';
import pgaDiContainer from '@/App.container';

import IOrderService,
    {   ListBusinessStartupInternalProjectTable, BusinessStartupInternalProjectViewTable, DeliverablesModel,
            BusinessStartupInternalProjectDetailsView, } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
import { Guid } from 'guid-typescript';

export interface InternalProjectsState
{
    InternalProjects: Array<BusinessStartupInternalProjectViewTable>;
    InternalProjectsClosed: Array<BusinessStartupInternalProjectViewTable>;
    InternalProjectDetails: BusinessStartupInternalProjectDetailsView;
}

@Module( { namespaced: true, dynamic: true, store, name: 'InternalProjectModule', } )
class InternalProjectsStore extends VuexModule implements InternalProjectsState
{
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>( OrderService );

    //Table State
    public InternalProjects: Array<BusinessStartupInternalProjectViewTable> = new Array<BusinessStartupInternalProjectViewTable>();
    public InternalProjectsClosed: Array<BusinessStartupInternalProjectViewTable> = new Array<BusinessStartupInternalProjectViewTable>();

    // Details State
    public InternalProjectDetails: BusinessStartupInternalProjectDetailsView =
    {
        Id: Guid.createEmpty(),
        Status: 1,
        ProjectRequirementsReview:
        {
            Title: "",
            Description: "",
            StartDate: new Date(),
            ExpectedEndDate: new Date(),

            BusinessLine: "",
            TechArea: "",
            SupplyTipology: "",

            Deliverables: new Array<DeliverablesModel>(),
            AreaManager: "",
            Notes: "",
        },
    }
    
    // INTERNALPROJECTS TABLE
    @Action( { rawError: true } )
    public async ListBusinessStartupInternalProjects (): Promise<void>
    {
        const internalProjects: ListBusinessStartupInternalProjectTable = await this.orderService.GetAllBusinessStartupInternalProjects();
        this.SET_INTERNALPROJECTS_TABLE( internalProjects.InternalProjects );
    }
    @Mutation
    public SET_INTERNALPROJECTS_TABLE ( offers: Array<BusinessStartupInternalProjectViewTable> )
    {
        this.InternalProjects = offers;
    }

    // INTERNALPROJECTS TABLE CLOSED
    @Action( { rawError: true } )
    public async ListBusinessStartupInternalProjectsClosed (): Promise<void>
    {
        const internalProjectsClosed: ListBusinessStartupInternalProjectTable = await this.orderService.GetAllBusinessStartupInternalProjectsClosed();
        this.SET_INTERNALPROJECTS_TABLE_CLOSED( internalProjectsClosed.InternalProjects );
    }
    @Mutation
    public SET_INTERNALPROJECTS_TABLE_CLOSED ( businessStartups: Array<BusinessStartupInternalProjectViewTable> )
    {
        this.InternalProjectsClosed = businessStartups;
    }

    // INTERNALPROJECT DETAILS
    @Action( { rawError: true } )
    public async GetInternalProjectsById (id: Guid): Promise<void>
    {
        const internalProject: BusinessStartupInternalProjectDetailsView = await this.orderService.GetBusinessStartupInternalProjectById(id);
        this.SET_INTERNALPROJECTS_DETAILS( internalProject );
    }
    @Mutation
    public SET_INTERNALPROJECTS_DETAILS ( internalProject: BusinessStartupInternalProjectDetailsView )
    {
        this.InternalProjectDetails = internalProject;
    }

}

export const InternalProjectStore = getModule( InternalProjectsStore );