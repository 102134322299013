




























































import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { InternalProjectStore } from "../../../InternalProjectStore";
import { UserSimple } from "@/services/iIdentityService";

@Component({})
export default class PgaOfferEvaluationDetailsInternalProjectCreate extends mixins(SharedMixin)
{
	get description() : string
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.Description;
	}
	set description(value: string)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_DESCRIPTION(value);
	}

	get daysMenExpected() : number
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.DaysMenExpected;
	}
	set daysMenExpected(value: number)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_DAYSMEN_EXPECTED(value);
	}

	get expectedCost() : number
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.ExpectedCost;
	}
	set expectedCost(value: number)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_EXPECTEDCOST(value);
	}

	get market() : boolean
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.Market;
	}
	set market(value: boolean)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_INTERNALUSE(!value);
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_MARKET(value);
	}

	get internalUse() : boolean
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.InternalUse;
	}
	set internalUse(value: boolean)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_MARKET(!value);
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_INTERNALUSE(value);
	}

	get acom() : Guid | null
	{
		return InternalProjectStore.EditDetailsInternalProject.Command.ACOMId;
	}
	set acom(value: Guid | null)
	{
		InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_ACOMID(value);
	}

	//Dropdown Lists
	get ACOMDropdown() : Array<UserSimple>
	{
		return InternalProjectStore.ACOMDropdown;
	}

	get Errors() : Map<string, Array<string>>
	{
		return InternalProjectStore.EditDetailsInternalProject.Errors;
	}
}
