







































import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { EditOtherComponentsCommand } from "@/services/iOrderService";
import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";

@Component({})
export default class PgaOfferEvaluationOtherComponentsSubtotals extends mixins( SharedMixin )
{
	get otherComponents() : EditOtherComponentsCommand
	{
		return SectionStore.EditOtherComponents.Command;
	}

	get offerAmount() : number
	{			
		return (this.otherComponents.Others.length > 0 ?
				this.otherComponents.Others
					.map(o => o.OfferAmount)
					.reduce((sum, current) => sum + current) : 0)
			+ this.otherComponents.ProductsPurchase.OfferAmount
			+ this.otherComponents.SolutionsPurchase.OfferAmount
			+ this.otherComponents.ServicesPurchase.OfferAmount
			+ this.otherComponents.TransferCosts.OfferAmount
			+ this.otherComponents.SecurityCosts.OfferAmount;
	}

	get expectedCost() : number
	{
		return (this.otherComponents.Others.length > 0 ?
				this.otherComponents.Others
					.map(o => o.ExpectedCost)
					.reduce((sum, current) => sum + current) : 0)
			+ this.otherComponents.ProductsPurchase.ExpectedCost
			+ this.otherComponents.SolutionsPurchase.ExpectedCost
			+ this.otherComponents.ServicesPurchase.ExpectedCost
			+ this.otherComponents.TransferCosts.ExpectedCost
			+ this.otherComponents.SecurityCosts.ExpectedCost;
	}

	get expectedMargin() : number
	{
		return (this.otherComponents.Others.length > 0 ?
				this.otherComponents.Others
					.map(o => o.OfferAmount - o.ExpectedCost)
					.reduce((sum, current) => sum + current) : 0)
			+ (this.otherComponents.ProductsPurchase.OfferAmount - this.otherComponents.ProductsPurchase.ExpectedCost)
			+ (this.otherComponents.SolutionsPurchase.OfferAmount - this.otherComponents.SolutionsPurchase.ExpectedCost)
			+ (this.otherComponents.ServicesPurchase.OfferAmount - this.otherComponents.ServicesPurchase.ExpectedCost)
			+ (this.otherComponents.TransferCosts.OfferAmount - this.otherComponents.TransferCosts.ExpectedCost)
			+ (this.otherComponents.SecurityCosts.OfferAmount - this.otherComponents.SecurityCosts.ExpectedCost);
	}

	get expectedMarginPerc() : string
	{
		if(this.offerAmount > 0)
		{
			return ((this.expectedMargin / this.offerAmount) * 100).toFixed(2);
		}
		return "0";
	}
}
