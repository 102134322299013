













































































































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { PgaValutazioneOffertaAltreComponentiCreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({
  }),
  computed: {
    BusinessLineItemsComponente: {
      get() {
        return OfferServiceModule.getBUSINESSLINE;
      }
    },
    BusinessLineComponente: {
      get() {
        return this.$props.Model.BusinessLineComponente;
      },
      set(value: string) {
        CreateOfferModule.SET_BUSINESSLINECOMPONENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TechAreaItemsComponente: {
      get() {
        return OfferServiceModule.getTECHAREA(
          CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaAltreComponentiCreate[
            this.$props.index
          ].BusinessLineComponente
        );
      }
    },
    TechAreaComponente: {
      get() {
        return this.$props.Model.TechAreaComponente;
      },
      set(value: string) {
        CreateOfferModule.SET_TECHAREACOMPONENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TipologiaFornituraItemsComponente: {
      get() {
        return OfferServiceModule.getTIPOLOGIAFORNITURA;
      }
    },
    TipologiaFornituraComponente: {
      get() {
        return this.$props.Model.TipologiaFornituraComponente;
      },
      set(value: string) {
        CreateOfferModule.SET_TIPOLOGIAFORNITURACOMPONENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TipologiaComponenteitems: {
      get() {
        return OfferServiceModule.getTIPOLOGIECOMPONENTE;
      }
    },
    TipologiaComponente: {
      get() {
        return this.$props.Model.TipologiaComponente;
      },
      set(value: string) {
        CreateOfferModule.SET_TIPOLOGIA_COMPONENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
    DescrizioneComponente: {
      get() {
        return this.$props.Model.DescrizioneComponente;
      },
      set(value: string) {
        CreateOfferModule.SET_DESCRIZIONE_COMPONENTE({
          index: this.$props.index,
          value: value
        });
      }
    },
    ImportoOffertaComponente: {
      get() {
        return this.$props.Model.ImportoOffertaComponente;
      },
      set(value: number) {
        CreateOfferModule.UpdateImportoOffertaComponente({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    CostoAttesoComponente: {
      get() {
        return this.$props.Model.CostoAttesoComponente;
      },
      set(value: number) {
        CreateOfferModule.UpdateCostoAttesoComponente({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    MargineAttesoComponente: {
      get() {
        return this.$props.Model.MargineAttesoComponente;
      }
    },
    MargineAttesoPercComponente: {
      get() {
        return this.$props.Model.MargineAttesoPercComponente;
      }
    }
  }
})
export default class PgaValutazioneOffertaAltreComponentiCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaAltreComponentiCreateModel;

  @Emit()
  removePgaValutazioneOffertaAltreComponentiCreate() {
    CreateOfferModule.REMOVE_ALTRE_COMPONENTI(this.index);
  }
}
