













































































































































































































































	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { CustomerOrderStore } from './CustomerOrderStore';
	import PgaOfferEvaluationCustomerOrderDetails from './components/details/PgaOfferEvaluationCustomerOrderDetails.vue';
	import { OfferEvaluationCustomerOrderDetailsView, StatusOfferEvaluation } from "@/services/iOrderService";
	import { SharedModule } from "@/feature/Shared/store";
	
	import { SendEmailToUsersCommand } from "@/services/iNotificationService";
	import { EmailsStore } from "@/feature/Shared/Email/store";

	@Component( {
		components:
		{
			PgaOfferEvaluationCustomerOrderDetails,
		}
	} )
    export default class OfferEvaluationPageCustomerOrderDetails extends mixins( SharedMixin )
    {
		private isLoaded = false;
		private showDialog = false;
		private title = "";
		private message = "";

		get editRestricted() : boolean
        {
            return !SharedModule.Permissions.includes('EditOfferEvaluationCustomerOrder')
                && SharedModule.Permissions.includes('EditOfferEvaluationCustomerOrderRestricted');
        }

		get isAssessment() : boolean
		{
			return this.customerOrderDetails.Versions.some(v => v);
		}

		get customerOrderDetails() : OfferEvaluationCustomerOrderDetailsView
		{
			return CustomerOrderStore.CustomerOrderDetails;
		}

		get isEditable() : boolean
		{
			return this.customerOrderDetails.Status != StatusOfferEvaluation.Approved
				&& this.customerOrderDetails.Status != StatusOfferEvaluation.Closed
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId;
		}

		get isDeletable() : boolean
		{
			return this.customerOrderDetails.Status != StatusOfferEvaluation.Closed
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId;
		}

		get isActivable() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.Saved
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId
				&& !this.isAssessment;
		}

		get isApprovalRequestableByACOMorDC() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.ApprovalRequestable
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId
				&& !this.isAssessment;
		}

		get isApprovalRequestableOrApprovableBySG() : boolean
		{
			const hasDCApproved = this.customerOrderDetails.ApprovalOfferDC 
				|| this.customerOrderDetails.ApprovalOfferLimitDC
				|| this.customerOrderDetails.ApprovalMOLDC
				|| this.customerOrderDetails.ApprovalMinimumMOLDC;
			const needDGApprove = this.customerOrderDetails.ExceedOfferLimit || this.customerOrderDetails.NotExceedMOL;
			const hasDGApproved = !needDGApprove ||
				(this.customerOrderDetails.ApprovalOfferLimitDG
					|| this.customerOrderDetails.ApprovalMOLDG 
					|| this.customerOrderDetails.ApprovalMinimumMOLDG);
			return CustomerOrderStore.CustomerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& hasDCApproved && hasDGApproved && !this.isAssessment;
		}

		get isApprovableByDC() : boolean
		{
			return !this.customerOrderDetails.ApprovalOfferDC
				&& this.customerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& !this.isAssessment;
		}

		get isApprovableByDG() : boolean
		{
			return (this.customerOrderDetails.ApprovalOfferLimitDC 
						|| this.customerOrderDetails.ApprovalMOLDC 
						|| this.customerOrderDetails.ApprovalMinimumMOLDC)
				&& !(this.customerOrderDetails.ApprovalOfferLimitDG 
						|| this.customerOrderDetails.ApprovalMOLDG 
						|| this.customerOrderDetails.ApprovalMinimumMOLDG)
				&& this.customerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& !this.isAssessment;
		}

		get isApprovableByDHRG() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.RequestApprovalDHRG
					&& !this.isAssessment;
		}

		// Assessment
		get canCreateAssessment() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.Approved
					&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId;
		}

		get isNotificableAssessment() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.Saved
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId
				&& this.isAssessment;
		}
		
		get isApprovalRequestableAssessment() : boolean
		{
			return this.customerOrderDetails.Status == StatusOfferEvaluation.ApprovalRequestable
				&& this.customerOrderDetails.ACOM.Id == SharedModule.UserId
				&& this.isAssessment;
		}

		get isApprovableAssessmentByDC() : boolean
		{
			return !this.customerOrderDetails.ApprovalOfferDC
				&& this.customerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& (this.customerOrderDetails.NotExceedMOL 
						|| this.customerOrderDetails.TotalsOfferEvaluation.ExpectedMargins < 0)
				&& this.isAssessment;
		}

		get isApprovableAssessmentByDG() : boolean
		{
			return this.customerOrderDetails.ApprovalOfferDC
				&& !(this.customerOrderDetails.ApprovalMOLDG 
						|| this.customerOrderDetails.ApprovalMinimumMOLDG)
				&& this.customerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& this.isAssessment;
		}

		get isApprovableAssessmentBySG() : boolean
		{
			const needDCApprove = this.customerOrderDetails.NotExceedMOL
				|| this.customerOrderDetails.TotalsOfferEvaluation.ExpectedMargins < 0;
			const hasDCApproved = !needDCApprove || (this.customerOrderDetails.ApprovalOfferDC 
				|| this.customerOrderDetails.ApprovalMOLDC
				|| this.customerOrderDetails.ApprovalMinimumMOLDC);

			const needDGApprove = this.customerOrderDetails.NotExceedMOL;
			const hasDGApproved = !needDGApprove ||
				(this.customerOrderDetails.ApprovalOfferLimitDG
					|| this.customerOrderDetails.ApprovalMOLDG 
					|| this.customerOrderDetails.ApprovalMinimumMOLDG);

			return CustomerOrderStore.CustomerOrderDetails.Status == StatusOfferEvaluation.Activating
				&& hasDCApproved && hasDGApproved && this.isAssessment;
		}

        private async Edit() : Promise<void>
        {
			const id = this.$route.params.id;
            this.$router.push( { name: "offerevaluationcustomeredit", params: { id } } );
        }

		private async Active() : Promise<void>
        {
			await CustomerOrderStore.ActiveCustomerOrderCommand();
			if(this.customerOrderDetails.Status === StatusOfferEvaluation.Activating)
				this.$router.go(-1);
        }

		private async RequestApproval() : Promise<void>
        {
			await CustomerOrderStore.RequestApprovalCustomerOrderCommand();
			this.$router.go(-1);
        }

		private async Approve() : Promise<void>
        {
			await CustomerOrderStore.ApproveCustomerOrderCommand();
			this.$router.go(-1);
        }

		// Assessment
		private async CreateAssessment() : Promise<void>
		{
			await CustomerOrderStore.CreateAssessmentCustomerOrder();
		}

		private async NotifyAssessment() : Promise<void>
        {
			await CustomerOrderStore.NotifyAssessmentCustomerOrderCommand();
			if(this.customerOrderDetails.Status === StatusOfferEvaluation.Activating)
				this.$router.go(-1);
        }

		private async RequestApprovalAssessment() : Promise<void>
        {
			await CustomerOrderStore.RequestApprovalAssessmentCustomerOrderCommand();
			this.$router.go(-1);
        }

		private async ApproveAssessment() : Promise<void>
        {
			await CustomerOrderStore.ApproveAssessmentCustomerOrderCommand();
			this.$router.go(-1);
        }


		private RequestChanges()
		{
			const sendEmailCommand : SendEmailToUsersCommand = 
			{
				UserIds: [this.customerOrderDetails.ACOM.Id.toString()],
				Subject: `Request changes for Proposal Evaluation Cutomer Order of ${this.customerOrderDetails.CustomerName}`,
				Body: "",
			};
			EmailsStore.SET_SEND_EMAIL_TO_USERS_COMMAND(sendEmailCommand);
			EmailsStore.SET_SHOW_FORM_EMAIL_TO_USERS(true);
		}

		private formatDate(date: Date) : string
		{
			return date.toLocaleDateString();
		}

		async Delete(): Promise<void> 
		{
			this.showDialog = false;
			await CustomerOrderStore.DeleteCustomerOrder();
			this.$router.go(-1);
		}

		async mounted() : Promise<void>
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE("");
			const id: Guid = Guid.parse(this.$route.params.id);
			this.isLoaded = false;
			await CustomerOrderStore.GetOfferEvaluationCustomerOrder(id);
			this.isLoaded = true;
		}
    }
