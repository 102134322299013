



















import Vue from "vue";
import { Component,  } from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Descrizione",
          align: "start",
          sortable: false,
          value: "DescrizioneAltriPresale"
        },
        { text: "Costo Atteso", value: "CostoAttesoAltriPresale" },
      ]
    };
  },
  computed: {
    AltriPresaleItems: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaAltriPresaleCreate;
      }
    }
  }
})
export default class PgaValutazioneOffertaAltriPresaleDetails extends Vue {}
