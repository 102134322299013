























































    import { Component } from "vue-property-decorator";
    import { Guid } from 'guid-typescript';
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { InternalProjectStore } from "../../InternalProjectStore";
    import { SectionStore } from "../../SectionStore";

    import { SharedModule, deepComparison, IsMobile } from "@/feature/Shared/store";
    
    // Header
    import PgaOfferEvaluationHeaderInternalProjectEdit from "./sub-components/PgaOfferEvaluationHeaderInternalProjectEdit.vue";
    // Details Internal Project
    import PgaOfferEvaluationDetailsInternalProjectCreate from "../create/sub-components/PgaOfferEvaluationDetailsInternalProjectCreate.vue";
    // Attachments
    import PgaOfferEvaluationAttachmentsCreate from "../create/sub-components/PgaOfferEvaluationAttachmentsCreate.vue";

	@Component( {
        components:
        {
            PgaOfferEvaluationHeaderInternalProjectEdit,

            PgaOfferEvaluationDetailsInternalProjectCreate,

            PgaOfferEvaluationAttachmentsCreate,
        }
    })
    export default class PgaOfferEvaluationInternalProjectCreate extends mixins(SharedMixin)
    {
        private readonly internalProjectResource = "internalproject";
        private isMiniVariant = this.isMobile;

        get isMobile() : boolean
        {
            return IsMobile(this.$vuetify.breakpoint.name);
        }

        get panelsOpened() : Array<number>
        {
            return [0, 1, 2];
        }

        // Errors check
        get hasErrorDetailsInternalProject() : boolean
        {
            return InternalProjectStore.EditDetailsInternalProject.Errors.size > 0;
        }
        get hasErrorsAttachments() : boolean
        {
            return SectionStore.EditAttachments.Errors.size > 0;
        }

        private async SaveAsDraft() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await InternalProjectStore.EditOfferEvaluationInternalProject();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(InternalProjectStore.EditDetailsInternalProject.Command, InternalProjectStore.EditDetailsInternalProject.CommandCopy))
                    await InternalProjectStore.EditDetailsInternalProjectAPIDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy)
                    || SectionStore.UploadFiles.Command.Files.length > 0)
                    await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);

            if(allSaved)
            {
                this.$router.go(-1);
            }
        }

        private async Save() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await InternalProjectStore.EditOfferEvaluationInternalProject();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await InternalProjectStore.EditDetailsInternalProjectAPI();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);

            if(allSaved)
            {
                await InternalProjectStore.SaveOfferEvaluationInternalProject();
                this.$router.go(-1);
            }
        }

        private undo() : void
        {
            this.$router.go(-1);
        }

        async mounted() : Promise<void>
        {
            const id = Guid.parse(this.$route.params.id);
            await InternalProjectStore.InitEditInternalProjectCommand(id);
            SectionStore.SET_OFFER_EVALUATION_TYPE(this.internalProjectResource);
        }

        beforeDestroy() : void
		{
            SectionStore.ResetEditAttachmentsSectionCommand();
            InternalProjectStore.ResetEditDetailsInternalProjectCommand();
            InternalProjectStore.ResetSaveInternalProjectCommand();
            InternalProjectStore.ResetEditInternalProjectCommand();
		}
    }
