




































































































import { Component, Prop } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel } from '@/services/iOrderService';
import { SectionStore } from "../../../../SectionStore";

@Component({})
export default class PgaOfferEvaluationAdditionalCostsPostsaleOthersCreate extends mixins(SharedMixin)
{
	@Prop({ type: Number, default: 0 }) readonly index!: number;
	@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

	get errorSubSection() : string
	{
		let color = "";
		this.Errors.forEach((value, key) =>
		{
			if(key.includes(`Others[${this.index}]`))
				color = "error";
		});
		return color;
	}

	get businessLine() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Others[this.index].BusinessLineId;
	}
	set businessLine(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_TECHAREA_ID({ index: this.index, techAreaId: null });
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_BUSINESSLINE_ID({ index: this.index, businessLineId: value });
	}

	get techArea() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Others[this.index].TechAreaId;
	}
	set techArea(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_TECHAREA_ID({ index: this.index, techAreaId: value });
	}

	get supplyTipology() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Others[this.index].SupplyTipologyId;
	}
	set supplyTipology(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_SUPPLYTIPOLOGY_ID({ index: this.index, supplyTipologyId: value });
	}

	get description() : string
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Others[this.index].Description;
	}
	set description(value: string)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_DESCRIPTION({ index: this.index, description: value });
	}

	get expectedCost() : number
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Others[this.index].ExpectedCost;
	}
	set expectedCost(value: number)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_EXPECTEDCOST({ index: this.index, expectedCost: value});
	}

	get tipology() : string
	{
		return "OTHERS";
	}

	//Dropdown Lists
	get businessLineDropdown() : Array<BusinessLineTableView>
	{
		return SectionStore.BusinessLinesDropdown;
	}
	get techAreaDropdown() : Array<TechAreaModel>
	{		
		return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
	}
	get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
	{
		return SectionStore.SupplyTipologiesDropdown;
	}

	get Errors() : Map<string, Array<string>>
	{
		return SectionStore.EditAdditionalCostsPostsale.Errors;
	}

	private removeElement() : void
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_REMOVE_ELEMENT(this.index);
	}
}
