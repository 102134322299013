




























































































































































	import { Component, Prop } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';
	import { clearTimeout, setTimeout } from 'timers';
	
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel } from '@/services/iOrderService';
	import { UserSimple } from "@/services/iIdentityService";
	import { SectionStore } from "../../../SectionStore";

	@Component({})
	export default class PgaOfferEvaluationAdditionalCostsPresaleCreate extends mixins(SharedMixin)
	{
		@Prop({ type: Number, default: 0 }) readonly index!: number;
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

		private dropdownLoading = false;
		private showDynamicDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

		private IsExternalResource = false;
		get isExternalResource() : boolean
		{
			return this.IsExternalResource;
		}
		set isExternalResource(value: boolean)
		{
			this.IsExternalResource = value;
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME_ID({ index: this.index, resourceNameId: null});
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME({ index: this.index, resourceName: ""});
		}

		get errorSubSection() : string
		{
			let color = "";
			this.Errors.forEach((value, key) =>
			{
				if(key.includes(`AdditionalCosts[${this.index}]`))
					color = "error";
			});
			return color;
		}

		//Details Additional Costs Presale: first row
		get businessLine() : Guid | null
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].BusinessLineId;
		}
		set businessLine(value: Guid | null)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_TECHAREA_ID({ index: this.index, techAreaId: null });
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_BUSINESSLINE_ID({ index: this.index, businessLineId: value });
		}

		get techArea() : Guid | null
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].TechAreaId;
		}
		set techArea(value: Guid | null)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_TECHAREA_ID({ index: this.index, techAreaId: value });
		}

		get supplyTipology() : Guid | null
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].SupplyTipologyId;
		}
		set supplyTipology(value: Guid | null)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_SUPPLYTIPOLOGY_ID({ index: this.index, supplyTipologyId: value });
		}

		//Details Human Resource: second row
		get professionalFigure() : string
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].Professional;
		}
		set professionalFigure(value: string)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_PROFESSIONAL({ index: this.index, professional: value });
		}

		get company() : string
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].Company;
		}
		set company(value: string)
		{
			this.showDynamicDropdown = false;
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_COMPANY({ index: this.index, company: value });
		}
		private setCompanyManually(value: string)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_COMPANY({ index: this.index, company: value });

			// cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.suppliersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestSuppliers(value);
                }, 500);
				this.showDynamicDropdown = true;
            }
			else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

		get user() : UserSimple | null
		{
			return this.usersDropdown
				.find(u => SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].ResourceNameId == u.Id) ?? null;
		}
		set user(value: UserSimple | null)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME_ID({ index: this.index, resourceNameId: value?.Id ?? null});
		}

		get externalUser() : string
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].ResourceName;
		}
		set externalUser(value: string)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME({ index: this.index, resourceName: value});
		}

		//Details Additiona Costs Presale: third row
		get daysMen() : number
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].DaysMen;
		}
		set daysMen(value: number)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_DAYSMEN({ index: this.index, daysMen: value});
		}

		get dailyCost() : number
		{
			return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[this.index].DailyCost;
		}
		set dailyCost(value: number)
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_DAILYCOST({ index: this.index, dailyCost: value});
		}

		get expectedCost() : number
		{
			return this.daysMen * this.dailyCost;
		}

		//Dropdown Lists
		get businessLineDropdown() : Array<BusinessLineTableView>
		{
			return SectionStore.BusinessLinesDropdown;
		}
		get techAreaDropdown() : Array<TechAreaModel>
		{		
			return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		}
		get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		{
			return SectionStore.SupplyTipologiesDropdown;
		}
		get usersDropdown() : Array<UserSimple>
		{
			return SectionStore.UsersDropdown;
		}
		get suppliersDropdown() : Array<string>
		{
			return SectionStore.SuppliersDropdown.map(s => s.BusinessName);
		}
		get professionalsDropdown() : Array<string>
		{
			return SectionStore.ProfessionalsDropdown;
		}

		private async requestSuppliers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await SectionStore.GetSuppliersDropdown(search);
			this.dropdownLoading = false;
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditAdditionalCostsPresale.Errors;
		}

		private removeElement() : void
		{
			SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_REMOVE_ELEMENT(this.index);
		}
	}
