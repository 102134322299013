





























import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import PgaOfferEvaluationTotalCostsPresaleCreate from './PgaOfferEvaluationTotalCostsPresaleCreate.vue';
import PgaOfferEvaluationTotalCostsPostsaleCreate from './PgaOfferEvaluationTotalCostsPostsaleCreate.vue';

@Component( {
	components:
	{
		PgaOfferEvaluationTotalCostsPresaleCreate,
		PgaOfferEvaluationTotalCostsPostsaleCreate,
	}
} )
export default class PgaOfferEvaluationTotalCostsCreate extends mixins( SharedMixin )
{ 
	private presale = 0;
	private postsale = 0;
}
