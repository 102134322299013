















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  computed: {
    ImportoOffertaTotale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .ImportoOffertaTotale;
      }
    },
    CostoAttesoTotale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .CostoAttesoTotale;
      }
    },
    MargineAttesoTotale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .MargineAttesoTotale;
      }
    },
    MargineAttesoPercTotale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .MargineAttesoPercTotale;
      }
    },
    Sconto: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate.Sconto;
      },
      set(value) {
        CreateOfferModule.UpdateSconto(value);
      }
    },
    ImportoOffertaTotaleScontato: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .ImportoOffertaTotaleScontato;
      }
    },
    CostoAttesoTotaleScontato: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .CostoAttesoTotaleScontato;
      }
    },
    MargineAttesoTotaleScontato: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .MargineAttesoTotaleScontato;
      }
    },
    MargineAttesoPercTotaleScontato: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate
          .MargineAttesoPercTotaleScontato;
      }
    }
  }
})
export default class TotaliOfferta extends Vue {}
