























import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";
// import PgaHubSpotDealsTable from "./sub-components/PgaHubSpotDealsTable.vue";
import { HubSpotDealDetailViewModel } from "@/services/iOrderService";
import { SharedModule } from "@/feature/Shared/store";

@Component( {} )
export default class PgaHubSpotDealSelector extends mixins( SharedMixin )
{
	private _selectedDeal: Array<HubSpotDealDetailViewModel> = new Array<HubSpotDealDetailViewModel>();
	private isLoading = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private headersForOwnedDeals: Array<any> =
	[
		{
			text: "Deal Id",
			align: " start",
			sortable: true,
			value: "Id",
			keySearch: "id",
		},
		{
			text: "Deal Name",
			align: "start",
			sortable: true,
			value: "Properties.DealName",
			keySearch: "dn",
		},
		{
			text: "Create Date",
			align: "start",
			sortable: true,
			value: "Properties.CreateDate",
		},
		{
			text: "Last Modified Date",
			align: "start",
			sortable: true,
			value: "Properties.HsLastModifiedDate",
		},
		{
			text: "Deal Stage",
			align: "start",
			sortable: true,
			value: "Properties.DealStage",
			keySearch: "ds",
		},
		{
			text: "Amount",
			align: "start",
			sortable: true,
			value: "Amount",
			keySearch: "am",
		},
	];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private headersForAllDeals: Array<any> =
	[
		{
			text: "Deal Id",
			align: " start",
			sortable: true,
			value: "Id",
			keySearch: "id",
		},
		{
			text: "Deal Name",
			align: "start",
			sortable: true,
			value: "Properties.DealName",
			keySearch: "dn",
		},
		{
			text: "Deal Stage",
			align: "start",
			sortable: true,
			value: "Properties.DealStage",
			keySearch: "ds",
		},
		{
			text: "Owner",
			align: "start",
			sortable: true,
			value: "Properties.HubSpotOwnerFullName",
			keySearch: "own",
		},
		{
			text: "Create Date",
			align: "start",
			sortable: true,
			value: "Properties.CreateDate",
		},
		{
			text: "Last Modified Date",
			align: "start",
			sortable: true,
			value: "Properties.HsLastModifiedDate",
		},
		{
			text: "Amount",
			align: "start",
			sortable: true,
			value: "Amount",
			keySearch: "am",
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private get headers() : Array<any>
	{
		if(SharedModule.Permissions.includes('ReadAllHubSpotDeals'))
			return this.headersForAllDeals;
		else
			return this.headersForOwnedDeals;
	}


	get listHubSpotDeals() : Array<HubSpotDealDetailViewModel>
	{
		return SectionStore.HubSpotDeals.Results;
	}

	get selectedDeal() : Array<HubSpotDealDetailViewModel>
	{
		return this._selectedDeal;
	}
	set selectedDeal(value: Array<HubSpotDealDetailViewModel>)
	{
		this._selectedDeal = value;
		this.$emit("selected-deal", this._selectedDeal[0]?.Id ?? '');
	}

	private getFormattedDate(value: Date | string) : string
	{
		const date = new Date(value);
		return date.toLocaleDateString();
	}

	private undo () : void
	{
		this.$router.go(-1);
	}

	async created (): Promise<void>
	{
		try
		{
			this.isLoading = true;
			await SectionStore.ListHubSpotDeals();
			this.isLoading = false;
		}
		catch(error)
		{
			this.isLoading = false;
			this.$router.go(-1);
		}
	}
}
