import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/infrastructure/store';
import '@/App.container';
import pgaDiContainer from '@/App.container';

import IIdentityService from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';

import IOrderService,
    {   BusinessLineModel, TechAreaModel, TipologiaFornituraModel, User, SolutionProductModel, HubSpotDealDetailViewModel,
            CustomerView, SupplierView,
        EditFixedPriceProjectCommand,
        EditHumanResourcesCommand, ResourceDetailsCommand,
        EditOtherComponentsCommand, ComponentDetailsCommand,
        EditTotalAmountsCommand,
        EditAdditionalCostsPresaleCommand, AdditionalCostPresaleDetailsCommand,
        EditOtherCostsPresaleCommand, OtherCostPresaleDetailsCommand,
        EditAdditionalCostsPostsaleCommand, AdditionalCostPostsaleDetailsCommand,
        EditTotalExpectedRevenueByFeeCommand,
        EditAttachmentsCommand, UploadFilesOfferEvaluationCommand,
        OfferEvaluationSectionResponse, ListHubSpotDealsResponse, OrderTipology } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';

import { SharedModule, parseErrors, deepCopy } from '@/feature/Shared/store';
import { CustomerOrderStore } from './CustomerOrderStore';

export interface SectionsState
{
    OfferEvaluationType: string;
    EditFixedPriceProject: EditFixedPriceProjectModel;
    EditHumanResources: EditHumanResourcesModel;
    EditOtherComponents: EditOtherComponentsModel;
    EditTotalAmounts: EditTotalAmountsModel;
    EditAdditionalCostsPresale: EditAdditionalCostsPresaleModel;
    EditOtherCostsPresale: EditOtherCostsPresaleModel;
    EditAdditionalCostsPostsale: EditAdditionalCostsPostsaleModel;
    EditTotalExpectedRevenueByFee: EditTotalExpectedRevenueByFeeModel;
    EditAttachments: EditAttachmentsModel;
    UploadFiles: UploadFilesModel;

    CRMDeal: HubSpotDealDetailViewModel;

    BusinessLinesDropdown: Array<BusinessLineModel>;
    TechAreasDropdown: Array<TechAreaModel>;
    SupplyTipologiesDropdown: Array<TipologiaFornituraModel>;
    SolutionProductsDropdown: Array<SolutionProductModel>;
    UsersDropdown: Array<User>;
    HubSpotDeals: ListHubSpotDealsResponse;
    ProfessionalsDropdown: Array<string>; 
    CustomersDropdown: Array<CustomerView>;
    SuppliersDropdown: Array<SupplierView>;

    BusinessLineIdFromCRM: Guid | null;
    TechAreaIdFromCRM: Guid | null;
}

// Fixed Price Project
export interface EditFixedPriceProjectModel
{
    Command: EditFixedPriceProjectCommand;
    CommandCopy: EditFixedPriceProjectCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Human Resources
export interface EditHumanResourcesModel
{
    Command: EditHumanResourcesCommand;
    CommandCopy: EditHumanResourcesCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Other Components
export interface EditOtherComponentsModel
{
    Command: EditOtherComponentsCommand;
    CommandCopy: EditOtherComponentsCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Total Amounts
export interface EditTotalAmountsModel
{
    Command: EditTotalAmountsCommand;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Additional Costs Presale
export interface EditAdditionalCostsPresaleModel
{
    Command: EditAdditionalCostsPresaleCommand;
    CommandCopy: EditAdditionalCostsPresaleCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Other Costs Presale
export interface EditOtherCostsPresaleModel
{
    Command: EditOtherCostsPresaleCommand;
    CommandCopy: EditOtherCostsPresaleCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Additional Costs Postsale
export interface EditAdditionalCostsPostsaleModel
{
    Command: EditAdditionalCostsPostsaleCommand;
    CommandCopy: EditAdditionalCostsPostsaleCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Total Expected Revenue by Fee
export interface EditTotalExpectedRevenueByFeeModel
{
    Command: EditTotalExpectedRevenueByFeeCommand;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Attachments
export interface EditAttachmentsModel
{
    Command: EditAttachmentsCommand;
    CommandCopy: EditAttachmentsCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}
// Files
export interface UploadFilesModel
{
    Command: UploadFilesOfferEvaluationCommand;
    Response: OfferEvaluationSectionResponse;
}

@Module( { namespaced: true, dynamic: true, store, name: 'SectionModule', } )
class SectionsStore extends VuexModule implements SectionsState
{
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>( OrderService );
    private identityService: IIdentityService = pgaDiContainer.get<IIdentityService>( IdentityService );
    
    public OfferEvaluationType = "";

    // Dropdowns' list
    public BusinessLinesDropdown: Array<BusinessLineModel> = new Array<BusinessLineModel>();
    public TechAreasDropdown: Array<TechAreaModel> = new Array<TechAreaModel>();
    public SupplyTipologiesDropdown: Array<TipologiaFornituraModel> = new Array<TipologiaFornituraModel>();
    public SolutionProductsDropdown: Array<SolutionProductModel> = new Array<SolutionProductModel>();
    public UsersDropdown: Array<User> = new Array<User>();
    public HubSpotDeals: ListHubSpotDealsResponse =
    {
        Results: new Array<HubSpotDealDetailViewModel>(),
    };
    public ProfessionalsDropdown: Array<string> = new Array<string>();
    public CustomersDropdown: Array<CustomerView> = new Array<CustomerView>();
    public SuppliersDropdown: Array<SupplierView> = new Array<SupplierView>();

    // BusinessLine and TechArea from CRM
    public BusinessLineIdFromCRM: Guid | null = null;
    public TechAreaIdFromCRM: Guid | null = null;
    
    // Dropdown BusinessLines
    @Action({ rawError: true })
    public async GetBusinessLinesDropdown()
    {
        const businessLines = await this.orderService.GetBusinessLinesDropdown();
        this.SET_BUSINESSLINES_DROPDOWN(businessLines);
    }
    @Mutation
    public SET_BUSINESSLINES_DROPDOWN(businessLines: Array<BusinessLineModel>)
    {
        this.BusinessLinesDropdown = businessLines;
    }

    // Dropdown TechAreas
    @Action({ rawError: true })
    public async GetTechAreasDropdown()
    {
        const techAreas = await this.orderService.GetAllTechAreasDropdown();
        this.SET_TECHAREAS_DROPDOWN(techAreas.TechAreas);
    }
    @Mutation
    public SET_TECHAREAS_DROPDOWN(techAreas: Array<TechAreaModel>)
    {
        this.TechAreasDropdown = techAreas;
    }

    // Dropdown SupplyTipologies
    @Action({ rawError: true })
    public async GetSupplyTipologiesDropdown()
    {
        const supplyTipologies = await this.orderService.GetAllSupplyTipologiesDropdown();
        this.SET_SUPPLYTIPOLOGIES_DROPDOWN(supplyTipologies.TipologiaFornitura);
    }
    @Mutation
    public SET_SUPPLYTIPOLOGIES_DROPDOWN(supplyTipologies: Array<TipologiaFornituraModel>)
    {
        this.SupplyTipologiesDropdown = supplyTipologies;
    }

    // Dropdown SolutionProducts
    @Action({ rawError: true })
    public async GetSolutionProductsDropdown()
    {
        const solutionProducts = await this.orderService.GetAllSolutionProductsDropdown();
        this.SET_SOLUTIONPRODUCTS_DROPDOWN(solutionProducts.SolutionProducts);
    }
    @Mutation
    public SET_SOLUTIONPRODUCTS_DROPDOWN(solutionProducts: Array<SolutionProductModel>)
    {
        this.SolutionProductsDropdown = solutionProducts;
    }

    // List HubSpotDeals
    @Action({ rawError: true })
    public async ListHubSpotDeals()
    {
        try
        {
            const hubSpots = await this.orderService.GetAllHubSpotDealsPage();
            hubSpots.Results.forEach(dls =>
                {
                    dls.Properties.Amount = parseFloat(dls.Properties.Amount?.toString() ?? '0');
                    dls.Properties.DealStage = getFormattedStage(dls.Properties.DealStage);
                });
            this.SET_LIST_HUB_SPOT_DEALS(hubSpots);
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            const errorMsg = errorParsed.get('HubSpotError')?.[0] ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE(errorMsg);
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            throw errorParsed;
        }
    }
    @Mutation
    public SET_LIST_HUB_SPOT_DEALS(hubSpots: ListHubSpotDealsResponse)
    {
        this.HubSpotDeals = hubSpots;
    }

    // Dropdown Users
    @Action({ rawError: true })
    public async GetUsersDropdown()
    {
        const users = await this.identityService.GetUsersDropdown();
        this.SET_USERS_DROPDOWN(users.Users);
    }
    @Mutation
    public SET_USERS_DROPDOWN(users: Array<User>)
    {
        this.UsersDropdown = users;
    }

    // Dropdown Professionals
    @Action({ rawError: true })
    public async GetProfessionalsDropdown()
    {
        const professionals = await this.orderService.GetAllProfessionals();
        this.SET_PROFESSIONALS_DROPDOWN(professionals.Professionals);
    }
    @Mutation
    public SET_PROFESSIONALS_DROPDOWN(professionals: Array<string>)
    {
        this.ProfessionalsDropdown = professionals;
    }

    // Dropdown Customers
    @Action({ rawError: true })
    public async GetCustomersDropdown(businessName: string)
    {
        const customers = await this.orderService.GetCustomersByBusinessName(businessName);
        this.SET_COSTUMERS_DROPDOWN(customers.Customers);
    }
    @Mutation
    public SET_COSTUMERS_DROPDOWN(customers: Array<CustomerView>)
    {
        this.CustomersDropdown = customers;
    }

    // Dropdown Suppliers
    @Action({ rawError: true })
    public async GetSuppliersDropdown(businessName: string)
    {
        const suppliers = await this.orderService.GetSuppliersByBusinessName(businessName);
        this.SET_SUPPLIERS_DROPDOWN(suppliers.Suppliers);
    }
    @Mutation
    public SET_SUPPLIERS_DROPDOWN(suppliers: Array<SupplierView>)
    {
        this.SuppliersDropdown = suppliers;
    }

    // CRM Deal
    public CRMDeal : HubSpotDealDetailViewModel =
    {
        Id: "",
        CreatedAt: new Date(),
        ClosedAt: new Date(),
        Properties:
        {
            Amount: 0,
            CreateDate: new Date(),
            CloseDate: new Date(),
            DealName: "",
            DealStage: "",
            HsLastModifiedDate: new Date(),
            HubSpotOwnerId: "",
            HubSpotOwnerFullName: "",
            BusinessLine: "",
            TechArea: "",
            TechnologiaLead: "",
        },
    }

    // FixedPriceProject State
    public EditFixedPriceProject: EditFixedPriceProjectModel =
    {
        Command: 
        {
            Id: Guid.createEmpty(),
            TotalTaxableAmount: 0,
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string,Array<string>>(),
    };

    // Human Resources State
    public EditHumanResources: EditHumanResourcesModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            Resources: new Array<ResourceDetailsCommand>(),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string,Array<string>>(),
    }

    // Other Components State
    public EditOtherComponents: EditOtherComponentsModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            ProductsPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                ProductCatalogId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            SolutionsPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                SolutionCatalogId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            ServicesPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            TransferCosts:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            SecurityCosts:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            Others: new Array<ComponentDetailsCommand>(
                {
                    BusinessLineId: null,
                    TechAreaId: null,
                    SupplyTipologyId: null,
                    Description: "",
                    OfferAmount: 0,
                    ExpectedCost: 0,
                }),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string,Array<string>>(),
    }

    // Total Amounts State
    public EditTotalAmounts: EditTotalAmountsModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            Discount: 0,
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Additional Costs Presale State
    public EditAdditionalCostsPresale: EditAdditionalCostsPresaleModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            AdditionalCosts: new Array<AdditionalCostPresaleDetailsCommand>(),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Other Costs Presale State
    public EditOtherCostsPresale: EditOtherCostsPresaleModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            OtherCosts: new Array<OtherCostPresaleDetailsCommand>(),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Additional Costs Postsale State
    public EditAdditionalCostsPostsale: EditAdditionalCostsPostsaleModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            Maintenance:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Warranty:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Support:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Others: new Array<AdditionalCostPostsaleDetailsCommand>(
                {
                    BusinessLineId: null,
                    TechAreaId: null,
                    SupplyTipologyId: null,
                    Description: "",
                    ExpectedCost: 0,
                }
            ),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // Total Expected Revenue By Fee State
    public EditTotalExpectedRevenueByFee: EditTotalExpectedRevenueByFeeModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            ExpectedFee: 0,
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    public EditAttachments: EditAttachmentsModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            Notes: "",
            BlobsName: new Array<string>(),
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    public UploadFiles: UploadFilesModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
            Files: new Array<File>(),
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
    }

    // Edit FixedPriceProject
    @Action( { rawError: true } )
    public async EditFixedPriceSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditFixedPriceProject(this.OfferEvaluationType, this.EditFixedPriceProject.Command);
            this.SET_EDIT_FIXEDPRICEPROJECT_RESPONSE(response);
            this.SET_EDIT_FIXEDPRICEPROJECT_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_FIXEDPRICEPROJECT_COMMAND_COPY(deepCopy(this.EditFixedPriceProject.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_FIXEDPRICEPROJECT_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditFixedPriceSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditFixedPriceProjectDraft(this.OfferEvaluationType, this.EditFixedPriceProject.Command);
            this.SET_EDIT_FIXEDPRICEPROJECT_RESPONSE(response);
            this.SET_EDIT_FIXEDPRICEPROJECT_COMMAND_COPY(deepCopy(this.EditFixedPriceProject.Command));
            return response;
        }
        catch(error)
        {
            console.log(error);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_FIXEDPRICEPROJECT_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditFixedPriceSectionCommand()
    {
        const emptyCommand: EditFixedPriceProjectCommand =
        {
            Id: Guid.createEmpty(),
            TotalTaxableAmount: 0,
        };
        this.SET_EDIT_FIXEDPRICEPROJECT_COMMAND(emptyCommand);
        this.SET_EDIT_FIXEDPRICEPROJECT_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_FIXEDPRICEPROJECT_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_COMMAND ( command: EditFixedPriceProjectCommand )
    {
        this.EditFixedPriceProject.Command = command;
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_COMMAND_COPY ( command: EditFixedPriceProjectCommand | null)
    {
        this.EditFixedPriceProject.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditFixedPriceProject.Response = response;
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_ID ( offerId: Guid )
    {
        this.EditFixedPriceProject.Command.Id = offerId;
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_TOT_TAX_AMOUNT ( totalTaxableAmount: number )
    {
        this.EditFixedPriceProject.Command.TotalTaxableAmount = totalTaxableAmount;
    }
    @Mutation
    public SET_EDIT_FIXEDPRICEPROJECT_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditFixedPriceProject.Errors = errors;
    }

    // Edit HumanResources
    @Action( { rawError: true } )
    public async EditHumanResourcesSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditHumanResources(this.OfferEvaluationType, this.EditHumanResources.Command);
            this.SET_EDIT_HUMANRESOURCES_RESPONSE(response);
            this.SET_EDIT_HUMANRESOURCES_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_HUMANRESOURCES_COMMAND_COPY(deepCopy(this.EditHumanResources.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_HUMANRESOURCES_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditHumanResourcesSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditHumanResourcesDraft(this.OfferEvaluationType, this.EditHumanResources.Command);
            this.SET_EDIT_HUMANRESOURCES_RESPONSE(response);
            this.SET_EDIT_HUMANRESOURCES_COMMAND_COPY(deepCopy(this.EditHumanResources.Command));
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_HUMANRESOURCES_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditHumanResourcesSectionCommand()
    {
        const emptyCommand: EditHumanResourcesCommand =
        {
            Id: Guid.createEmpty(),
            Resources: new Array<ResourceDetailsCommand>(),
        };
        this.SET_EDIT_HUMANRESOURCES_COMMAND(emptyCommand);
        this.SET_EDIT_HUMANRESOURCES_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_HUMANRESOURCES_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_COMMAND ( command: EditHumanResourcesCommand )
    {
        this.EditHumanResources.Command = command;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_COMMAND_COPY ( command: EditHumanResourcesCommand | null)
    {
        this.EditHumanResources.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditHumanResources.Response = response;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_ADD_NEW_RESOURCE ( )
    {   
        const businessLineIdOfFirst = this.EditHumanResources.Command?.Resources[0]?.BusinessLineId ?? null;
        const techAreaIdOfFirst = this.EditHumanResources.Command?.Resources[0]?.TechAreaId ?? null;
        const supplyTipologyIdOfFirst = this.EditHumanResources.Command?.Resources[0]?.SupplyTipologyId ?? null;

        const resourceDetails: ResourceDetailsCommand =
        {
            BusinessLineId: this.BusinessLineIdFromCRM ?? businessLineIdOfFirst,
            TechAreaId: this.TechAreaIdFromCRM ?? techAreaIdOfFirst,
            SupplyTipologyId: supplyTipologyIdOfFirst,
            Professional: "",
            Company: "",
            ResourceNameId: null,
            ResourceName: "",
            ExpectedMen: 0,
            OfferedMen: 0,
            DailyPriceOffer: 0,
            MiniumDailyPrice: 0,
        }
        this.EditHumanResources.Command.Resources.push(resourceDetails);
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_REMOVE_RESOURCE ( index: number )
    {
        this.EditHumanResources.Command.Resources.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_ID ( offerId: Guid )
    {
        this.EditHumanResources.Command.Id = offerId;
    }
    // Mutations Single Resource
    @Mutation
    public SET_EDIT_HUMANRESOURCES_BUSINESSLINE_ID ( payload: any )
    {
        InitBusinessLineIfNecessary(payload.businessLineId);
        this.EditHumanResources.Command.Resources[payload.index].BusinessLineId = payload.businessLineId;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_TECHAREA_ID ( payload: any )
    {
        InitTechAreaIfNecessary(payload.techAreaId);
        this.EditHumanResources.Command.Resources[payload.index].TechAreaId = payload.techAreaId;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_SUPPLYTIPOLOGY_ID ( payload: any )
    {
        InitSupplyTipologyIfNecessary(payload.supplyTipologyId);
        this.EditHumanResources.Command.Resources[payload.index].SupplyTipologyId = payload.supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_PROFESSIONAL ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].Professional = payload.professional;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_COMPANY ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].Company = payload.company;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_RESOURCENAME_ID ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].ResourceNameId = payload.resourceNameId;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_RESOURCENAME ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].ResourceName = payload.resourceName;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_EXPECTEDMEN ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].ExpectedMen = payload.expectedMen;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_OFFEREDMEN ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].OfferedMen = payload.offeredMen;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_DAILYPROCEOFFER ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].DailyPriceOffer = payload.dailyPriceOffer;
    }
    @Mutation
    public SET_EDIT_HUMANRESOURCES_MINIUMDAILYPRICE ( payload: any )
    {
        this.EditHumanResources.Command.Resources[payload.index].MiniumDailyPrice = payload.miniumDailyPrice;
    }
    // Muataion Error Response
    @Mutation
    public SET_EDIT_HUMANRESOURCES_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditHumanResources.Errors = errors;
    }

    // Edit OtherComponents
    @Action( { rawError: true } )
    public async EditOtherComponentsSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditOtherComponents(this.OfferEvaluationType, this.EditOtherComponents.Command);
            this.SET_EDIT_OTHERCOMPONENTS_RESPONSE(response);
            this.SET_EDIT_OTHERCOMPONENTS_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_OTHERCOMPONENTS_COMMAND_COPY(deepCopy(this.EditOtherComponents.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_OTHERCOMPONENTS_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditOtherComponentsSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditOtherComponentsDraft(this.OfferEvaluationType, this.EditOtherComponents.Command);
            this.SET_EDIT_OTHERCOMPONENTS_RESPONSE(response);
            this.SET_EDIT_OTHERCOMPONENTS_COMMAND_COPY(deepCopy(this.EditOtherComponents.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_OTHERCOMPONENTS_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditOtherComponentsSectionCommand()
    {
        const emptyCommand: EditOtherComponentsCommand =
        {
            Id: Guid.createEmpty(),
            ProductsPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                ProductCatalogId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            SolutionsPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                SolutionCatalogId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            ServicesPurchase:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                SupplierCode: "",
                SupplierBusinessName: "",
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            TransferCosts:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            SecurityCosts:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                OfferAmount: 0,
                ExpectedCost: 0,
            },
            Others: new Array<ComponentDetailsCommand>(
                {
                    BusinessLineId: null,
                    TechAreaId: null,
                    SupplyTipologyId: null,
                    Description: "",
                    OfferAmount: 0,
                    ExpectedCost: 0,
                }
            ),
        };
        this.SET_EDIT_OTHERCOMPONENTS_COMMAND(emptyCommand);
        this.SET_EDIT_OTHERCOMPONENTS_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_OTHERCOMPONENTS_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_COMMAND ( command: EditOtherComponentsCommand )
    {
        this.EditOtherComponents.Command = command;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_COMMAND_COPY ( command: EditOtherComponentsCommand | null )
    {
        this.EditOtherComponents.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditOtherComponents.Response = response;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_ADD_NEW_COMPONENT ( )
    {
        const businessLineIdOfFirst = this.EditOtherComponents.Command.Others[0]?.BusinessLineId ?? null;
        const techAreaIdOfFirst = this.EditOtherComponents.Command.Others[0]?.TechAreaId ?? null;
        const supplyTipologyIdOfFirst = this.EditOtherComponents.Command.Others[0]?.SupplyTipologyId ?? null;

        const componentDetails: ComponentDetailsCommand =
        {
            BusinessLineId: this.BusinessLineIdFromCRM ?? businessLineIdOfFirst,
            TechAreaId: this.TechAreaIdFromCRM ?? techAreaIdOfFirst,
            SupplyTipologyId: supplyTipologyIdOfFirst,
            Description: "",
            OfferAmount: 0,
            ExpectedCost: 0,
        }
        this.EditOtherComponents.Command.Others.push(componentDetails);
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_REMOVE_OTHER_COMPONENT ( index: number )
    {
        this.EditOtherComponents.Command.Others.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_ID ( offerId: Guid )
    {
        this.EditOtherComponents.Command.Id = offerId;
    }
    // Mutations Single Other
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_BUSINESSLINE_ID ( payload: any )
    {
        InitBusinessLineIfNecessary(payload.businessLineId);
        this.EditOtherComponents.Command.Others[payload.index].BusinessLineId = payload.businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_TECHAREA_ID ( payload: any )
    {
        InitTechAreaIfNecessary(payload.techAreaId);
        this.EditOtherComponents.Command.Others[payload.index].TechAreaId = payload.techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_SUPPLYTIPOLOGY_ID ( payload: any )
    {
        InitSupplyTipologyIfNecessary(payload.supplyTipologyId);
        this.EditOtherComponents.Command.Others[payload.index].SupplyTipologyId = payload.supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_DESCRIPTION ( payload: any )
    {
        this.EditOtherComponents.Command.Others[payload.index].Description = payload.description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_OFFERAMOUNT ( payload: any )
    {
        this.EditOtherComponents.Command.Others[payload.index].OfferAmount = payload.offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_OTHER_EXPECTEDCOST ( payload: any )
    {
        this.EditOtherComponents.Command.Others[payload.index].ExpectedCost = payload.expectedCost;
    }
    // Mutations ProductPurchase
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditOtherComponents.Command.ProductsPurchase.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditOtherComponents.Command.ProductsPurchase.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditOtherComponents.Command.ProductsPurchase.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERCODE ( supplierCode: string )
    {
        this.EditOtherComponents.Command.ProductsPurchase.SupplierCode = supplierCode;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERBUSINESSNAME ( supplierBusinessName: string )
    {
        this.EditOtherComponents.Command.ProductsPurchase.SupplierBusinessName = supplierBusinessName;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_PRODUCTCATALOG_ID ( productCatalogId: Guid | null )
    {
        this.EditOtherComponents.Command.ProductsPurchase.ProductCatalogId = productCatalogId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_DESCRIPTION ( description: string )
    {
        this.EditOtherComponents.Command.ProductsPurchase.Description = description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_OFFERAMOUNT ( offerAmount: number )
    {
        this.EditOtherComponents.Command.ProductsPurchase.OfferAmount = offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_PRODUCT_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditOtherComponents.Command.ProductsPurchase.ExpectedCost = expectedCost;
    }
    // Mutations SolutionPurchase
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditOtherComponents.Command.SolutionsPurchase.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditOtherComponents.Command.SolutionsPurchase.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditOtherComponents.Command.SolutionsPurchase.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_SUPPLIERCODE ( supplierCode: string )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.SupplierCode = supplierCode;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_SUPPLIERBUSINESSNAME ( supplierBusinessName: string )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.SupplierBusinessName = supplierBusinessName;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_SOLUTIONCATALOG_ID ( solutionCatalogId: Guid | null )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.SolutionCatalogId = solutionCatalogId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_DESCRIPTION ( description: string )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.Description = description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_OFFERAMOUNT ( offerAmount: number )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.OfferAmount = offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SOLUTION_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditOtherComponents.Command.SolutionsPurchase.ExpectedCost = expectedCost;
    }
    // Mutations ServicePurchase
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditOtherComponents.Command.ServicesPurchase.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditOtherComponents.Command.ServicesPurchase.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditOtherComponents.Command.ServicesPurchase.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_SUPPLIERCODE ( supplierCode: string )
    {
        this.EditOtherComponents.Command.ServicesPurchase.SupplierCode = supplierCode;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_SUPPLIERBUSINESSNAME ( supplierBusinessName: string )
    {
        this.EditOtherComponents.Command.ServicesPurchase.SupplierBusinessName = supplierBusinessName;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_DESCRIPTION ( description: string )
    {
        this.EditOtherComponents.Command.ServicesPurchase.Description = description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_OFFERAMOUNT ( offerAmount: number )
    {
        this.EditOtherComponents.Command.ServicesPurchase.OfferAmount = offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SERVICE_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditOtherComponents.Command.ServicesPurchase.ExpectedCost = expectedCost;
    }
    // Mutations TransferCosts
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditOtherComponents.Command.TransferCosts.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditOtherComponents.Command.TransferCosts.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditOtherComponents.Command.TransferCosts.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_DESCRIPTION ( description: string )
    {
        this.EditOtherComponents.Command.TransferCosts.Description = description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_OFFERAMOUNT ( offerAmount: number )
    {
        this.EditOtherComponents.Command.TransferCosts.OfferAmount = offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_TRANSFER_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditOtherComponents.Command.TransferCosts.ExpectedCost = expectedCost;
    }
    // Mutations SecurityCosts
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditOtherComponents.Command.SecurityCosts.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditOtherComponents.Command.SecurityCosts.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditOtherComponents.Command.SecurityCosts.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_DESCRIPTION ( description: string )
    {
        this.EditOtherComponents.Command.SecurityCosts.Description = description;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_OFFERAMOUNT ( offerAmount: number )
    {
        this.EditOtherComponents.Command.SecurityCosts.OfferAmount = offerAmount;
    }
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_SECURITY_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditOtherComponents.Command.SecurityCosts.ExpectedCost = expectedCost;
    }
    // Mutation Error Response
    @Mutation
    public SET_EDIT_OTHERCOMPONENTS_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditOtherComponents.Errors = errors;
    }

    // Edit TotalAmounts
    @Action( { rawError: true } )
    public async EditTotalAmountsSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditTotalAmounts(this.OfferEvaluationType, this.EditTotalAmounts.Command);
            this.SET_EDIT_TOTALAMOUNTS_RESPONSE(response);
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_TOTALAMOUNTS_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditTotalAmountsSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditTotalAmountsDraft(this.OfferEvaluationType, this.EditTotalAmounts.Command);
            this.SET_EDIT_TOTALAMOUNTS_RESPONSE(response);
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_TOTALAMOUNTS_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditTotalAmountsSectionCommand()
    {
        const emptyCommand: EditTotalAmountsCommand =
        {
            Id: Guid.createEmpty(),
            Discount: 0,
        };
        this.SET_EDIT_TOTALAMOUNTS_COMMAND(emptyCommand);
        this.SET_EDIT_TOTALAMOUNTS_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_EDIT_TOTALAMOUNTS_COMMAND ( command: EditTotalAmountsCommand )
    {
        this.EditTotalAmounts.Command = command;
    }
    @Mutation
    public SET_EDIT_TOTALAMOUNTS_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditTotalAmounts.Response = response;
    }
    @Mutation
    public SET_EDIT_TOTALAMOUNTS_ID ( offerId: Guid )
    {
        this.EditTotalAmounts.Command.Id = offerId;
    }
    @Mutation
    public SET_EDIT_TOTALAMOUNTS_DISCOUNT ( discount: number )
    {
        this.EditTotalAmounts.Command.Discount = discount;
    }
    @Mutation
    public SET_EDIT_TOTALAMOUNTS_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditTotalAmounts.Errors = errors;
    }

    // Edit AdditionalCostsPresale
    @Action( { rawError: true } )
    public async EditAdditionalCostsPresaleSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditAdditionalCostsPresale(this.OfferEvaluationType, this.EditAdditionalCostsPresale.Command);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_RESPONSE(response);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND_COPY(deepCopy(this.EditAdditionalCostsPresale.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditAdditionalCostsPresaleSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditAdditionalCostsPresaleDraft(this.OfferEvaluationType, this.EditAdditionalCostsPresale.Command);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_RESPONSE(response);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND_COPY(deepCopy(this.EditAdditionalCostsPresale.Command));
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_ADDITIONALCOSTSPRESALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditAdditionalCostsPresaleSectionCommand()
    {
        const emptyCommand: EditAdditionalCostsPresaleCommand =
        {
            Id: Guid.createEmpty(),
            AdditionalCosts: new Array<AdditionalCostPresaleDetailsCommand>(),
        };
        this.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND(emptyCommand);
        this.SET_EDIT_ADDITIONALCOSTSPRESALE_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND ( command: EditAdditionalCostsPresaleCommand )
    {
        this.EditAdditionalCostsPresale.Command = command;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_COMMAND_COPY ( command: EditAdditionalCostsPresaleCommand | null )
    {
        this.EditAdditionalCostsPresale.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditAdditionalCostsPresale.Response = response;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_ADD_NEW_ELEMENT ( )
    {   
        const businessLineIdOfFirst = this.EditAdditionalCostsPresale.Command.AdditionalCosts[0]?.BusinessLineId ?? null;
        const techAreaIdOfFirst = this.EditAdditionalCostsPresale.Command.AdditionalCosts[0]?.TechAreaId ?? null;
        const supplyTipologyIdOfFirst = this.EditAdditionalCostsPresale.Command.AdditionalCosts[0]?.SupplyTipologyId ?? null;

        const resourceDetails: AdditionalCostPresaleDetailsCommand =
        {
            BusinessLineId: this.BusinessLineIdFromCRM ?? businessLineIdOfFirst,
            TechAreaId: this.TechAreaIdFromCRM ?? techAreaIdOfFirst,
            SupplyTipologyId: supplyTipologyIdOfFirst,
            Professional: "",
            Company: "",
            ResourceNameId: null,
            ResourceName: "",
            DaysMen: 0,
            DailyCost: 0,
        }
        this.EditAdditionalCostsPresale.Command.AdditionalCosts.push(resourceDetails);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_REMOVE_ELEMENT ( index: number )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_ID ( offerId: Guid )
    {
        this.EditAdditionalCostsPresale.Command.Id = offerId;
    }
    // Mutations Single Resource
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_BUSINESSLINE_ID ( payload: any )
    {
        InitBusinessLineIfNecessary(payload.businessLineId);
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].BusinessLineId = payload.businessLineId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_TECHAREA_ID ( payload: any )
    {
        InitTechAreaIfNecessary(payload.techAreaId);
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].TechAreaId = payload.techAreaId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_SUPPLYTIPOLOGY_ID ( payload: any )
    {
        InitSupplyTipologyIfNecessary(payload.supplyTipologyId);
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].SupplyTipologyId = payload.supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_PROFESSIONAL ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].Professional = payload.professional;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_COMPANY ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].Company = payload.company;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME_ID ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].ResourceNameId = payload.resourceNameId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_RESOURCENAME ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].ResourceName = payload.resourceName;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_DAYSMEN ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].DaysMen = payload.daysMen;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_DAILYCOST ( payload: any )
    {
        this.EditAdditionalCostsPresale.Command.AdditionalCosts[payload.index].DailyCost = payload.dailyCost;
    }
    // Mutation Error Response
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPRESALE_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditAdditionalCostsPresale.Errors = errors;
    }


    // Edit OtherCostsPresale
    @Action( { rawError: true } )
    public async EditOtherCostsPresaleSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditOtherCostsPresale(this.OfferEvaluationType, this.EditOtherCostsPresale.Command);
            this.SET_EDIT_OTHERCOSTSPRESALE_RESPONSE(response);
            this.SET_EDIT_OTHERCOSTSPRESALE_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_OTHERCOSTSPRESALE_COMMAND_COPY(deepCopy(this.EditOtherCostsPresale.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_OTHERCOSTSPRESALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditOtherCostsPresaleSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditOtherCostsPresaleDraft(this.OfferEvaluationType, this.EditOtherCostsPresale.Command);
            this.SET_EDIT_OTHERCOSTSPRESALE_RESPONSE(response);
            this.SET_EDIT_OTHERCOSTSPRESALE_COMMAND_COPY(deepCopy(this.EditOtherCostsPresale.Command));
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_OTHERCOSTSPRESALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditOtherCostsPresaleSectionCommand()
    {
        const emptyCommand: EditOtherCostsPresaleCommand =
        {
            Id: Guid.createEmpty(),
            OtherCosts: new Array<OtherCostPresaleDetailsCommand>(),
        };
        this.SET_EDIT_OTHERCOSTSPRESALE_COMMAND(emptyCommand);
        this.SET_EDIT_OTHERCOSTSPRESALE_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_OTHERCOSTSPRESALE_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_COMMAND ( command: EditOtherCostsPresaleCommand )
    {
        this.EditOtherCostsPresale.Command = command;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_COMMAND_COPY ( command: EditOtherCostsPresaleCommand | null )
    {
        this.EditOtherCostsPresale.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditOtherCostsPresale.Response = response;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_ADD_NEW_ELEMENT ( )
    {   
        const otherCostsDetails: OtherCostPresaleDetailsCommand =
        {
            Description: "",
            SupplierCode: "",
            SupplierBusinessName: "",
            ExpectedCost: 0,
        }
        this.EditOtherCostsPresale.Command.OtherCosts.push(otherCostsDetails);
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_REMOVE_ELEMENT ( index: number )
    {
        this.EditOtherCostsPresale.Command.OtherCosts.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_ID ( offerId: Guid )
    {
        this.EditOtherCostsPresale.Command.Id = offerId;
    }
    // Mutations Single Resource
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_DESCRIPTION ( payload: any )
    {
        this.EditOtherCostsPresale.Command.OtherCosts[payload.index].Description = payload.description;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERCODE ( payload: any )
    {
        this.EditOtherCostsPresale.Command.OtherCosts[payload.index].SupplierCode = payload.supplierCode;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_SUPPLIERBUSINESSNAME ( payload: any )
    {
        this.EditOtherCostsPresale.Command.OtherCosts[payload.index].SupplierBusinessName = payload.supplierBusinessName;
    }
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_EXPECTEDCOST ( payload: any )
    {
        this.EditOtherCostsPresale.Command.OtherCosts[payload.index].ExpectedCost = payload.expectedCost;
    }
    // Muataion Error Response
    @Mutation
    public SET_EDIT_OTHERCOSTSPRESALE_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditOtherCostsPresale.Errors = errors;
    }


    // Edit AdditionalCostsPostsale
    @Action( { rawError: true } )
    public async EditAdditionalCostsPostsaleSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditAdditionalCostsPostsale(this.OfferEvaluationType, this.EditAdditionalCostsPostsale.Command);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_RESPONSE(response);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND_COPY(deepCopy(this.EditAdditionalCostsPostsale.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditAdditionalCostsPostsaleSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditAdditionalCostsPostsaleDraft(this.OfferEvaluationType, this.EditAdditionalCostsPostsale.Command);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_RESPONSE(response);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND_COPY(deepCopy(this.EditAdditionalCostsPostsale.Command));
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditAdditionalCostsPostsaleSectionCommand()
    {
        const emptyCommand: EditAdditionalCostsPostsaleCommand =
        {
            Id: Guid.createEmpty(),
            Maintenance:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Warranty:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Support:
            {
                BusinessLineId: null,
                TechAreaId: null,
                SupplyTipologyId: null,
                Description: "",
                ExpectedCost: 0,
            },
            Others: new Array<AdditionalCostPostsaleDetailsCommand>(
                {
                    BusinessLineId: this.BusinessLineIdFromCRM,
                    TechAreaId: this.TechAreaIdFromCRM,
                    SupplyTipologyId: null,
                    Description: "",
                    ExpectedCost: 0,
                }
            ),
        };
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND(emptyCommand);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND_COPY(null);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND ( command: EditAdditionalCostsPostsaleCommand )
    {
        this.EditAdditionalCostsPostsale.Command = command;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_COMMAND_COPY ( command: EditAdditionalCostsPostsaleCommand | null )
    {
        this.EditAdditionalCostsPostsale.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditAdditionalCostsPostsale.Response = response;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_ADD_NEW_ELEMENT ( )
    {
        const businessLineIdOfFirst = this.EditAdditionalCostsPostsale.Command.Others[0]?.BusinessLineId ?? null;
        const techAreaIdOfFirst = this.EditAdditionalCostsPostsale.Command.Others[0]?.TechAreaId ?? null;
        const supplyTipologyIdOfFirst = this.EditAdditionalCostsPostsale.Command.Others[0]?.SupplyTipologyId ?? null;

        const resourceDetails: AdditionalCostPostsaleDetailsCommand =
        {
            BusinessLineId: this.BusinessLineIdFromCRM ?? businessLineIdOfFirst,
            TechAreaId: this.TechAreaIdFromCRM ?? techAreaIdOfFirst,
            SupplyTipologyId: supplyTipologyIdOfFirst,
            Description: "",
            ExpectedCost: 0,
        }
        this.EditAdditionalCostsPostsale.Command.Others.push(resourceDetails);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_REMOVE_ELEMENT ( index: number )
    {
        this.EditAdditionalCostsPostsale.Command.Others.splice(index, 1);
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_ID ( offerId: Guid )
    {
        this.EditAdditionalCostsPostsale.Command.Id = offerId;
    }
    // Mutations Maintenance
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditAdditionalCostsPostsale.Command.Maintenance.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditAdditionalCostsPostsale.Command.Maintenance.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditAdditionalCostsPostsale.Command.Maintenance.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_DESCRIPTION ( description: string )
    {
        this.EditAdditionalCostsPostsale.Command.Maintenance.Description = description;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditAdditionalCostsPostsale.Command.Maintenance.ExpectedCost = expectedCost;
    }
    // Mutations Warranty
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditAdditionalCostsPostsale.Command.Warranty.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditAdditionalCostsPostsale.Command.Warranty.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditAdditionalCostsPostsale.Command.Warranty.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_DESCRIPTION ( description: string )
    {
        this.EditAdditionalCostsPostsale.Command.Warranty.Description = description;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditAdditionalCostsPostsale.Command.Warranty.ExpectedCost = expectedCost;
    }
    // Mutations Support
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_BUSINESSLINE_ID ( businessLineId: Guid | null )
    {
        InitBusinessLineIfNecessary(businessLineId);
        this.EditAdditionalCostsPostsale.Command.Support.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_TECHAREA_ID ( techAreaId: Guid | null )
    {
        InitTechAreaIfNecessary(techAreaId);
        this.EditAdditionalCostsPostsale.Command.Support.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_SUPPLYTIPOLOGY_ID ( supplyTipologyId: Guid | null )
    {
        InitSupplyTipologyIfNecessary(supplyTipologyId);
        this.EditAdditionalCostsPostsale.Command.Support.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_DESCRIPTION ( description: string )
    {
        this.EditAdditionalCostsPostsale.Command.Support.Description = description;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditAdditionalCostsPostsale.Command.Support.ExpectedCost = expectedCost;
    }
    // Mutations Single Row
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_BUSINESSLINE_ID ( payload: any )
    {
        InitBusinessLineIfNecessary(payload.businessLineId);
        this.EditAdditionalCostsPostsale.Command.Others[payload.index].BusinessLineId = payload.businessLineId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_TECHAREA_ID ( payload: any )
    {
        InitTechAreaIfNecessary(payload.techAreaId);
        this.EditAdditionalCostsPostsale.Command.Others[payload.index].TechAreaId = payload.techAreaId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_SUPPLYTIPOLOGY_ID ( payload: any )
    {
        InitSupplyTipologyIfNecessary(payload.supplyTipologyId);
        this.EditAdditionalCostsPostsale.Command.Others[payload.index].SupplyTipologyId = payload.supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_DESCRIPTION ( payload: any )
    {
        this.EditAdditionalCostsPostsale.Command.Others[payload.index].Description = payload.description;
    }
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_EXPECTEDCOST ( payload: any )
    {
        this.EditAdditionalCostsPostsale.Command.Others[payload.index].ExpectedCost = payload.expectedCost;
    }
    // Muataion Error Response
    @Mutation
    public SET_EDIT_ADDITIONALCOSTSPOSTSALE_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditAdditionalCostsPostsale.Errors = errors;
    }


    // Edit TotalExpectedRevenueByFee
    @Action( { rawError: true } )
    public async EditTotalExpectedRevenueByFeeSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditTotalExpectedRevenueByFee(this.OfferEvaluationType, this.EditTotalExpectedRevenueByFee.Command);
            this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_RESPONSE(response);
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditTotalExpectedRevenueByFeeSectionDraft (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditTotalExpectedRevenueByFeeDraft(this.OfferEvaluationType, this.EditTotalExpectedRevenueByFee.Command);
            this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_RESPONSE(response);
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditTotalExpectedRevenueByFeeSectionCommand()
    {
        const emptyCommand: EditTotalExpectedRevenueByFeeCommand =
        {
            Id: Guid.createEmpty(),
            ExpectedFee: 0,
        };
        this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_COMMAND(emptyCommand);
        this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_COMMAND ( command: EditTotalExpectedRevenueByFeeCommand )
    {
        this.EditTotalExpectedRevenueByFee.Command = command;
    }
    @Mutation
    public SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditTotalExpectedRevenueByFee.Response = response;
    }
    @Mutation
    public SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ID ( offerId: Guid )
    {
        this.EditTotalExpectedRevenueByFee.Command.Id = offerId;
    }
    @Mutation
    public SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_FEE ( expectedFee: number )
    {
        this.EditTotalExpectedRevenueByFee.Command.ExpectedFee = expectedFee;
    }
    @Mutation
    public SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditTotalExpectedRevenueByFee.Errors = errors;
    }

    // Edit Attachments
    @Action( { rawError: true } )
    public async EditAttachmentsSection (): Promise<OfferEvaluationSectionResponse>
    {
        try
        {
            const response = await this.orderService.EditAttachments(this.OfferEvaluationType, this.EditAttachments.Command);
            await this.UploadFilesOffer();
            this.SET_EDIT_ATTACHMENTS_RESPONSE(response);
            this.SET_EDIT_ATTACHMENTS_COMMAND_COPY(deepCopy(this.EditAttachments.Command));
            return response;
        }
        catch(error)
        {
            const errorParsed = parseErrors(error);
            this.SET_EDIT_ATTACHMENTS_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditAttachmentsSectionCommand()
    {
        const emptyCommand: EditAttachmentsCommand =
        {
            Id: Guid.createEmpty(),
            Notes: "",
            BlobsName: new Array<string>(),
        };
        this.SET_EDIT_ATTACHMENTS_COMMAND(emptyCommand);
        this.SET_EDIT_ATTACHMENTS_ERRORS(new Map<string, Array<string>>());
        this.SET_EDIT_ATTACHMENTS_COMMAND_COPY(null);
        this.ResetUploadFilesSectionCommand();
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_COMMAND ( command: EditAttachmentsCommand )
    {
        this.EditAttachments.Command = command;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_COMMAND_COPY ( command: EditAttachmentsCommand | null )
    {
        this.EditAttachments.CommandCopy = command;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditAttachments.Response = response;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_ID ( offerId: Guid )
    {
        this.EditAttachments.Command.Id = offerId;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_NOTES ( notes: string )
    {
        this.EditAttachments.Command.Notes = notes;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_FILES( filesName: Array<string>)
    {
        this.EditAttachments.Command.BlobsName = filesName;
    }
    @Mutation
    public SET_EDIT_ATTACHMENTS_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditAttachments.Errors = errors;
    }

    // Upload Files
    @Action( { rawError: true } )
    private async UploadFilesOffer (): Promise<void>
    {
        const response = await this.orderService.UploadFilesOfferEvaluation(this.OfferEvaluationType, this.UploadFiles.Command);
        this.SET_UPLOAD_FILES_RESPONSE(response);
    }
    @Action( { rawError: true } )
    public ResetUploadFilesSectionCommand()
    {
        const emptyCommand: UploadFilesOfferEvaluationCommand =
        {
            Id: Guid.createEmpty(),
            Files: new Array<File>(),
        };
        this.SET_UPLOAD_FILES_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_UPLOAD_FILES_COMMAND ( command: UploadFilesOfferEvaluationCommand )
    {
        this.UploadFiles.Command = command;
    }
    @Mutation
    public SET_UPLOAD_FILES_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.UploadFiles.Response = response;
    }
    @Mutation
    public SET_UPLOAD_FILES_ID ( offerId: Guid )
    {
        this.UploadFiles.Command.Id = offerId;
    }
    @Mutation
    public SET_UPLOAD_FILES ( files: Array<File> )
    {
        this.UploadFiles.Command.Files = files;
    }

    // Common Action between Section comamnd
    @Action( { rawError: true } )
    public SetOfferEvaluationIdToSections(id: Guid)
    {
        this.SET_EDIT_FIXEDPRICEPROJECT_ID(id);
        this.SET_EDIT_HUMANRESOURCES_ID(id);
        this.SET_EDIT_OTHERCOMPONENTS_ID(id);
        this.SET_EDIT_TOTALAMOUNTS_ID(id);
        this.SET_EDIT_ADDITIONALCOSTSPRESALE_ID(id);
        this.SET_EDIT_OTHERCOSTSPRESALE_ID(id);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ID(id);
        this.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_ID(id);
        this.SET_EDIT_ATTACHMENTS_ID(id);
        this.SET_UPLOAD_FILES_ID(id);
    }
    @Mutation
    public SET_OFFER_EVALUATION_TYPE(value: string)
    {
        value = value.toLowerCase();
        if(value !== "customerorder" && value !== "internalproject")
            console.error(`Type ${value} of OfferEvaluation doesn't exist. Acceptable values are: 'customerorder' o 'internalproject'`);
        else
            this.OfferEvaluationType = value;
    }

    //HubSpot
    @Action( { rawError: true } )
    public ResetCRMDeal()
    {
        const crmDeal : HubSpotDealDetailViewModel =
        {
            Id: "",
            CreatedAt: new Date(),
            ClosedAt: new Date(),
            Properties:
            {
                Amount: 0,
                CreateDate: new Date(),
                CloseDate: new Date(),
                DealName: "",
                DealStage: "",
                HsLastModifiedDate: new Date(),
                HubSpotOwnerId: "",
                HubSpotOwnerFullName: "",
                BusinessLine: "",
                TechArea: "",
                TechnologiaLead: "",
            },
        }
        this.SET_CRM_DEAL(crmDeal);
    }
    @Mutation
    public SET_CRM_DEAL(crmDeal: HubSpotDealDetailViewModel)
    {
        this.CRMDeal = crmDeal;
    }
    @Mutation
    public SET_BUSINESSLINEID_FROM_CRM(businessLineId: Guid)
    {
        this.BusinessLineIdFromCRM = businessLineId;
    }
    @Mutation
    public SET_TECHAREAID_FROM_CRM(techAreaId: Guid)
    {
        this.TechAreaIdFromCRM = techAreaId;
    }

    @Action( { rawError: true } )
    public InitValuesUsingCRMDeal() : void
    {
        CustomerOrderStore.SET_CREATE_CUSTOMERORDER_HEADER_CRMDEALID(this.CRMDeal.Id);
        CustomerOrderStore.SET_CREATE_CUSTOMERORDER_HEADER_CRMAMOUNT(this.CRMDeal.Properties.Amount);
        CustomerOrderStore.SET_CREATE_CUSTOMERORDER_HEADER_CRMLASTUPDATE(this.CRMDeal.Properties.HsLastModifiedDate);
        CustomerOrderStore.SET_CREATE_CUSTOMERORDER_HEADER_DESCRIPTION(this.CRMDeal.Properties.DealName);
        CustomerOrderStore.SET_CREATE_CUSTOMERORDER_HEADER_ORDERTYPE(
            this.CRMDeal.Properties.TechnologiaLead === "Renewal"? OrderTipology.Renewal : OrderTipology.New);
        
        // Init BusinessLine from CRM if exists
        const businessLineId = this.BusinessLinesDropdown
            .filter(bl => this.CRMDeal.Properties.BusinessLine.toLowerCase().includes(bl.Title.toLowerCase()))[0]?.Id ?? null;
        // Init TechArea from CRM if exists
        const techAreaId = this.TechAreasDropdown
            .filter(ta => this.CRMDeal.Properties.TechArea.toLowerCase().includes(ta.Name.toLowerCase()))[0]?.Id ?? null;

        this.SET_BUSINESSLINEID_FROM_CRM(businessLineId);
        this.SET_TECHAREAID_FROM_CRM(techAreaId);

        // Init BusinessLines and TechAreas into sections
        // OTHER COMPONENTS
        this.SET_EDIT_OTHERCOMPONENTS_PRODUCT_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_PRODUCT_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SOLUTION_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SOLUTION_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SERVICE_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SERVICE_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_TRANSFER_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_TRANSFER_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SECURITY_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_SECURITY_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_OTHERCOMPONENTS_OTHER_BUSINESSLINE_ID({ index: 0, businessLineId: this.BusinessLineIdFromCRM});
        this.SET_EDIT_OTHERCOMPONENTS_OTHER_TECHAREA_ID({ index: 0, techAreaId: this.TechAreaIdFromCRM });
        // ADDITIONAL COSTS POSTSALE
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_WARRANTY_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_BUSINESSLINE_ID(this.BusinessLineIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_SUPPORT_TECHAREA_ID(this.TechAreaIdFromCRM);
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_BUSINESSLINE_ID({ index: 0, businessLineId: this.BusinessLineIdFromCRM});
        this.SET_EDIT_ADDITIONALCOSTSPOSTSALE_OTHERS_TECHAREA_ID({ index: 0, techAreaId: this.TechAreaIdFromCRM });
    }
}

export const SectionStore = getModule( SectionsStore );

function InitBusinessLineIfNecessary(businessLineId: Guid | null) : void
{
    if(SectionStore.OfferEvaluationType !== 'customerorder' || businessLineId === null)
        return;
    const atLeastOneBusinessLineInHumanResources = SectionStore.EditHumanResources.Command.Resources
        .some(hr => hr.BusinessLineId != null);
    const atLeastOneBusinessLineInOtherComponents = SectionStore.EditOtherComponents.Command.Others
        .some(oc => oc.BusinessLineId != null)
        || !!SectionStore.EditOtherComponents.Command.ProductsPurchase.BusinessLineId
        || !!SectionStore.EditOtherComponents.Command.SolutionsPurchase.BusinessLineId
        || !!SectionStore.EditOtherComponents.Command.ServicesPurchase.BusinessLineId
        || !!SectionStore.EditOtherComponents.Command.TransferCosts.BusinessLineId
        || !!SectionStore.EditOtherComponents.Command.SecurityCosts.BusinessLineId;
    const atLeastOneBusinessLineInAdditionalCostPresale = SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts
        .some(ac => ac.BusinessLineId != null);
    const atLeastOneBusinessLineInAdditionalCostPostsale = SectionStore.EditAdditionalCostsPostsale.Command.Others
        .some(ac => ac.BusinessLineId != null)
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.BusinessLineId
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Warranty.BusinessLineId
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Support.BusinessLineId;
    if( !atLeastOneBusinessLineInHumanResources
        && !atLeastOneBusinessLineInOtherComponents
        && !atLeastOneBusinessLineInAdditionalCostPresale
        && !atLeastOneBusinessLineInAdditionalCostPostsale )
    {
        // Setting BusinessLineIds in Human Resources
        for (const [i, _] of SectionStore.EditHumanResources.Command.Resources.entries())
        {
            SectionStore.EditHumanResources.Command.Resources[i].BusinessLineId = businessLineId;
        }
        // Setting BusinessLineIds in Other Components
        for (const [i, _] of SectionStore.EditOtherComponents.Command.Others.entries())
        {
            SectionStore.EditOtherComponents.Command.Others[i].BusinessLineId = businessLineId;
        }
        SectionStore.EditOtherComponents.Command.ProductsPurchase.BusinessLineId = businessLineId;
        SectionStore.EditOtherComponents.Command.SolutionsPurchase.BusinessLineId = businessLineId;
        SectionStore.EditOtherComponents.Command.ServicesPurchase.BusinessLineId = businessLineId;
        SectionStore.EditOtherComponents.Command.TransferCosts.BusinessLineId = businessLineId;
        SectionStore.EditOtherComponents.Command.SecurityCosts.BusinessLineId = businessLineId;
        // Setting BusinessLineIds in Additional Costs Presale
        for (const [i, _] of SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.entries())
        {
            SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[i].BusinessLineId = businessLineId;
        }
        // Setting BusinessLineIds in Additional Costs Postsale
        for (const [i, _] of SectionStore.EditAdditionalCostsPostsale.Command.Others.entries())
        {
            SectionStore.EditAdditionalCostsPostsale.Command.Others[i].BusinessLineId = businessLineId;
        }
        SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.BusinessLineId = businessLineId;
        SectionStore.EditAdditionalCostsPostsale.Command.Warranty.BusinessLineId = businessLineId;
        SectionStore.EditAdditionalCostsPostsale.Command.Support.BusinessLineId = businessLineId;
    }
}

function InitTechAreaIfNecessary(techAreaId: Guid | null) : void
    {
        if(SectionStore.OfferEvaluationType !== 'customerorder' || techAreaId === null)
            return;
        const atLeastOneTechAreaInHumanResources = SectionStore.EditHumanResources.Command.Resources
            .some(hr => hr.TechAreaId != null);
        const atLeastOneTechAreaInOtherComponents = SectionStore.EditOtherComponents.Command.Others
            .some(oc => oc.TechAreaId != null)
            || !!SectionStore.EditOtherComponents.Command.ProductsPurchase.TechAreaId
            || !!SectionStore.EditOtherComponents.Command.SolutionsPurchase.TechAreaId
            || !!SectionStore.EditOtherComponents.Command.ServicesPurchase.TechAreaId
            || !!SectionStore.EditOtherComponents.Command.TransferCosts.TechAreaId
            || !!SectionStore.EditOtherComponents.Command.SecurityCosts.TechAreaId;
        const atLeastOneTechAreaInAdditionalCostPresale = SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts
            .some(ac => ac.TechAreaId != null);
        const atLeastOneTechAreaInAdditionalCostPostsale = SectionStore.EditAdditionalCostsPostsale.Command.Others
            .some(ac => ac.TechAreaId != null)
            || !!SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.TechAreaId
            || !!SectionStore.EditAdditionalCostsPostsale.Command.Warranty.TechAreaId
            || !!SectionStore.EditAdditionalCostsPostsale.Command.Support.TechAreaId;
        if( !atLeastOneTechAreaInHumanResources
            && !atLeastOneTechAreaInOtherComponents
            && !atLeastOneTechAreaInAdditionalCostPresale
            && !atLeastOneTechAreaInAdditionalCostPostsale )
        {
            // Setting TechAreaIds in Human Resources
            for (const [i, _] of SectionStore.EditHumanResources.Command.Resources.entries())
            {
                const humanResource = SectionStore.EditHumanResources.Command.Resources[i];
                if(IsTechAreaPartOfBusinessLine(techAreaId, humanResource.BusinessLineId))
                    humanResource.TechAreaId = techAreaId;
            }
            // Setting TechAreaIds in Other Components
            for (const [i, _] of SectionStore.EditOtherComponents.Command.Others.entries())
            {
                const other =  SectionStore.EditOtherComponents.Command.Others[i];
                if(IsTechAreaPartOfBusinessLine(techAreaId, other.BusinessLineId))
                    other.TechAreaId = techAreaId;
            }
            const commandOtherComp = SectionStore.EditOtherComponents.Command;
            commandOtherComp.ProductsPurchase.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandOtherComp.ProductsPurchase.BusinessLineId) ? techAreaId : null;
            commandOtherComp.SolutionsPurchase.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandOtherComp.SolutionsPurchase.BusinessLineId) ? techAreaId : null;
            commandOtherComp.ServicesPurchase.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandOtherComp.ServicesPurchase.BusinessLineId) ? techAreaId : null;
            commandOtherComp.TransferCosts.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandOtherComp.TransferCosts.BusinessLineId) ? techAreaId : null;
            commandOtherComp.SecurityCosts.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandOtherComp.SecurityCosts.BusinessLineId) ? techAreaId : null;
            // Setting TechAreaIds in Additional Costs Presale
            for (const [i, _] of SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.entries())
            {
                const additionalCost = SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[i];
                if(IsTechAreaPartOfBusinessLine(techAreaId, additionalCost.BusinessLineId))
                    additionalCost.TechAreaId = techAreaId;
            }
            // Setting TechAreaIds in Additional Costs Postsale
            for (const [i, _] of SectionStore.EditAdditionalCostsPostsale.Command.Others.entries())
            {
                const other = SectionStore.EditAdditionalCostsPostsale.Command.Others[i];
                if(IsTechAreaPartOfBusinessLine(techAreaId, other.BusinessLineId))
                    other.TechAreaId = techAreaId;
            }
            const commandAddCostsPost = SectionStore.EditAdditionalCostsPostsale.Command;
            commandAddCostsPost.Maintenance.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandAddCostsPost.Maintenance.BusinessLineId) ? techAreaId : null;
            commandAddCostsPost.Warranty.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandAddCostsPost.Warranty.BusinessLineId) ? techAreaId : null;
            commandAddCostsPost.Support.TechAreaId = IsTechAreaPartOfBusinessLine(techAreaId, commandAddCostsPost.Support.BusinessLineId) ? techAreaId : null;
        }
    }

function IsTechAreaPartOfBusinessLine(techAreaId: Guid | null, businessLineId: Guid | null) : boolean
{
    if(techAreaId === null || businessLineId === null)
        return false;
    else
    {
        const techAreas = SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === businessLineId);
        return techAreas.some(ta => ta.Id === techAreaId);
    }
}

function InitSupplyTipologyIfNecessary(supplyTipologyId: Guid | null) : void
{
    if(SectionStore.OfferEvaluationType !== 'customerorder' || supplyTipologyId === null)
        return;
    const atLeastOneSupplyTipologyInHumanResources = SectionStore.EditHumanResources.Command.Resources
        .some(hr => hr.SupplyTipologyId != null);
    const atLeastOneSupplyTipologyInOtherComponents = SectionStore.EditOtherComponents.Command.Others
        .some(oc => oc.SupplyTipologyId != null)
        || !!SectionStore.EditOtherComponents.Command.ProductsPurchase.SupplyTipologyId
        || !!SectionStore.EditOtherComponents.Command.SolutionsPurchase.SupplyTipologyId
        || !!SectionStore.EditOtherComponents.Command.ServicesPurchase.SupplyTipologyId
        || !!SectionStore.EditOtherComponents.Command.TransferCosts.SupplyTipologyId
        || !!SectionStore.EditOtherComponents.Command.SecurityCosts.SupplyTipologyId;
    const atLeastOneSupplyTipologyInAdditionalCostPresale = SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts
        .some(ac => ac.SupplyTipologyId != null);
    const atLeastOneSupplyTipologyInAdditionalCostPostsale = SectionStore.EditAdditionalCostsPostsale.Command.Others
        .some(ac => ac.SupplyTipologyId != null)
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.SupplyTipologyId
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Warranty.SupplyTipologyId
        || !!SectionStore.EditAdditionalCostsPostsale.Command.Support.SupplyTipologyId;
    if( !atLeastOneSupplyTipologyInHumanResources
        && !atLeastOneSupplyTipologyInOtherComponents
        && !atLeastOneSupplyTipologyInAdditionalCostPresale
        && !atLeastOneSupplyTipologyInAdditionalCostPostsale )
    {
        // Setting SupplyTipologyIds in Human Resources
        for (const [i, _] of SectionStore.EditHumanResources.Command.Resources.entries())
        {
            SectionStore.EditHumanResources.Command.Resources[i].SupplyTipologyId = supplyTipologyId;
        }
        // Setting SupplyTipologyIds in Other Components
        for (const [i, _] of SectionStore.EditOtherComponents.Command.Others.entries())
        {
            SectionStore.EditOtherComponents.Command.Others[i].SupplyTipologyId = supplyTipologyId;
        }
        SectionStore.EditOtherComponents.Command.ProductsPurchase.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditOtherComponents.Command.SolutionsPurchase.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditOtherComponents.Command.ServicesPurchase.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditOtherComponents.Command.TransferCosts.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditOtherComponents.Command.SecurityCosts.SupplyTipologyId = supplyTipologyId;
        // Setting SupplyTipologyIds in Additional Costs Presale
        for (const [i, _] of SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.entries())
        {
            SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts[i].SupplyTipologyId = supplyTipologyId;
        }
        // Setting SupplyTipologyIds in Additional Costs Postsale
        for (const [i, _] of SectionStore.EditAdditionalCostsPostsale.Command.Others.entries())
        {
            SectionStore.EditAdditionalCostsPostsale.Command.Others[i].SupplyTipologyId = supplyTipologyId;
        }
        SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditAdditionalCostsPostsale.Command.Warranty.SupplyTipologyId = supplyTipologyId;
        SectionStore.EditAdditionalCostsPostsale.Command.Support.SupplyTipologyId = supplyTipologyId;
    }
}

export function getFormattedStage(value: string) : string
{
    let result = '';
    switch(value)
    {
        case 'closedwon':
            result = "Closed Won";
            break;
        case 'contractsent':
            result = 'Pre Order';
            break;
        case 'decisionmakerboughtin':
            result = "Qualified Proposal";
            break;
        case 'presentationscheduled':
            result = 'Proposal';
            break;
        default:
            break;
    }
    return result;
}