
























import Vue from "vue";
import { Component} from "vue-property-decorator";
import { CreateOfferModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Business Line",
          align: "start",
          sortable: false,
          value: "BusinessLine"
        },
        { text: "TechArea", value: "TechArea" },
        { text: "Tipologia Fornitura", value: "TipologiaFornitura" },
        { text: "Figura Professionale", value: "FiguraProfessionale" },
        { text: "Società", value: "Societa" },
        { text: "Nome Risorsa", value: "NomeRisorsa" },
        { text: "GG/Uomo Previsti", value: "GGUomoPrev" },
        { text: "GG/Uomo Offerta", value: "GGUomoOff" },
        { text: "Margine Rischio %", value: "MargineRischio" },
        {
          text: "Tariffa Giornaliera Offerta",
          value: "TariffaOfferta"
        },
        { text: "Importo Offerta", value: "ImportoOfferta" },
        {
          text: "Tariffa Giornaliera Minima",
          value: "TariffaMinima"
        },
        { text: "Costo Atteso", value: "CostoAtteso" },
        { text: "Margine Atteso", value: "MargineAtteso" },
        { text: "Margine Atteso %", value: "MargineAttesoPerc" }
      ]
    };
  },
  computed: {
    RisorseUmaneItems: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaRisorseUmaneCreate;
      }
    }
  }
})
export default class RisorseUmaneDisplay extends Vue {}
