






















import { Component, Prop } from 'vue-property-decorator';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { CustomerOrderStore } from '../../../store';

@Component({})
export default class PgaOtherCustomerReferralEdit extends mixins(SharedMixin)
{
    @Prop({ default: -1, type: Number}) index!: number;

    private get otherCustomerReferral() : string
    {
        return CustomerOrderStore.OrderRequirementsReviewEdit.Command.OtherCustomerReferrals[this.index];
    }
    private set otherCustomerReferral(value: string)
    {
        CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS({ index: this.index, otherCustomerReferrals: value });
    }

    private removeOtherCustomerReferral() : void
    {
        CustomerOrderStore.REMOVE_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS(this.index);
    }
}
