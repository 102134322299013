






	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaBusinessStartupCustomerOrderEdit from './components/edit/PgaBusinessStartupCustomerOrderEdit.vue';
    import { CustomerOrderStore } from "./store";

	@Component( {
		components:
		{
			PgaBusinessStartupCustomerOrderEdit,
		},
	} )
    export default class BusinessStartupPageCustomerOrderEdit extends mixins( SharedMixin )
    {
		private isLoaded = false;

		async mounted() : Promise<void>
		{
			const id: Guid = Guid.parse(this.$route.params.id);
			this.isLoaded = false;
			await CustomerOrderStore.GetCustomerOrdersById(id);
			CustomerOrderStore.InitOrderRequirementsReviewEdit();
			this.isLoaded = true;
		}
    }
