















	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { SectionStore } from "../../../SectionStore";
    import { AdditionalCostPostsaleDetailsCommand } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalCostsPostsaleCreate extends mixins( SharedMixin )
	{
        private get maintenance() : AdditionalCostPostsaleDetailsCommand
        {
            return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance;
        }
        private get warranty() : AdditionalCostPostsaleDetailsCommand
        {
            return SectionStore.EditAdditionalCostsPostsale.Command.Warranty;
        }
		private get support() : AdditionalCostPostsaleDetailsCommand
        {
            return SectionStore.EditAdditionalCostsPostsale.Command.Support;
        }
		private get others() : Array<AdditionalCostPostsaleDetailsCommand>
        {
            return SectionStore.EditAdditionalCostsPostsale.Command.Others;
        }

		// Total Costs Postsale 
		get totalCostsPostsale (): number
		{
			const total = this.maintenance.ExpectedCost
				+ this.warranty.ExpectedCost
				+ this.support.ExpectedCost
				+ (this.others.length > 0 ?
					this.others
						.map(o => o.ExpectedCost)
						.reduce((sum, current) => sum + current) : 0);
			this.$emit('postsale', total);
			return total;
		}
	}
