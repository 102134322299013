



























	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { BusinessStartupInternalProjectViewTable } from "@/services/iOrderService";
    import { InternalProjectStore } from "../../store";

	@Component({})
	export default class PgaBusinessStartupInternalProjectTable extends mixins(SharedMixin)
	{
        private isLoading = false;
        private showClosed = false;
		private headers =
			[
                {
                    text: "Title",
                    align: 'start',
                    sortable: true,
					value: "Title",
                    keySearch: "ti",
				},
				{
                    text: "Start Date",
                    align: 'start',
                    sortable: true,
					value: "StartDate",
                    keySearch: "sd",
				},
                {
                    text: "Expected End Date",
                    align: 'start',
                    sortable: true,
					value: "ExpectedEndDate",
                    keySearch: "eed",
				},
				{
                    text: "Code",
                    align: 'start',
                    sortable: true,
					value: "Code",
                    keySearch: "co",
                },
            ];

        private get internalProjectList() : Array<BusinessStartupInternalProjectViewTable>
        {
            if(this.showClosed)
                return InternalProjectStore.InternalProjectsClosed;    
            return InternalProjectStore.InternalProjects;
        }

        private async getInternalProjectsClosed(value: boolean) : Promise<void>
        {
            if(!InternalProjectStore.InternalProjectsClosed.some(co => co) && value)
            {
                this.isLoading = true;
                await InternalProjectStore.ListBusinessStartupInternalProjectsClosed();
                this.isLoading = false;
            }
        }

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private details(item: any) : void
		{
            const id = item.Id;
			this.$router.push( { name: "businessstartupprojectdetails", params: { id } } );
        }

        private getFormattedDate(value: Date | string) : string
        {
            const date = new Date(value);
            return date.toLocaleDateString();
        }
        
        async mounted() : Promise<void>
        {
            this.isLoading = true;
            await InternalProjectStore.ListBusinessStartupInternalProjects();
            this.isLoading = false;
        }
	}
