
















































































































































































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import {
  CreateOfferModule,
  PgaValutazioneOffertaRisorseUmaneCreateModel
} from "../store";
import { OfferServiceModule } from "../../store";
import PgaNumberField from "../../../../components/inputs/PgaNumberField.vue";
import PgaCurrencyField from "../../../../components/inputs/PgaCurrencyField.vue";

@Component({
  data: () => ({
    RisorsaUmana: undefined
  }),
  components: {
    PgaCurrencyField,
    PgaNumberField
  },

  computed: {
    PgaNumberField: {
      get() {
        return this.$props.Model.PgaNumberField;
      }
    },
    PgaCurrencyField: {
      get() {
        return this.$props.Model.PgaCurrencyField;
      }
    },
    ItemsNomeRisorsa: {
      get() {
        return OfferServiceModule.getNOMERISORSA;
      }
    },
    NomeRisorsa: {
      get() {
        return this.$props.Model.NomeRisorsa;
      },
      set(value: string) {
        CreateOfferModule.SET_NOMERISORSA({
          index: this.$props.index,
          value: value
        });
      }
    },
    BusinessLineItems: {
      get() {
        return OfferServiceModule.getBUSINESSLINE;
      }
    },
    BusinessLine: {
      get() {
        return this.$props.Model.BusinessLine;
      },
      set(value: string) {
        CreateOfferModule.SET_BUSINESSLINE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TechAreaItems: {
      get() {
        return OfferServiceModule.getTECHAREA(
          CreateOfferModule.ModuloValutazioneOfferta
            .PgaValutazioneOffertaRisorseUmaneCreate[this.$props.index]
            .BusinessLine
        );
      }
    },
    TechArea: {
      get() {
        return this.$props.Model.TechArea;
      },
      set(value: string) {
        CreateOfferModule.SET_TECHAREA({
          index: this.$props.index,
          value: value
        });
      }
    },
    TipologiaFornituraItems: {
      get() {
        return OfferServiceModule.getTIPOLOGIAFORNITURA;
      }
    },
    TipologiaFornitura: {
      get() {
        return this.$props.Model.TipologiaFornitura;
      },
      set(value: string) {
        CreateOfferModule.SET_TIPOLOGIAFORNITURA({
          index: this.$props.index,
          value: value
        });
      }
    },
    FiguraProfessionale: {
      get() {
        return this.$props.Model.FiguraProfessionale;
      },
      set(value: string) {
        CreateOfferModule.SET_FIGURAPROFESSIONALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    Societa: {
      get() {
        return this.$props.Model.Societa;
      },
      set(value: string) {
        CreateOfferModule.SET_SOCIETA({
          index: this.$props.index,
          value: value
        });
      }
    },
    GGUomoPrev: {
      get() {
        return this.$props.Model.GGUomoPrev;
      },
      set(value: number) {
        CreateOfferModule.UpdateGiorniUomoPrevisti({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    GGUomoOff: {
      get() {
        return this.$props.Model.GGUomoOff;
      },
      set(value: number) {
        CreateOfferModule.UpdateGiorniUomoOfferta({
          index: this.$props.index,
          value: value
        });
      }
    },
    MargineRischio: {
      get() {
        return this.$props.Model.MargineRischio;
      }
    },
    TariffaOfferta: {
      get() {
        return this.$props.Model.TariffaOfferta;
      },
      set(value: number) {
        CreateOfferModule.UpdateTariffaOfferta({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    ImportoOfferta: {
      get() {
        return parseFloat(this.$props.Model.ImportoOfferta);
      }
    },
    TariffaMinima: {
      get() {
        return this.$props.Model.TariffaMinima;
      },
      set(value: number) {
        CreateOfferModule.UpdateTariffaMinima({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    MargineAtteso: {
      get() {
        return this.$props.Model.MargineAtteso;
      }
    },
    CostoAtteso: {
      get() {
        return this.$props.Model.CostoAtteso;
      }
    },
    MargineAttesoPerc: {
      get() {
        return this.$props.Model.MargineAttesoPerc;
      }
    }
  }
})
export default class PgaValutazioneOffertaRisorseUmaneCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object })
  readonly Model!: PgaValutazioneOffertaRisorseUmaneCreateModel;
  async mounted() {
    await OfferServiceModule.RetrievePokemons();
  }

  @Emit()
  removeRisorsa() {
    CreateOfferModule.REMOVE_RISORSA_UMANA(this.index);
  }
  @Emit()
  duplicateRisorsa() {
    CreateOfferModule.DUPLICATE_RISORSA_UMANA(this.index);
  }
}
