






























































































































































	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { InternalProjectStore } from './InternalProjectStore';
	import PgaOfferEvaluationInternalProjectDetails from './components/details/PgaOfferEvaluationInternalProjectDetails.vue';
	import { OfferEvaluationInternalProjectDetailsView, StatusOfferEvaluation } from "@/services/iOrderService";
	
	import { EmailsStore } from "@/feature/Shared/Email/store";
	import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";

	@Component( {
		components:
		{
			PgaOfferEvaluationInternalProjectDetails,
		}
	} )
    export default class OfferEvaluationPageInternalProjectDetails extends mixins( SharedMixin )
    {
		private isLoaded = false;
		private showDialog = false;
		private title = "";
		private message = "";

		get isAssessment() : boolean
		{
			return this.internalProjectDetails.Versions.some(v => v);
		}

		get internalProjectDetails() : OfferEvaluationInternalProjectDetailsView
		{
			return InternalProjectStore.InternalProjectDetails;
		}

		get isEditable() : boolean
		{
			return this.internalProjectDetails.Status != StatusOfferEvaluation.Approved
				&& this.internalProjectDetails.Status != StatusOfferEvaluation.Closed;
		}

		get isDeletable() : boolean
		{
			return this.internalProjectDetails.Status != StatusOfferEvaluation.Closed;
		}

		get isActivable() : boolean
		{
			return this.internalProjectDetails.Status == StatusOfferEvaluation.Saved;
		}

		get isApprovalRequestable() : boolean
		{
			return this.internalProjectDetails.Status == StatusOfferEvaluation.ApprovalRequestable
				&& !this.isAssessment;
		}

		get isApprovableByDG() : boolean
		{
			return !(this.internalProjectDetails.ApprovalProjectDG
						|| this.internalProjectDetails.ApprovalSpendingBudgetDG)
				&& this.internalProjectDetails.Status == StatusOfferEvaluation.Activating
				&& !this.isAssessment;
		}

		get isApprovableBySG() : boolean
		{
			const hasDGApproved = this.internalProjectDetails.ApprovalProjectDG
						|| this.internalProjectDetails.ApprovalSpendingBudgetDG;
			return hasDGApproved && !this.internalProjectDetails.ReviewSG
				&& this.internalProjectDetails.Status == StatusOfferEvaluation.Activating
				&& !this.isAssessment;
		}

		// Assessment
		get canCreateAssessment() : boolean
		{
			return this.internalProjectDetails.Status == StatusOfferEvaluation.Approved;
		}

		get isNotificableAssessment() : boolean
		{
			return this.internalProjectDetails.Status == StatusOfferEvaluation.Saved
				&& this.isAssessment;
		}
		
		get isApprovalRequestableAssessment() : boolean
		{
			return this.internalProjectDetails.Status == StatusOfferEvaluation.ApprovalRequestable
				&& this.isAssessment;
		}

		get isApprovableAssessmentByDG() : boolean
		{

			const needDGApprove = this.internalProjectDetails.ExceedBudget;
			return needDGApprove
				&& !(this.internalProjectDetails.ApprovalProjectDG
						|| this.internalProjectDetails.ApprovalSpendingBudgetDG)
				&& this.internalProjectDetails.Status == StatusOfferEvaluation.Activating
				&& this.isAssessment;
		}

		get isApprovableAssessmentBySG() : boolean
		{
			const needDGApprove = this.internalProjectDetails.ExceedBudget;
			const hasDGApproved = !needDGApprove || (this.internalProjectDetails.ApprovalProjectDG
						|| this.internalProjectDetails.ApprovalSpendingBudgetDG);
			return hasDGApproved 
				&& !this.internalProjectDetails.ReviewSG
				&& this.internalProjectDetails.Status == StatusOfferEvaluation.Activating
				&& this.isAssessment;
		}

        private async Edit() : Promise<void>
        {
            const id = this.$route.params.id;
            this.$router.push( { name: "offerevaluationprojectedit", params: { id } } );
        }

		private async Active() : Promise<void>
        {
			await InternalProjectStore.ActiveInternalProjectCommand();
			if(this.internalProjectDetails.Status === StatusOfferEvaluation.Activating)
				this.$router.go(-1);
        }

		private async RequestApproval() : Promise<void>
        {
			await InternalProjectStore.RequestApprovalInternalProjectCommand();
			this.$router.go(-1);
        }

		private async Approve() : Promise<void>
        {
			await InternalProjectStore.ApproveInternalProjectCommand();
			this.$router.go(-1);
        }

		// Assessment
		private async CreateAssessment() : Promise<void>
		{
			await InternalProjectStore.CreateAssessmentInternalProject();
		}

		private async NotifyAssessment() : Promise<void>
        {
			await InternalProjectStore.NotifyAssessmentInternalProjectCommand();
			if(this.internalProjectDetails.Status === StatusOfferEvaluation.Activating)
				this.$router.go(-1);
        }

		private async RequestApprovalAssessment() : Promise<void>
        {
			await InternalProjectStore.RequestApprovalAssessmentInternalProjectCommand();
			this.$router.go(-1);
        }

		private async ApproveAssessment() : Promise<void>
        {
			await InternalProjectStore.ApproveAssessmentInternalProjectCommand();
			this.$router.go(-1);
        }


		private RequestChanges()
		{
			const sendEmailCommand : SendEmailToPermissionsCommand = 
			{
				PermissionIds: ["525FDD79-7DAD-4D4D-941F-3F1473C5F102"],
				Subject: `Request changes for Internal Project of ${this.internalProjectDetails.Title}`,
				Body: "",
			};
			EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
			EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
		}

		private formatDate(date: Date) : string
		{
			return date.toLocaleDateString();
		}

		async Delete(): Promise<void> 
		{
			this.showDialog = false;
			await InternalProjectStore.DeleteInternalProject();
			this.$router.go(-1);
		}

		async mounted() : Promise<void>
		{
			const id: Guid = Guid.parse(this.$route.params.id);
			this.isLoaded = false;
			await InternalProjectStore.GetOfferEvaluationInternalProject(id)
			this.isLoaded = true;
		}
    }
