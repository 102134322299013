



























	import { Component, Model } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { AdditionalCostsPresaleSectionView } from "@/services/iOrderService";
	
	@Component({})
	export default class PgaOfferEvaluationAdditionalCostsPresaleSubtotalsDetails extends mixins( SharedMixin )
	{
        @Model("", { type: Object }) readonly Model!: AdditionalCostsPresaleSectionView;

		get daysMen() : number
		{
			return this.Model.DaysMen;
		}

		get expectedCost() : number
		{
			return this.Model.ExpectedCost;
		}
	}
