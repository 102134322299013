



















































import Vue from "vue";
import { Component, Model} from "vue-property-decorator";
import { PgaValutazioneOffertaSubtotaliRisorseUmaneModel } from "../store";

@Component({
  computed: {
    ggUomoOff: {
      get() {
        return this.$props.Model.SubGGOfferta;
      }
    },
    ggUomoPrev: {
      get() {
        return this.$props.Model.SubGGUomoPrevisti;
      }
    },
    margineRischio: {
      get() {
        return this.$props.Model.SubMargineRischio;
      }
    },
    importoOfferta: {
      get() {
        return this.$props.Model.SubImportoOfferta;
      }
    },
    costoAtteso: {
      get() {
         return this.$props.Model.SubCostoAtteso;
      }
    },
    margineAtteso: {
      get() {
         return this.$props.Model.SubMargineAtteso;
      }
    },
    margineAttesoPerc: {
      get() {
         return this.$props.Model.SubMargineAttesoPerc;
      }
    }
  }
})
export default class PgaValutazioneOffertaSubtotaliRisorseUmane extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaSubtotaliRisorseUmaneModel;
}
