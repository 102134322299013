






















	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { BusinessStartupCustomerOrderViewTable } from "@/services/iOrderService";
    import { CustomerOrderStore } from "../../store";

	@Component({})
	export default class PgaBusinessStartupCustomerOrderTable extends mixins(SharedMixin)
	{
        private isLoading = false;
        private showClosed = false;
		private headers =
			[
                {
                    text: "ACOM",
                    align: 'start',
                    sortable: true,
					value: "ACOM",
                    keySearch: "ac",
				},
				{
                    text: "Customer",
                    align: 'start',
                    sortable: true,
					value: "Customer",
                    keySearch: "cm",
				},
                {
                    text: "Final Customer",
                    align: 'start',
                    sortable: true,
					value: "FinalCustomer",
                    keySearch: "fcn",
				},
				{
                    text: "Description",
                    align: 'start',
                    sortable: true,
					value: "Description",
                    keySearch: "de",
                },
                {
                    text: "Order Protocol",
                    align: 'start',
                    sortable: true,
					value: "OrderProtocol",
                    keySearch: "op",
                },
                {
                    text: "Contract Protocol",
                    align: 'start',
                    sortable: true,
					value: "ContractProtocol",
                    keySearch: "cp",
                },  
            ];

        private get customerOrderList() : Array<BusinessStartupCustomerOrderViewTable>
        {
            if(this.showClosed)
                return CustomerOrderStore.CustomerOrdersClosed;
            return CustomerOrderStore.CustomerOrders;
        }

        private async getCustomerOrdersClosed(value: boolean) : Promise<void>
        {
            if(!CustomerOrderStore.CustomerOrdersClosed.some(co => co) && value)
            {
                this.isLoading = true;
                await CustomerOrderStore.ListBusinessStartupCustomerOrdersClosed();
                this.isLoading = false;
            }
        }

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private details(item: any) : void
		{
            const id = item.Id;
			this.$router.push( { name: "businessstartupcustomerdetails", params: { id } } );
        }
        
        async mounted() : Promise<void>
        {
            this.isLoading = true;
            await CustomerOrderStore.ListBusinessStartupCustomerOrders();
            this.isLoading = false;
        }
	}
