
























import Vue from "vue";
import { Component,  Model,  } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { OfferServiceModule } from "../../store";
import { PgaValutazioneOffertaHeaderCreateModel } from "../store";
@Component({
    data: () => ({
        valid: "",
    }),
    computed: {
        TipologiaOffertaItems: {
            get() {
                return OfferServiceModule.getTIPOLOGIAOFFERTA;
            }
        },
        TipologiaOfferta: {
            get() {
                return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate.TipologiaOfferta;
            },
            set(value: string) {
                CreateOfferModule.SET_TIPOLOGIAOFFERTA(value);
            }
        },
        protocollo: {
            get() {
                return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate
                    .Protocollo;
            },
            set(value) {
                CreateOfferModule.SET_PROTOCOLLO(value);
            }
        },
        cliente: {
            get() {
                return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate
                    .Cliente;
            },
            set(value) {
                CreateOfferModule.SET_CLIENTE(value);
            }
        },
        descrizione: {
            get() {
                return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate
                    .Descrizione;
            },
            set(value) {
                CreateOfferModule.SET_DESCRIZIONE(value);
            }
        }
    }
})
export default class PgaValutazioneOffertaHeaderCreate extends Vue {
    @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaHeaderCreateModel;
}
