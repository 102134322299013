









































	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { InternalProjectStore } from '../../InternalProjectStore';
    import { OfferEvaluationInternalProjectTableView } from "@/services/iOrderService";
    import { getFileName } from "@/feature/Shared/store";

	@Component({})
	export default class PgaOfferEvaluationInnerProjectTable extends mixins(SharedMixin)
	{
        private isLoading = false;
        private showClosed = false;
		private headers =
			[
                {
                    text: "Title",
                    align: 'start',
                    sortable: true,
					value: "Title",
                    keySearch: "ti",
                    width: "70%",
				},
                {
                    text: "Expected Cost",
                    align: 'start',
                    sortable: true,
					value: "ExpectedCost",
                    keySearch: "ec",
				},
				{
                    text: "Date",
                    align: 'start',
                    sortable: true,
					value: "Date",
                    keySearch: "dt",
				}
            ];

        private get offerEvalutaionInnerProjectList() : Array<OfferEvaluationInternalProjectTableView>
        {
            if(this.showClosed)
                return InternalProjectStore.InternalProjectsClosed;
            return InternalProjectStore.InternalProjects;
        }

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private details(item: any) : void
		{
            const id = item.Id;
			this.$router.push( { name: "offerevaluationprojectdetails", params: { id, } } );
        }

        private async getInternalProjectsClosed() : Promise<void>
        {
            if(!InternalProjectStore.InternalProjectsClosed.some(co => co))
            {
                this.isLoading = true;
                await InternalProjectStore.ListOfferEvaluationInternalProjectsClosed();
                this.isLoading = false;
            }
        }

        private getFileName(fullFileName: string) : string
        {
            return getFileName(fullFileName);
        }

        private getFormattedDate(value: Date | string) : string
        {
            const date = new Date(value);
            return date.toLocaleDateString();
        }
        
        async mounted() : Promise<void>
        {
            this.isLoading = true;
            await InternalProjectStore.ListOfferEvaluationInternalProjects();
            this.isLoading = false;
        }
	}
