















	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { SectionStore } from "../../../SectionStore";
    import { EditFixedPriceProjectCommand, EditHumanResourcesCommand, EditOtherComponentsCommand } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalCostsPresaleCreate extends mixins( SharedMixin )
	{
        get editFixedPrice() : EditFixedPriceProjectCommand
        {
            return SectionStore.EditFixedPriceProject.Command;
        }
        get editHumanResources() : EditHumanResourcesCommand
        {
            return SectionStore.EditHumanResources.Command;
        }
        get editOtherComponents() : EditOtherComponentsCommand
        {
            return SectionStore.EditOtherComponents.Command;
        }

		// Total Costs Presale 
		get totalCostsPresale (): number
		{
			const total = this.expectedCostAdditional + this.expectedCostOther;
			this.$emit('presale', total);
			return total;
		}

		private get expectedCostAdditional() : number
		{
			if(SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.length > 0)
			{
				return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts
					.map(ac => ac.DaysMen * ac.DailyCost)
					.reduce((sum, current) => sum + current);
			}
			return 0;
		}

		private get expectedCostOther() : number
		{
			if(SectionStore.EditOtherCostsPresale.Command.OtherCosts.length > 0)
			{
				return SectionStore.EditOtherCostsPresale.Command.OtherCosts
					.map(oc => oc.ExpectedCost)
					.reduce((sum, current) => sum + current);
			}
			return 0;
		}
	}
