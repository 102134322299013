


















































	import { Component, Prop } from "vue-property-decorator";
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { SectionStore } from "../../../SectionStore";
	import { getFileName } from "@/feature/Shared/store";

	@Component( {} )
	export default class PgaOfferEvaluationAttachmentsCreate extends mixins(SharedMixin)
	{
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;
		
		get notes() : string
		{
			return SectionStore.EditAttachments.Command.Notes;
		}
		set notes(value: string)
		{
			SectionStore.SET_EDIT_ATTACHMENTS_NOTES(value);
		}

		get files() : Array<File>
		{
			return SectionStore.UploadFiles.Command.Files ?? new Array<File>();
		}
		set files(value: Array<File>)
		{
			SectionStore.SET_UPLOAD_FILES(value);
		}

		private getFile(index: number) : File
		{
			return this.files[index];
		}
		private setFile(value: File, index: number) : void
		{
			const file = value;
			if(file && !this.files.some(f => f.name === file.name))
				this.files.push(file);
			if(!value)
				this.files.splice(index, 1);
		}

		get filesLoaded() : Array<string>
		{
			return SectionStore.EditAttachments.Command.BlobsName ?? new Array<string>();
		}
		private removeDocument(fileName: string) : void
		{
			const filesNameLoaded = this.filesLoaded
				.filter(file => file !== fileName);
			SectionStore.SET_EDIT_ATTACHMENTS_FILES(filesNameLoaded);
		}

		private getFileName(fullFileName: string) : string
        {
            return getFileName(fullFileName);
        }

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditAttachments.Errors;
		}

		beforeDestroy() : void
        {
            SectionStore.SET_EDIT_ATTACHMENTS_ERRORS(new Map<string, Array<string>>());
            SectionStore.ResetEditAttachmentsSectionCommand();
        }
	}
