import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';
import store from '@/infrastructure/store';

import '@/App.container';
import pgaDiContainer from '@/App.container';

import IOrderService,
    {   ListOfferEvaluationInternalProject, OfferEvaluationInternalProjectTableView, OfferEvaluationInternalProjectDetailsView,
            BlobMetaDataView,
        CreateOfferEvaluationInternalProjectHeaderCommand, SaveOfferEvaluationInternalProjectCommand, EditOfferEvaluationInternalProjectHeaderCommand,
            ActiveOfferEvaluationInternalProjectCommand, RequestApprovalOfferEvaluationInternalProjectCommand, OfferEvaluationInternalProjectVersionView,
            EditDetailsInternalProjectCommand,
        CreateOfferEvaluationInternalProjectHeaderResponse, SaveOfferEvaluationInternalProjectResponse, EditOfferEvaluationInternalProjectHeaderResponse,
            OfferEvaluationSectionResponse,
        StatusOfferEvaluation } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';

import iIdentityService, { UserSimple } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';
        
import { SharedModule, parseErrors, deepCopy } from '@/feature/Shared/store';
import { SectionStore } from './SectionStore';

export interface InternalProjectsState
{
    InternalProjects: Array<OfferEvaluationInternalProjectTableView>;
    InternalProjectsClosed: Array<OfferEvaluationInternalProjectTableView>;
    InternalProjectDetails: OfferEvaluationInternalProjectDetailsView;
    CreateInternalProjectHeader: CreateInternalProjectHeaderModel;
    EditInternalProjectHeader: EditInternalProjectHeaderModel;
    EditDetailsInternalProject: EditDetailsInternalProjectModel;
    SaveInternalProject: SaveInternalProjectModel;

    ACOMDropdown: Array<UserSimple>;
}

export interface CreateInternalProjectHeaderModel
{
    Command: CreateOfferEvaluationInternalProjectHeaderCommand;
    Response: CreateOfferEvaluationInternalProjectHeaderResponse;
    Errors: Map<string, Array<string>>;
}

export interface EditInternalProjectHeaderModel
{
    Command: EditOfferEvaluationInternalProjectHeaderCommand;
    Response: EditOfferEvaluationInternalProjectHeaderResponse;
    Errors: Map<string, Array<string>>;
}

export interface SaveInternalProjectModel
{
    Command: SaveOfferEvaluationInternalProjectCommand;
    Response: SaveOfferEvaluationInternalProjectResponse;
    Errors: Map<string, Array<string>>;
}

export interface EditDetailsInternalProjectModel
{
    Command: EditDetailsInternalProjectCommand;
    CommandCopy: EditDetailsInternalProjectCommand | null;
    Response: OfferEvaluationSectionResponse;
    Errors: Map<string, Array<string>>;
}

@Module( { namespaced: true, dynamic: true, store, name: 'InternalProjectModule', } )
class InternalProjectsStore extends VuexModule implements InternalProjectsState
{
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>( OrderService );
    private identityService: iIdentityService = pgaDiContainer.get<iIdentityService>(IdentityService);

    //Table State
    public InternalProjects: Array<OfferEvaluationInternalProjectTableView> = new Array<OfferEvaluationInternalProjectTableView>();
    public InternalProjectsClosed: Array<OfferEvaluationInternalProjectTableView> = new Array<OfferEvaluationInternalProjectTableView>();

    //Details State
    public InternalProjectDetails: OfferEvaluationInternalProjectDetailsView =
    {
        Id: Guid.createEmpty(),
        Title: "",
        Date: new Date(),
        // Triad
        BusinessLine: "",
        TechArea: "",
        SupplyTipology: "", 
        // Approval
        ApprovalProjectDG: false,
        ApprovalSpendingBudgetDG: false,
        ReviewSG: false,
        // Exceeded Limits
        ExceedBudget: false,
        // Status
        Status: StatusOfferEvaluation.Draft,
        // Sections
        Details:
        {
            Description: "",
            DaysMenExpected: 0,
            ExpectedCost: 0,
            Market: false,
            InternalUse: true,
            ACOM: "",
        },
        Attachments:
        {
            Notes: "",
            Blobs: new Array<BlobMetaDataView>(),
        },
        Versions: new Array<OfferEvaluationInternalProjectVersionView>(),
    }

    // Create State
    public CreateInternalProjectHeader: CreateInternalProjectHeaderModel =
    {
        Command: 
        {
            Title: "",
            Date: new Date(),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    };

    // Edit State
    public EditInternalProjectHeader: EditInternalProjectHeaderModel =
    {
        Command: 
        {
            Id: Guid.createEmpty(),
            Title: "",
            Date: new Date(),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    };

    // Edit Details State
    public EditDetailsInternalProject: EditDetailsInternalProjectModel =
    {
        Command: 
        {
            Id: Guid.createEmpty(),
            Description: "",
            DaysMenExpected: 0,
            ExpectedCost: 0,
            Market: false,
            InternalUse: true,
            ACOMId: null,
        },
        CommandCopy: null,
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    };

    // Save State
    public SaveInternalProject: SaveInternalProjectModel =
    {
        Command:
        {
            Id: Guid.createEmpty(),
        },
        Response:
        {
            Id: Guid.createEmpty(),
        },
        Errors: new Map<string, Array<string>>(),
    }

    // ACOM Dropdown
    public ACOMDropdown = new Array<UserSimple>();
    @Action({ rawError: true })
    public async GetACOMDropdown(): Promise<void>
    {
        const acomDropdown: Array<UserSimple> = await this.identityService.GetACOMsWithDC();
        const ACOM_DC: UserSimple =
        {
            Id: SharedModule.UserId,
            FullName: SharedModule.Name.toUpperCase(),
        } 
        acomDropdown.push(ACOM_DC);
        this.SET_ACOM_DROPDOWN(acomDropdown);
    }
    @Mutation
    public SET_ACOM_DROPDOWN(acoms: Array<UserSimple>)
    {
        this.ACOMDropdown = acoms;
    }

    // INTERNALPROJECTS TABLE
    @Action( { rawError: true } )
    public async ListOfferEvaluationInternalProjects (): Promise<void>
    {
        const offers: ListOfferEvaluationInternalProject = await this.orderService.GetAllOfferEvaluationInternalProjects();
        this.SET_INTERNALPROJECTS_TABLE( offers.OfferEvaluationInternalProjects );
    }
    @Mutation
    public SET_INTERNALPROJECTS_TABLE ( offers: Array<OfferEvaluationInternalProjectTableView> )
    {
        this.InternalProjects = offers;
    }

    @Action( { rawError: true } )
    public async ListOfferEvaluationInternalProjectsClosed (): Promise<void>
    {
        const offersClosed: ListOfferEvaluationInternalProject = await this.orderService.GetAllOfferEvaluationInternalProjectsClosed();
        this.SET_INTERNALPROJECTS_CLOSED_TABLE( offersClosed.OfferEvaluationInternalProjects );
    }
    @Mutation
    public SET_INTERNALPROJECTS_CLOSED_TABLE ( offersClosed: Array<OfferEvaluationInternalProjectTableView> )
    {
        this.InternalProjectsClosed = offersClosed;
    }

    // INTERNALPROJECT DETAILS
    @Action( { rawError: true } )
    public async GetOfferEvaluationInternalProject (id: Guid): Promise<void>
    {
        const offer: OfferEvaluationInternalProjectDetailsView = await this.orderService.GetOfferEvaluationInternalProjectById(id);
        this.SET_INTERNALPROJECTS_DETAILS( offer );
    }
    @Mutation
    public SET_INTERNALPROJECTS_DETAILS ( offer: OfferEvaluationInternalProjectDetailsView )
    {
        this.InternalProjectDetails = offer;
    }
    
    // CREATE INTERNALPROJECTS
    @Action( { rawError: true } )
    public async CreateOfferEvaluationInternalProject (): Promise<CreateOfferEvaluationInternalProjectHeaderResponse>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.CreateOfferEvaluationInternalProjectHeader(this.CreateInternalProjectHeader.Command);
            SharedModule.SET_ISLOADING(false);
            this.SET_CREATE_INTERNALPROJECT_HEADER_RESPONSE(response);
            this.SET_CREATE_INTERNALPROJECT_HEADER_ERRORS(new Map<string, Array<string>>());
            this.SetCommandToEditInternalProjectHeader(response.Id);
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_CREATE_INTERNALPROJECT_HEADER_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetCreateInternalProjectCommand()
    {
        const emptyCommand: CreateOfferEvaluationInternalProjectHeaderCommand =
        {
            Title: "",
            Date: new Date(),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
        };
        this.SET_CREATE_INTERNALPROJECT_HEADER_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_COMMAND ( command: CreateOfferEvaluationInternalProjectHeaderCommand )
    {
        this.CreateInternalProjectHeader.Command = command;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_RESPONSE ( response: CreateOfferEvaluationInternalProjectHeaderResponse )
    {
        this.CreateInternalProjectHeader.Response = response;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_TITLE ( title: string )
    {
        this.CreateInternalProjectHeader.Command.Title = title;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_DATE ( date: Date )
    {
        this.CreateInternalProjectHeader.Command.Date = date;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_BUSINESSLINEID(businessLineId: Guid | null)
    {
        this.CreateInternalProjectHeader.Command.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_TECHAREAID(techAreaId: Guid | null)
    {
        this.CreateInternalProjectHeader.Command.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_SUPPLYTIPOLOGYID(supplyTipologyId: Guid | null)
    {
        this.CreateInternalProjectHeader.Command.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_CREATE_INTERNALPROJECT_HEADER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.CreateInternalProjectHeader.Errors = errors;
    }

     // EDIT INTERNALPROJECTS
    @Action( { rawError: true } )
    public async EditOfferEvaluationInternalProject (): Promise<EditOfferEvaluationInternalProjectHeaderResponse>
    {
        try
        {
            const response = await this.orderService.EditOfferEvaluationInternalProjectHeader(this.EditInternalProjectHeader.Command);
            this.SET_EDIT_INTERNALPROJECT_HEADER_RESPONSE(response);
            this.SET_EDIT_INTERNALPROJECT_HEADER_ERRORS(new Map<string, Array<string>>());
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_INTERNALPROJECT_HEADER_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async InitEditInternalProjectCommand(id: Guid) : Promise<void>
    {
        const internalProjectToEdit = await this.orderService.GetOfferEvaluationInternalProjectByIdToEdit(id);
        const headerCommand: EditOfferEvaluationInternalProjectHeaderCommand =
        {
            Id: internalProjectToEdit.Id,
            Title: internalProjectToEdit.Title,
            Date: internalProjectToEdit.Date,
            BusinessLineId: internalProjectToEdit.BusinessLineId,
            TechAreaId: internalProjectToEdit.TechAreaId,
            SupplyTipologyId: internalProjectToEdit.SupplyTipologyId,
        }
        this.SET_EDIT_INTERNALPROJECT_HEADER_COMMAND(headerCommand);
        this.SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND(internalProjectToEdit.Details);
        SectionStore.SET_EDIT_ATTACHMENTS_COMMAND(internalProjectToEdit.Attachments);

        // Setting commands copies
        this.SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND_COPY(deepCopy(internalProjectToEdit.Details));
        SectionStore.SET_EDIT_ATTACHMENTS_COMMAND_COPY(deepCopy(internalProjectToEdit.Attachments));
        SectionStore.SET_UPLOAD_FILES_ID(internalProjectToEdit.Id);
        this.SET_SAVE_INTERNALPROJECT_ID(internalProjectToEdit.Id);
    }
    @Action( { rawError: true } )
    public ResetEditInternalProjectCommand()
    {
        const emptyCommand: EditOfferEvaluationInternalProjectHeaderCommand =
        {
            Id: Guid.createEmpty(),
            Title: "",
            Date: new Date(),
            BusinessLineId: null,
            TechAreaId: null,
            SupplyTipologyId: null,
        };
        this.SET_EDIT_INTERNALPROJECT_HEADER_COMMAND(emptyCommand);
    }
    @Action( { rawError: true } )
    private SetCommandToEditInternalProjectHeader(id: Guid) : void
    {
        const createCommand = this.CreateInternalProjectHeader.Command;
        this.SET_EDIT_INTERNALPROJECT_HEADER_ID(id);
        this.SET_EDIT_INTERNALPROJECT_HEADER_TITLE(createCommand.Title);
        this.SET_EDIT_INTERNALPROJECT_HEADER_DATE(createCommand.Date);
        this.SET_EDIT_INTERNALPROJECT_HEADER_BUSINESSLINEID(createCommand.BusinessLineId);
        this.SET_EDIT_INTERNALPROJECT_HEADER_TECHAREAID(createCommand.TechAreaId);
        this.SET_EDIT_INTERNALPROJECT_HEADER_SUPPLYTIPOLOGYID(createCommand.SupplyTipologyId);
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_COMMAND ( command: EditOfferEvaluationInternalProjectHeaderCommand )
    {
        this.EditInternalProjectHeader.Command = command;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_RESPONSE ( response: EditOfferEvaluationInternalProjectHeaderResponse )
    {
        this.EditInternalProjectHeader.Response = response;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_ID ( id: Guid )
    {
        this.EditInternalProjectHeader.Command.Id = id;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_TITLE ( title: string )
    {
        this.EditInternalProjectHeader.Command.Title = title;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_DATE ( date: Date )
    {
        this.EditInternalProjectHeader.Command.Date = date;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_BUSINESSLINEID(businessLineId: Guid | null)
    {
        this.EditInternalProjectHeader.Command.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_TECHAREAID(techAreaId: Guid | null)
    {
        this.EditInternalProjectHeader.Command.TechAreaId = techAreaId;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_SUPPLYTIPOLOGYID(supplyTipologyId: Guid | null)
    {
        this.EditInternalProjectHeader.Command.SupplyTipologyId = supplyTipologyId;
    }
    @Mutation
    public SET_EDIT_INTERNALPROJECT_HEADER_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditInternalProjectHeader.Errors = errors;
    }

    // EDIT DETAILS INTERNALPROJECT
    @Action( { rawError: true } )
    public async EditDetailsInternalProjectAPI (): Promise<EditOfferEvaluationInternalProjectHeaderResponse>
    {
        try
        {
            const response = await this.orderService.EditDetailsInternalProject(this.EditDetailsInternalProject.Command);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_RESPONSE(response);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND_COPY(deepCopy(this.EditDetailsInternalProject.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public async EditDetailsInternalProjectAPIDraft (): Promise<EditOfferEvaluationInternalProjectHeaderResponse>
    {
        try
        {
            const response = await this.orderService.EditDetailsInternalProjectDraft(this.EditDetailsInternalProject.Command);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_RESPONSE(response);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_ERRORS(new Map<string, Array<string>>());
            this.SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND_COPY(deepCopy(this.EditDetailsInternalProject.Command));
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_EDIT_DETAILS_INTERNALPROJECT_ERRORS(errorParsed);
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetEditDetailsInternalProjectCommand()
    {
        const emptyCommand: EditDetailsInternalProjectCommand =
        {
            Id: Guid.createEmpty(),
            Description: "",
            DaysMenExpected: 0,
            ExpectedCost: 0,
            Market: false,
            InternalUse: true,
            ACOMId: null,
        };
        this.SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND ( command: EditDetailsInternalProjectCommand )
    {
        this.EditDetailsInternalProject.Command = command;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_COMMAND_COPY ( commandCopy: EditDetailsInternalProjectCommand | null )
    {
        this.EditDetailsInternalProject.CommandCopy = commandCopy;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_RESPONSE ( response: OfferEvaluationSectionResponse )
    {
        this.EditDetailsInternalProject.Response = response;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_ID ( id: Guid )
    {
        this.EditDetailsInternalProject.Command.Id = id;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_DESCRIPTION ( description: string )
    {
        this.EditDetailsInternalProject.Command.Description = description;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_DAYSMEN_EXPECTED ( daysMenExpected: number )
    {
        this.EditDetailsInternalProject.Command.DaysMenExpected = daysMenExpected;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_EXPECTEDCOST ( expectedCost: number )
    {
        this.EditDetailsInternalProject.Command.ExpectedCost = expectedCost;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_MARKET (market: boolean)
    {
        this.EditDetailsInternalProject.Command.Market = market;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_INTERNALUSE (internalUse: boolean)
    {
        this.EditDetailsInternalProject.Command.InternalUse = internalUse;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_ACOMID(acomId: Guid | null)
    {
        this.EditDetailsInternalProject.Command.ACOMId = acomId;
    }
    @Mutation
    public SET_EDIT_DETAILS_INTERNALPROJECT_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.EditDetailsInternalProject.Errors = errors;
    }


    // SAVE INTERNALPROJECT
    @Action( { rawError: true } )
    public async SaveOfferEvaluationInternalProject (): Promise<SaveOfferEvaluationInternalProjectResponse>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            const response = await this.orderService.SaveOfferEvaluationInternalProject(this.SaveInternalProject.Command);
            SharedModule.SET_ISLOADING(false);
            this.SET_SAVE_INTERNALPROJECT_ERRORS(new Map<string, Array<string>>());
            return response;
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            this.SET_SAVE_INTERNALPROJECT_ERRORS(errorParsed);
            SharedModule.SET_ALERT_ERROR_MESSAGE(errorParsed.get('id')?.[0] ?? "");
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            errorParsed
            throw errorParsed;
        }
    }
    @Action( { rawError: true } )
    public ResetSaveInternalProjectCommand()
    {
        const emptyCommand: SaveOfferEvaluationInternalProjectCommand =
        {
            Id: Guid.createEmpty(),
        };
        this.SET_SAVE_INTERNALPROJECT_COMMAND(emptyCommand);
    }
    @Mutation
    public SET_SAVE_INTERNALPROJECT_COMMAND ( command: SaveOfferEvaluationInternalProjectCommand )
    {
        this.SaveInternalProject.Command = command;
    }
    @Mutation
    public SET_SAVE_INTERNALPROJECT_ID ( id: Guid )
    {
        this.SaveInternalProject.Command.Id = id;
    }
    @Mutation
    public SET_SAVE_INTERNALPROJECT_ERRORS ( errors: Map<string,Array<string>> )
    {
        this.SaveInternalProject.Errors = errors;
    }

    @Action( { rawError: true } )
    public async DeleteInternalProject() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            await this.orderService.DeleteOfferEvaluationInternalProject(internalProject.Id);
            SharedModule.SET_ISLOADING(false);
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errorParsed = parseErrors(error);
            SharedModule.SET_ALERT_ERROR_MESSAGE(errorParsed.get('id')?.join('<br> - ') ?? "");
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            throw errorParsed;
        }
    }

    @Action( { rawError: true } )
    public async ActiveInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            const cmd: ActiveOfferEvaluationInternalProjectCommand =
            {
                Id: internalProject.Id,
            };
            const response = await this.orderService.ActiveOfferEvaluationInternalProject(cmd);

            if(response.WarningMessages.length > 0)
            {
                const msg = response.WarningMessages.join("<br>");
                SharedModule.SET_ALERT_WARNING_MESSAGE(msg);
                internalProject.Status = StatusOfferEvaluation.ApprovalRequestable;
            }
            else
            {
                internalProject.Status = StatusOfferEvaluation.Activating;
            }
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async RequestApprovalInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            const cmd: RequestApprovalOfferEvaluationInternalProjectCommand =
            {
                Id: internalProject.Id,
            };
            await this.orderService.RequestApprovalOfferEvaluationInternalProject(cmd);
            internalProject.Status = StatusOfferEvaluation.Activating;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async ApproveInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            await this.orderService.ApproveOfferEvaluationInternalProject(internalProject.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async CreateAssessmentInternalProject() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const response = await this.orderService.CreateAssessmentOfferEvaluationInternalProject(this.InternalProjectDetails.Id);
            await this.GetOfferEvaluationInternalProject(response.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async NotifyAssessmentInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            const response = await this.orderService.NotifyAssessmentOfferEvaluationInternalProject(internalProject.Id);

            if(response.WarningMessages.length > 0)
            {
                const msg = response.WarningMessages.join("<br>");
                SharedModule.SET_ALERT_WARNING_MESSAGE(msg);
                internalProject.Status = StatusOfferEvaluation.ApprovalRequestable;
            }
            else
            {
                internalProject.Status = StatusOfferEvaluation.Activating;
            }
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async RequestApprovalAssessmentInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            await this.orderService.RequestApprovalAssessmentOfferEvaluationInternalProject(internalProject.Id);
            internalProject.Status = StatusOfferEvaluation.Activating;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    @Action( { rawError: true } )
    public async ApproveAssessmentInternalProjectCommand() : Promise<void>
    {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.InternalProjectDetails.Id == null)
                return Promise.reject("Details Offer Evaluation not initialized, try reload the application");

            const internalProject = this.InternalProjectDetails;
            await this.orderService.ApproveAssessmentOfferEvaluationInternalProject(internalProject.Id);
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

export const InternalProjectStore = getModule( InternalProjectsStore );