













	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaBusinessStartupInternalProjectDetails from './components/details/PgaBusinessStartupInternalProjectDetails.vue';
    import { InternalProjectStore } from "./store";

	@Component( {
		components:
		{
			PgaBusinessStartupInternalProjectDetails,
		},
	} )
    export default class BusinessStartupPageInternalProjectDetails extends mixins( SharedMixin )
    {
		private isLoaded = false;

		async mounted() : Promise<void>
		{
			const id: Guid = Guid.parse(this.$route.params.id);
			this.isLoaded = false;
			await InternalProjectStore.GetInternalProjectsById(id);
			this.isLoaded = true;
		}
    }
