





































































	import { Component, Prop } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { TotalAmountsDiscountedSectionView, TotalAmountsSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalAmountsDiscountedDetails extends mixins( SharedMixin )
	{
        @Prop({ type: Object, default: function(){ return {} } }) readonly totalAmounts!: TotalAmountsSectionView;
		@Prop({ type: Object, default: function(){ return {} } }) readonly totalAmountsDiscounted!: TotalAmountsDiscountedSectionView;

        // Discount
        get discount() : number
        {
            return this.totalAmounts.Discount;
        }

        // Total Amounts Discounted
        get offerAmountsDiscounted (): number
		{
			return this.totalAmountsDiscounted.OfferAmounts;
		}

		get expectedCostsDiscounted (): number
		{
            return this.totalAmountsDiscounted.ExpectedCosts;
		}

        get expectedMarginsDiscounted (): number
		{
			return this.totalAmountsDiscounted.ExpectedMargins;
		}

        get expectedMarginsDiscountedPerc (): string
		{
			return this.totalAmountsDiscounted.ExpectedMarginsPercentage.toFixed(2);
		}
	}
