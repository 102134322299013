











	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaOfferEvaluationCustomerOrderEdit from './components/edit/PgaOfferEvaluationCustomerOrderEdit.vue';
	
	import { Route, Next } from "vue-router";
	import { SectionStore } from "./SectionStore";
	import { deepComparison, SharedModule } from "@/feature/Shared/store";

	@Component( {
		components:
		{
			PgaOfferEvaluationCustomerOrderEdit,
		},
	} )
    export default class OfferEvaluationPageCustomerOrderEdit extends mixins( SharedMixin )
    {
		private isLoaded = false;

        private undo()
        {
            this.$router.go(-1);
        }

		private get editRestricted() : boolean
        {
            return !SharedModule.Permissions.includes('EditOfferEvaluationCustomerOrder')
                && SharedModule.Permissions.includes('EditOfferEvaluationCustomerOrderRestricted');
        }

		private anyChangesSection() : boolean
		{
			const anyChangesFixedPrice = !deepComparison(SectionStore.EditFixedPriceProject.Command, SectionStore.EditFixedPriceProject.CommandCopy);
			const anyChangesHumanResources = !deepComparison(SectionStore.EditHumanResources.Command, SectionStore.EditHumanResources.CommandCopy);
			const anyChangesOtherComponents = !deepComparison(SectionStore.EditOtherComponents.Command, SectionStore.EditOtherComponents.CommandCopy);
			const anyChangesAdditionalCostsPresale = !deepComparison(SectionStore.EditAdditionalCostsPresale.Command, SectionStore.EditAdditionalCostsPresale.CommandCopy);
			const anyChangesOtherCostsPresale = !deepComparison(SectionStore.EditOtherCostsPresale.Command, SectionStore.EditOtherCostsPresale.CommandCopy);
			const anyChangesAdditionalCostsPostsale = !deepComparison(SectionStore.EditAdditionalCostsPostsale.Command, SectionStore.EditAdditionalCostsPostsale.CommandCopy);
			const anyChangesAttachments = !deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy);
			return anyChangesFixedPrice || anyChangesHumanResources 
				|| anyChangesOtherComponents || anyChangesAdditionalCostsPresale
				|| anyChangesOtherCostsPresale || anyChangesAdditionalCostsPostsale
				|| anyChangesAttachments;
		}

		async mounted() : Promise<void>
        {
			this.isLoaded = false;
			await Promise.all([
				SectionStore.GetBusinessLinesDropdown(),
				SectionStore.GetTechAreasDropdown(),
				SectionStore.GetSupplyTipologiesDropdown(),
				SectionStore.GetSolutionProductsDropdown(),
				SectionStore.GetUsersDropdown(),
				SectionStore.GetProfessionalsDropdown()
			]);
			this.isLoaded = true;
        }

		beforeRouteLeave(to: Route, from: Route, next: Next) : void
		{
			if(this.anyChangesSection())
			{
				const answer = window.confirm("Do you really want to leave? There are unsaved changes!");
				if (answer)
				{
					next();
				}
			}
			else
			{
				next();
			}
		}
    }
