





















































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { DeliverablesModel, ProjectRequirementsReviewSectionView } from "@/services/iOrderService";

@Component({})
export default class PgaProjectRequirementsReviewDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: ProjectRequirementsReviewSectionView;

	get description() : string
	{
		return this.Model.Description;
	}

	get startDate() : Date
	{
		return this.Model.StartDate;
	}

	get expectedEndDate() : Date
	{
		return this.Model.ExpectedEndDate;
	}

	get businessLine() : string
	{
		return this.Model.BusinessLine;
	}

	get techArea() : string
	{
		return this.Model.TechArea;
	}

	get supplyTipology() : string
	{
		return this.Model.SupplyTipology;
	}

	get deliverables() : Array<DeliverablesModel>
	{
		return this.Model.Deliverables;
	}

	get areaManager() : string
	{
		return this.Model.AreaManager;
	}

	get notes() : string
	{
		return this.Model.Notes;
	}
}
