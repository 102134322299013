




























































































    import { Component, Prop } from 'vue-property-decorator';
    import { clearTimeout, setTimeout } from 'timers';
    
    import { mixins } from "vue-class-component";
    import SharedMixin from "@/mixins/SharedMixin";
    
    import { CustomerOrderStore } from "../../../CustomerOrderStore";
    
    import { EditOfferEvaluationCustomerOrderHeaderCommand, OrderTipology } from "@/services/iOrderService";
    import { SharedModule } from "@/feature/Shared/store";
    import { SectionStore } from '@/feature/Offerte/OfferEvaluation/SectionStore';

    @Component({})
    export default class PgaOfferEvaluationHeaderCustomerOrderEdit extends mixins(SharedMixin)
    {
        @Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

        private dropdownLoading = false;
        private showDynamicDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly orderTipology : Array<any> = 
        [
            {
                Name: 'New',
                Value: 1,
            },
            {
                Name: 'Renewal',
                Value: 2,
            }
        ];

        get commandHeader() : EditOfferEvaluationCustomerOrderHeaderCommand
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command;
        }

        get acom() : string
        {
            return SharedModule.Account?.name ?? "";
        }

        get customerName() : string
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.CustomerName;
        }
        set customerName(value: string)
        {
            this.showDynamicDropdown = false;
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_CUSTOMERNAME(value);
        }
        private setCustomerNameManually(value: string)
		{
			CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_CUSTOMERNAME(value);

            // cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.customersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestCustomers(value);
                }, 500);
                this.showDynamicDropdown = true;
            }
            else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

        get offerDescription() : string
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.Description;
        }
        set offerDescription(value: string)
        {
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_DESCRIPTION(value);
        }

        get protocol() : string
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.OrderProtocol;
        }
        set protocol(value: string)
        {
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_PROTOCOL(value);
        }

        get type() : OrderTipology
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.Type;
        }
        set type(value: OrderTipology)
        {
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_ORDERTYPE(value)
        }

        get masterAgreement() : boolean
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.MasterAgreement;
        }
        set masterAgreement(value: boolean)
        {
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_MASTERAGREEMENT(value);
        }

        // CRM
        get crmDealId() : string
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.CRMDealId;
        }

        get crmAmount() : number
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.CRMAmount;
        }

        get crmLastUpdate() : Date
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Command.CRMLastUpdate;
        }

        get customersDropdown() : Array<string>
		{
			return SectionStore.CustomersDropdown.map(c => c.BusinessName);
		}

        private async requestCustomers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await SectionStore.GetCustomersDropdown(search);
			this.dropdownLoading = false;
		}

        get Errors() : Map<string, Array<string>>
        {
            return CustomerOrderStore.EditCustomerOrderHeader.Errors;
        }

        beforeDestroy() : void
        {
            CustomerOrderStore.SET_EDIT_CUSTOMERORDER_HEADER_ERRORS(new Map<string, Array<string>>());
            CustomerOrderStore.ResetEditCustomerOrderCommand();
        }
    }
