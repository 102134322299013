








































import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaOfferEvaluationCustomerOrderTable from './components/tables/PgaOfferEvaluationCustomerOrderTable.vue';
import PgaOfferEvaluationInnerProjectTable from './components/tables/PgaOfferEvaluationInnerProjectTable.vue';
import { SharedModule } from "@/feature/Shared/store";

@Component( {
	components:
	{
		PgaOfferEvaluationCustomerOrderTable,
		PgaOfferEvaluationInnerProjectTable,
	}
} )
export default class OfferEvaluationPageIndex extends mixins( SharedMixin )
{
	get canReadCutomerOrdersTable() : boolean
	{
		const permissionsRequested = ["ReadOfferEvaluationCustomerOrder", "ReadOfferEvaluationCustomerOrderRestricted"];
		return SharedModule.Permissions.some(p => permissionsRequested.includes(p));
	}

	get canReadInternalProjectsTable() : boolean
	{
		return SharedModule.Permissions.includes("ReadOfferEvaluationInternalProject");
	}

	private NavigateReassignmentACOM() : void
	{
		this.$router.push( { name: "offerevaluationcustomerreassignacom" } );
	}
}
