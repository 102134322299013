



















































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { OfferServiceModule } from "../../store";
import { PgaValutazioneOffertaPostsaleCreateModel } from "../store";

@Component({
  data: () => ({
  }),
  computed: {
    DescrizionePostsale: {
      get() {
        return this.$props.Model.DescrizionePostsale;
      },
      set(value: string) {
        CreateOfferModule.SET_DESCRIZIONEPOSTSALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    CostoAttesoPostsale: {
      get() {
        return this.$props.Model.CostoAttesoPostsale;
      },
      set(value: number) {
        CreateOfferModule.UpdateCostoAttesoPostsale({
          index: this.$props.index,
          value: parseFloat((value).toString())
        });
      }
    },
    TipologiaPostsaleItems: {
      get() {
        return OfferServiceModule.getPOSTSALE;
      }
    },
    TipologiaPostsale: {
      get() {
        return this.$props.Model.TipologiaPostsale;
      },
      set(value: string) {
        CreateOfferModule.SET_TIPOLOGIAPOSTSALE({
          index: this.$props.index,
          value: value
        }); 
      }
    },
  }
})
export default class PgaValutazioneOffertaPostsaleCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaPostsaleCreateModel;

  @Emit()
  removePostsale(n: number) {
    CreateOfferModule.REMOVE_POSTSALE(this.index);
  }
}
