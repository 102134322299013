





































































































































































	import { Component, Prop } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';
	import { clearTimeout, setTimeout } from 'timers';
	
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { TechAreaModel, TipologiaFornituraModel, BusinessLineTableView, SolutionProductModel, SupplierView } from '@/services/iOrderService';
	import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";

	@Component({})
	export default class PgaOfferEvaluationOtherComponentsProductCreate extends mixins(SharedMixin)
	{
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;
		
		private dropdownLoading = false;
		private showDynamicDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

		get errorSubSection() : string
		{
			let color = "";
			this.Errors.forEach((value, key) =>
			{
				if(key.includes("ProductsPurchase"))
					color = "error";
			});
			return color;
		}

		//Details Other Components: first row
		get businessLine() : Guid | null
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.BusinessLineId;
		}
		set businessLine(value: Guid | null)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_TECHAREA_ID(null);
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_BUSINESSLINE_ID(value);
		}

		get techArea() : Guid | null
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.TechAreaId;
		}
		set techArea(value: Guid | null)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_TECHAREA_ID(value);
		}

		get supplyTipology() : Guid | null
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.SupplyTipologyId;
		}
		set supplyTipology(value: Guid | null)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLYTIPOLOGY_ID(value);
		}

		get componentTipology() : string
		{
			return "PRODUCTS PURCHASE";
		}

		get supplierCode() : string
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.SupplierCode;
		}
		set supplierCode(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERCODE(value);
		}

		get supplierBusinessName() : string
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.SupplierBusinessName;
		}
		set supplierBusinessName(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERBUSINESSNAME(value);
		}

		get supplier() : SupplierView | null
		{
			return this.suppliersDropdown.find(s => s.Code === this.supplierCode) ?? null;
		}
		set supplier(value: SupplierView | null)
		{
			this.showDynamicDropdown = false;
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERCODE(value?.Code ?? "");
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_SUPPLIERBUSINESSNAME(value?.BusinessName ?? "");
		}
		private requestSuppliersDebounced(value: string)
		{
			// cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 
				&& !this.suppliersDropdown.some(s => s.BusinessName == value)
				&& this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestSuppliers(value);
                }, 500);
				this.showDynamicDropdown = true;
            }
			else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

		get productCatalog() : Guid | null
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.ProductCatalogId;
		}
		set productCatalog(value: Guid | null)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_PRODUCTCATALOG_ID(value);
		}

		get description() : string
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.Description;
		}
		set description(value: string)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_DESCRIPTION(value);
		}
		
		get offerAmount() : number
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.OfferAmount;
		}
		set offerAmount(value: number)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_OFFERAMOUNT(value);
		}

		get expectedCost() : number
		{
			return SectionStore.EditOtherComponents.Command.ProductsPurchase.ExpectedCost;
		}
		set expectedCost(value: number)
		{
			SectionStore.SET_EDIT_OTHERCOMPONENTS_PRODUCT_EXPECTEDCOST(value);
		}

		get expectedMargin() : number
		{
			return this.offerAmount - this.expectedCost;
		}

		get expectedMarginPerc() : string
		{
			if(this.offerAmount == 0)
				return "0";
			return ((this.expectedMargin / this.offerAmount) * 100).toFixed(2);
		}

		//Dropdown Lists
		get businessLineDropdown() : Array<BusinessLineTableView>
		{
			return SectionStore.BusinessLinesDropdown;
		}
		get techAreaDropdown() : Array<TechAreaModel>
		{		
			return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		}
		get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		{
			return SectionStore.SupplyTipologiesDropdown;
		}
		get productCatalogDropdown() : Array<SolutionProductModel>
		{
			return SectionStore.SolutionProductsDropdown.filter(sp => sp.Typology.toLowerCase() === "product");
		}
		get suppliersDropdown() : Array<SupplierView>
		{
			return SectionStore.SuppliersDropdown;
		}

		private async requestSuppliers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await SectionStore.GetSuppliersDropdown(search);
			this.dropdownLoading = false;
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditOtherComponents.Errors;
		}
	}
