



































































    import { Component } from "vue-property-decorator";
    import { Guid } from "guid-typescript";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { InternalProjectStore } from "../../InternalProjectStore";
    import { SectionStore } from "../../SectionStore";

    import { SharedModule, deepComparison, IsMobile } from "@/feature/Shared/store";
    
    // Header
    import PgaOfferEvaluationHeaderInternalProjectCreate from "./sub-components/PgaOfferEvaluationHeaderInternalProjectCreate.vue";
    import PgaOfferEvaluationHeaderInternalProjectEdit from "../edit/sub-components/PgaOfferEvaluationHeaderInternalProjectEdit.vue";
    // Details Internal Project
    import PgaOfferEvaluationDetailsInternalProjectCreate from "./sub-components/PgaOfferEvaluationDetailsInternalProjectCreate.vue";
    // Attachments
    import PgaOfferEvaluationAttachmentsCreate from "./sub-components/PgaOfferEvaluationAttachmentsCreate.vue";

	@Component( {
        components:
        {
            PgaOfferEvaluationHeaderInternalProjectCreate,
            PgaOfferEvaluationHeaderInternalProjectEdit,

            PgaOfferEvaluationDetailsInternalProjectCreate,

            PgaOfferEvaluationAttachmentsCreate,
        }
    })
    export default class PgaOfferEvaluationInternalProjectCreate extends mixins(SharedMixin)
    {
        private readonly internalProjectResource = "internalproject";
        private offerEvaluationInternalProjectId : Guid | null = null;
        private isMiniVariant = this.isMobile;

        get isMobile() : boolean
        {
            return IsMobile(this.$vuetify.breakpoint.name);
        }

        get panelsOpened() : Array<number>
        {
            if(this.offerEvaluationInternalProjectId === null)
                return [];
            return [0, 1, 2];
        }

        // Errors check
        get hasErrorDetailsInternalProject() : boolean
        {
            return InternalProjectStore.EditDetailsInternalProject.Errors.size > 0;
        }
        get hasErrorsAttachments() : boolean
        {
            return SectionStore.EditAttachments.Errors.size > 0;
        }

        private async SaveAsDraft() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await InternalProjectStore.EditOfferEvaluationInternalProject();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(InternalProjectStore.EditDetailsInternalProject.Command, InternalProjectStore.EditDetailsInternalProject.CommandCopy))
                    await InternalProjectStore.EditDetailsInternalProjectAPIDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy))
                    await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);

            if(allSaved)
            {
                const id = this.offerEvaluationInternalProjectId?.toString() ?? "";
                this.$router.replace({ name: "offerevaluationprojectdetails", params: { id } })
            }
        }

        private async Save() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await InternalProjectStore.EditOfferEvaluationInternalProject();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await InternalProjectStore.EditDetailsInternalProjectAPI();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);

            if(allSaved)
            {
                await InternalProjectStore.SaveOfferEvaluationInternalProject();
                const id = this.offerEvaluationInternalProjectId?.toString() ?? "";
                this.$router.replace({ name: "offerevaluationprojectdetails", params: { id } })
            }
        }

        private undo() : void
        {
            this.$router.go(-1);
        }

        private async createHeader() : Promise<void>
        {
            this.offerEvaluationInternalProjectId = (await InternalProjectStore.CreateOfferEvaluationInternalProject()).Id;
            SectionStore.SetOfferEvaluationIdToSections(this.offerEvaluationInternalProjectId);
            InternalProjectStore.SET_SAVE_INTERNALPROJECT_ID(this.offerEvaluationInternalProjectId);
            InternalProjectStore.SET_EDIT_DETAILS_INTERNALPROJECT_ID(this.offerEvaluationInternalProjectId);
        }

        mounted() : void
        {
            SectionStore.SET_OFFER_EVALUATION_TYPE(this.internalProjectResource);
        }

        beforeDestroy() : void
		{
            SectionStore.ResetEditAttachmentsSectionCommand();
            InternalProjectStore.ResetEditDetailsInternalProjectCommand();
            InternalProjectStore.ResetSaveInternalProjectCommand();
		}
    }
