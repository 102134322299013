



















import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { OtherCostsPresaleSectionView } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationOtherCostsPresaleSubtotalsDetails extends mixins( SharedMixin )
{
	@Model("", { type: Object }) readonly Model!: OtherCostsPresaleSectionView;

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}
}
