






























import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessStartupCustomerOrderTable from './customer-orders/components/table/PgaBusinessStartupCustomerOrderTable.vue';
import PgaBusinessStartupInternalProjectTable from './internal-projects/components/table/PgaBusinessStartupInternalProjectTable.vue';
import { SharedModule } from "@/feature/Shared/store";

@Component( {
	components:
	{
		PgaBusinessStartupCustomerOrderTable,
		PgaBusinessStartupInternalProjectTable,
	}
} )
export default class BusinessStartupPageIndex extends mixins( SharedMixin )
{
	get canReadCutomerOrdersTable() : boolean
	{
		const permissionsRequested = ["ReadBusinessStartupCustomerOrder", "ReadBusinessStartupCustomerOrderRestricted"];
		return SharedModule.Permissions.some(p => permissionsRequested.includes(p));
	}

	get canReadInternalProjectsTable() : boolean
	{
		return SharedModule.Permissions.includes("ReadBusinessStartupInternalProject");
	}
}
