











	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import PgaOfferEvaluationInternalProjectCreate from "./components/create/PgaOfferEvaluationInternalProjectCreate.vue";

	import { Next, Route } from "vue-router";
	import { SectionStore } from "./SectionStore";
	import { deepComparison } from "@/feature/Shared/store";
	import { InternalProjectStore } from "./InternalProjectStore";

	@Component( {
		components:
		{
			PgaOfferEvaluationInternalProjectCreate,
		}
	} )
    export default class OfferEvaluationPageInnerProjectCreate extends mixins( SharedMixin )
    {
		private isLoaded = false;

        private undo()
        {
            this.$router.go(-1);
        }

		private anyChangesSection() : boolean
		{
			const anyChangesDetails = !deepComparison(InternalProjectStore.EditDetailsInternalProject.Command, InternalProjectStore.EditDetailsInternalProject.CommandCopy);
			const anyChangesAttachments = !deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy);
			return anyChangesAttachments || anyChangesDetails;
		}

		async mounted() : Promise<void>
        {
			this.isLoaded = false;
            await Promise.all([
				SectionStore.GetBusinessLinesDropdown(),
				SectionStore.GetTechAreasDropdown(),
				SectionStore.GetSupplyTipologiesDropdown(),
				InternalProjectStore.GetACOMDropdown(),
			]);
			this.isLoaded = true;
        }

		beforeRouteLeave(to: Route, from: Route, next: Next) : void
		{
			if(this.anyChangesSection())
			{
				const answer = window.confirm("Do you really want to leave? There are unsaved changes!");
				if (answer)
				{
					next();
				}
			}
			else
			{
				next();
			}
		}
    }
