











	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaOfferEvaluationCustomerOrderCreate from './components/create/PgaOfferEvaluationCustomerOrderCreate.vue';
	
	import { Route, Next } from "vue-router";
	import { SectionStore } from "./SectionStore";
	import { deepComparison, SharedModule } from "@/feature/Shared/store";
	import { CustomerOrderStore } from "./CustomerOrderStore";

	@Component( {
		components:
		{
			PgaOfferEvaluationCustomerOrderCreate,
		},
	} )
    export default class OfferEvaluationPageCustomerOrderCreate extends mixins( SharedMixin )
    {
		private isLoaded = false;
		private checkCustomerOrderByCRM = false;

        private undo()
        {
            this.$router.go(-1);
        }

		private anyChangesSection() : boolean
		{
			const anyChangesFixedPrice = !deepComparison(SectionStore.EditFixedPriceProject.Command, SectionStore.EditFixedPriceProject.CommandCopy);
			const anyChangesHumanResources = !deepComparison(SectionStore.EditHumanResources.Command, SectionStore.EditHumanResources.CommandCopy);
			const anyChangesOtherComponents = !deepComparison(SectionStore.EditOtherComponents.Command, SectionStore.EditOtherComponents.CommandCopy);
			const anyChangesAdditionalCostsPresale = !deepComparison(SectionStore.EditAdditionalCostsPresale.Command, SectionStore.EditAdditionalCostsPresale.CommandCopy);
			const anyChangesOtherCostsPresale = !deepComparison(SectionStore.EditOtherCostsPresale.Command, SectionStore.EditOtherCostsPresale.CommandCopy);
			const anyChangesAdditionalCostsPostsale = !deepComparison(SectionStore.EditAdditionalCostsPostsale.Command, SectionStore.EditAdditionalCostsPostsale.CommandCopy);
			const anyChangesAttachments = !deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy);
			return anyChangesFixedPrice || anyChangesHumanResources 
				|| anyChangesOtherComponents || anyChangesAdditionalCostsPresale
				|| anyChangesOtherCostsPresale || anyChangesAdditionalCostsPostsale
				|| anyChangesAttachments;
		}

		async mounted() : Promise<void>
        {
			if(!SectionStore.CRMDeal.Id)
			{
				const errorMessage =
					"Cannot create Proposal Evaluation External Project, before you must select a Deal from CRM";
				SharedModule.SET_ALERT_IS_VISIBLE(true);
				SharedModule.SET_ALERT_ERROR_MESSAGE(errorMessage);
				this.$router.go(-1);
			}
			else
			{
				try
				{
					this.checkCustomerOrderByCRM = await CustomerOrderStore.CheckIfExistsCustomerOrderByCRMDealId();
				}
				catch
				{
					this.$router.go(-1);
				}
			}
			this.isLoaded = false;
			await Promise.all([
				SectionStore.GetBusinessLinesDropdown(),
				SectionStore.GetTechAreasDropdown(),
				SectionStore.GetSupplyTipologiesDropdown(),
				SectionStore.GetSolutionProductsDropdown(),
				SectionStore.GetUsersDropdown(),
				SectionStore.GetProfessionalsDropdown()
			]);
			SectionStore.InitValuesUsingCRMDeal();
			this.isLoaded = true;
        }

		beforeDestroy() : void
		{
			SectionStore.ResetCRMDeal();
		}

		beforeRouteLeave(to: Route, from: Route, next: Next) : void
		{
			if(this.checkCustomerOrderByCRM && SectionStore.CRMDeal.Id && this.anyChangesSection())
			{
				const answer = window.confirm("Do you really want to leave? There are unsaved changes!");
				if (answer)
				{
					next();
				}
			}
			else
			{
				next();
			}
		}
    }
