













































	import { Component, Prop } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { TotalAmountsSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalAmountsDetails extends mixins( SharedMixin )
	{
        @Prop({ type: Object, default: function(){ return {} } }) readonly totalAmounts!: TotalAmountsSectionView;

        // Total Amounts 
		get offerAmounts (): number
		{
			return this.totalAmounts.OfferAmounts;
		}

		get expectedCosts (): number
		{
            return this.totalAmounts.ExpectedCosts;
		}

        get expectedMargins (): number
		{
			return this.totalAmounts.ExpectedMargins;
		}

        get expectedMarginsPerc (): string
		{
			return this.totalAmounts.ExpectedMarginsPercentage.toFixed(2);
		}
	}
