


























































































































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { DeliverablesModel, OrderRequirementsReviewSectionView } from "@/services/iOrderService";

@Component({})
export default class PgaOrderRequirementsReviewDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: OrderRequirementsReviewSectionView;
	
	get ACOM() : string
	{
		return this.Model.ACOM;
	}

	get customer() : string
	{
		return this.Model.Customer;
	}

	get finalCustomer() : string
	{
		return this.Model.FinalCustomer;
	}

	get description() : string
	{
		return this.Model.Description;
	}

	get advanceSupply() : boolean
	{
		return this.Model.AdvanceSupply;
	}

	get startDate() : Date
	{
		return this.Model.StartDate;
	}

	get expectedEndDate() : Date
	{
		return this.Model.ExpectedEndDate;
	}

	get place() : string
	{
		if(this.Model.Place === 1)
			return "Proge";
		else
			return "Customer";
	}

	get address() : string
	{
		return this.Model.Address ?? "";
	}

	get customerContractManager() : string
	{
		return this.Model.CustomerContractManager;
	}

	get otherCustomerReferrals() : Array<string>
	{
		return this.Model.OtherCustomerReferrals;
	}

	get businessLine() : string
	{
		return this.Model.BusinessLine;
	}

	get techArea() : string
	{
		return this.Model.TechArea;
	}

	get supplyTipology() : string
	{
		return this.Model.SupplyTipology;
	}

	get deliverables() : Array<DeliverablesModel>
	{
		return this.Model.Deliverables;
	}

	get areaManager() : string
	{
		return this.Model.AreaManager;
	}

	get privacy() : boolean
	{
		return this.Model.Privacy;
	}

	get notes() : string
	{
		return this.Model.Notes;
	}
}
