




























import { Component, Prop } from 'vue-property-decorator';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { CustomerOrderStore } from '../../../store';

@Component({})
export default class PgaDeliverableEdit extends mixins(SharedMixin)
{
    @Prop({ default: -1, type: Number}) index!: number;

    get deliverableName() : string
    {
        return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Deliverables[this.index]?.DeliverableName ?? "";
    }
    set deliverableName(value: string)
    {
        CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES_NAME({ index: this.index, deliverableName: value });
    }

    get deliveryBy() : Date
    {
        return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Deliverables[this.index]?.DeliveryBy ?? new Date();
    }
    set deliveryBy(value: Date)
    {
        CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES_DATE({ index: this.index, deliveryBy: value });
    }

    private removeDeliverable() : void
    {
        CustomerOrderStore.REMOVE_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES(this.index);
    }
}
