































































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { ComponentDetailsView } from '@/services/iOrderService';

@Component({})
export default class PgaOfferEvaluationOtherComponentsOthersDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: ComponentDetailsView;

	//Details Other Components: first row
	get businessLine() : string
	{
		return this.Model.BusinessLine;
	}

	get techArea() : string
	{
		return this.Model.TechArea;
	}

	get supplyTipology() : string
	{
		return this.Model.SupplyTipology;
	}

	get componentTipology() : string
	{
		return "OTHERS";
	}

	get description() : string
	{
		return this.Model.Description;
	}
	
	get offerAmount() : number
	{
		return this.Model.OfferAmount;
	}

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}

	get expectedMargin() : number
	{
		return this.Model.ExpectedMargin;
	}

	get expectedMarginPerc() : string
	{
		return this.Model.ExpectedMarginPercentage.toFixed(2);
	}
}
