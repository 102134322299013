










































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { PgaValutazioneOffertaAltriPresaleCreateModel } from "../store";

@Component({
  data: () => ({
    PgaValutazioneOffertaAltriPresaleCreate: undefined
  }),
  computed: {
    DescrizioneAltriPresale: {
      get() {
        return this.$props.Model.DescrizioneAltriPresale;
      },
      set(value: string) {
        CreateOfferModule.SET_DESCRIZIONEALTRIPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    CostoAttesoAltriPresale: {
      get() {
        return this.$props.Model.CostoAttesoAltriPresale;
      },
      set(value: number) {
        CreateOfferModule.UpdateCostoAttesoAltriPresale({
          index: this.$props.index,
          value: parseFloat((value).toString())
        });
      }
    },
  }
})
export default class PgaValutazioneOffertaAltriPresaleCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaAltriPresaleCreateModel;

  @Emit()
  removeAltriPresale() {
    CreateOfferModule.REMOVE_ALTRI_PRESALE(this.index);
  }
}
