


















import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaHubSpotDealSelector from "./components/hubspot/PgaHubSpotDealSelector.vue";
import { SectionStore } from "./SectionStore";

@Component( {
	components: {
		PgaHubSpotDealSelector,
	},
} )
export default class OfferEvaluationPageCustomerOrderSelectDeal extends mixins(SharedMixin)
{
	private dealId = "";

	private SelectDeal()
	{
		const deal = SectionStore.HubSpotDeals.Results
			.find(d => d.Id === this.dealId);
		if(deal)
		{
			SectionStore.SET_CRM_DEAL(deal);
			this.$router.replace({ name: "offerevaluationcustomercreate" })				
		}				
	}

	private undo ()
	{
		this.$router.go( -1 );
	}
}
