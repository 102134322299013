








































































	import { Component, Prop } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { SectionStore } from "../../../SectionStore";
    import { EditFixedPriceProjectCommand, EditHumanResourcesCommand, EditOtherComponentsCommand } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationExpectedRevenueCreate extends mixins( SharedMixin )
	{
        @Prop({ type: Boolean, default: false }) readonly readonlyFee!: boolean;
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

        private get editFixedPrice() : EditFixedPriceProjectCommand
        {
            return SectionStore.EditFixedPriceProject.Command;
        }
        private get editHumanResources() : EditHumanResourcesCommand
        {
            return SectionStore.EditHumanResources.Command;
        }
        private get editOtherComponents() : EditOtherComponentsCommand
        {
            return SectionStore.EditOtherComponents.Command;
        }

        // Totals Offer Evaluation
		get offerAmounts() : number
		{
			return this.partialOfferAmounts * (1 - SectionStore.EditTotalAmounts.Command.Discount / 100);
		}
		
		private get partialOfferAmounts (): number
		{
            const totalOfferAmountsHR = this.editHumanResources.Resources.length > 0 ?
                    this.editHumanResources.Resources
                        .map(r => r.DailyPriceOffer * r.OfferedMen)
                        .reduce((sum, current) => sum + current) : 0;
            const totalOfferAmountsOC = (this.editOtherComponents.Others.length > 0 ?
					this.editOtherComponents.Others
					.map(o => o.OfferAmount)
					.reduce((sum, current) => sum + current) : 0)
				+ this.editOtherComponents.ProductsPurchase.OfferAmount
				+ this.editOtherComponents.SolutionsPurchase.OfferAmount
				+ this.editOtherComponents.ServicesPurchase.OfferAmount
				+ this.editOtherComponents.TransferCosts.OfferAmount
				+ this.editOtherComponents.SecurityCosts.OfferAmount;
			return this.editFixedPrice.TotalTaxableAmount + totalOfferAmountsHR + totalOfferAmountsOC;
		}

		private get partialExpectedCosts (): number
		{
            const totalExpectedCostsHR = this.editHumanResources.Resources.length > 0 ?
                this.editHumanResources.Resources
                    .map(r => 
                    {
                        if(r.ExpectedMen > 0)
							return r.MiniumDailyPrice * r.ExpectedMen;
						else
							return r.MiniumDailyPrice * r.OfferedMen;
                    })
                    .reduce((sum, current) => sum + current) : 0;
            const totalExpectedCostsOC = (this.editOtherComponents.Others.length > 0 ?
                this.editOtherComponents.Others
					.map(o => o.ExpectedCost)
					.reduce((sum, current) => sum + current) : 0)
				+ this.editOtherComponents.ProductsPurchase.ExpectedCost
				+ this.editOtherComponents.SolutionsPurchase.ExpectedCost
				+ this.editOtherComponents.ServicesPurchase.ExpectedCost
				+ this.editOtherComponents.TransferCosts.ExpectedCost
				+ this.editOtherComponents.SecurityCosts.ExpectedCost;
			return totalExpectedCostsHR + totalExpectedCostsOC;
		}

		get expectedCosts() : number
		{
			return this.partialExpectedCosts +
				this.expectedCostsPresale +
				this.expectedCostsPostsale;
		}

		private get expectedCostsPresale() : number
		{
			let expectedCosts = 0;
			if(SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.length > 0)
			{
				expectedCosts += SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts
					.map(ac => ac.DaysMen * ac.DailyCost)
					.reduce((sum, current) => sum + current);
			}
			if(SectionStore.EditOtherCostsPresale.Command.OtherCosts.length > 0)
			{
				expectedCosts += SectionStore.EditOtherCostsPresale.Command.OtherCosts
					.map(oc => oc.ExpectedCost)
					.reduce((sum, current) => sum + current);
			}
			return expectedCosts;
		}

		private get expectedCostsPostsale() : number
		{
			return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.ExpectedCost +
				SectionStore.EditAdditionalCostsPostsale.Command.Warranty.ExpectedCost +
				SectionStore.EditAdditionalCostsPostsale.Command.Support.ExpectedCost +
				(SectionStore.EditAdditionalCostsPostsale.Command.Others.length > 0 ?
				SectionStore.EditAdditionalCostsPostsale.Command.Others
					?.map(o => o.ExpectedCost)
					?.reduce((sum, current) => sum + current) : 0);
		}

        get expectedMargins (): number
		{
			return this.offerAmounts - this.expectedCosts;
		}

        get expectedMarginsPerc (): string
		{
            if(this.offerAmounts > 0)
               return ((this.expectedMargins / this.offerAmounts) * 100).toFixed(2);
            return "0";
		}

        // Expected Fee
        get expectedFee() : number
        {
            return SectionStore.EditTotalExpectedRevenueByFee.Command.ExpectedFee;
        }
        set expectedFee(value: number)
        {
            SectionStore.SET_EDIT_TOTALEXPECTEDREVENUEBYFEE_FEE(value);
        }

        // Total Expected Revenue
        get expectedRevenue (): number
		{
            return this.offerAmounts + this.expectedFee;
		}

        get expectedMarginsRevenue (): number
		{
			return this.expectedRevenue - this.expectedCosts;
		}

        get expectedMarginsRevenuePerc (): string
		{
            if(this.expectedRevenue > 0)
                return ((this.expectedMarginsRevenue / this.expectedRevenue) * 100).toFixed(2);
            return "0";
		}

		get Errors (): Map<string, Array<string>>
		{
			return SectionStore.EditTotalAmounts.Errors;
		}

		beforeDestroy (): void
		{
			SectionStore.ResetEditTotalExpectedRevenueByFeeSectionCommand();
		}
	}
