





























































import { Component } from "vue-property-decorator";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";
import { ResourceDetailsCommand } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationHumanResourcesSubtotals extends mixins(SharedMixin)
{
	get resources() : Array<ResourceDetailsCommand>
	{
		return SectionStore.EditHumanResources.Command.Resources;
	}

	get expectedMen (): number
	{
		if(this.resources.length > 0)
			return this.resources
				.map(r => r.ExpectedMen)
				.reduce((sum, current) => sum + current);
		return 0;
	}

	get offeredMen (): number
	{
		if(this.resources.length > 0)
			return this.resources
				.map(r => r.OfferedMen)
				.reduce((sum, current) => sum + current);
		return 0;
	}

	get riskMargin (): string
	{
		if(this.offeredMen == 0)
			return "0";
		return ((this.offeredMen - this.expectedMen) / this.offeredMen * 100)
			.toFixed(2);
	}

	get offerAmount (): number
	{
		if(this.resources.length > 0)
		{
			const offerAmounts = new Array<number>();
			this.resources
				.forEach(r => offerAmounts.push(r.DailyPriceOffer * r.OfferedMen))
			return offerAmounts
				.reduce((sum, current) => sum + current);
		}
		return 0;
	}

	get expectedCost() : number
	{
		if(this.resources.length > 0)
		{
			const offerCosts = new Array<number>();
			SectionStore.EditHumanResources.Command.Resources
				.forEach(r =>
				{
					if(r.ExpectedMen > 0)
						offerCosts.push(r.MiniumDailyPrice * r.ExpectedMen);
					else
						offerCosts.push(r.MiniumDailyPrice * r.OfferedMen);
				})
			return offerCosts
				.reduce((sum, current) => sum + current);
		}
		return 0;
	}

	get expectedMargin() : number
	{
		if(this.resources.length > 0)
		{
			const expectedMargins = new Array<number>();
			SectionStore.EditHumanResources.Command.Resources
				.forEach(r => 
				{
					const offerAmount = r.DailyPriceOffer * r.OfferedMen;
					if(r.ExpectedMen > 0)
						expectedMargins.push(offerAmount - (r.MiniumDailyPrice * r.ExpectedMen));
					else
						expectedMargins.push(offerAmount - (r.MiniumDailyPrice * r.OfferedMen));
				})
			return expectedMargins
				.reduce((sum, current) => sum + current);
		}
		return 0;
	}
	
	get expectedMarginPerc() : string
	{
		if(this.offerAmount == 0)
			return "0";
		return ((this.expectedMargin / this.offerAmount) * 100).toFixed(2);
	}
}
