







































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { OtherComponentsView } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationOtherComponentsSubtotals extends mixins( SharedMixin )
{
	@Model("", { type: Object }) readonly Model!: OtherComponentsView;

	get offerAmount() : number
	{			
		return this.Model.OfferAmount;
	}

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}

	get expectedMargin() : number
	{
		return this.Model.ExpectedMargin;
	}

	get expectedMarginPerc() : string
	{
		return this.Model.ExpectedMarginPercentage.toFixed(2);
	}
}
