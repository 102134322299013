

























































































import { Component, Prop } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel } from '@/services/iOrderService';
import { SectionStore } from "../../../../SectionStore";

@Component({})
export default class PgaOfferEvaluationAdditionalCostsMaintenancePostsaleCreate extends mixins(SharedMixin)
{
	@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;
	
	get errorSubSection() : string
	{
		let color = "";
		this.Errors.forEach((value, key) =>
		{
			if(key.includes("Maintenance"))
				color = "error";
		});
		return color;
	}

	get businessLine() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.BusinessLineId;
	}
	set businessLine(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_TECHAREA_ID(null);
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_BUSINESSLINE_ID(value);
	}

	get techArea() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.TechAreaId;
	}
	set techArea(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_TECHAREA_ID(value);
	}

	get supplyTipology() : Guid | null
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.SupplyTipologyId;
	}
	set supplyTipology(value: Guid | null)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_SUPPLYTIPOLOGY_ID(value);
	}

	get description() : string
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.Description;
	}
	set description(value: string)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_DESCRIPTION(value);
	}

	get expectedCost() : number
	{
		return SectionStore.EditAdditionalCostsPostsale.Command.Maintenance.ExpectedCost;
	}
	set expectedCost(value: number)
	{
		SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_MAINTENANCE_EXPECTEDCOST(value);
	}

	get tipology() : string
	{
		return "MAINTENANCE";
	}

	//Dropdown Lists
	get businessLineDropdown() : Array<BusinessLineTableView>
	{
		return SectionStore.BusinessLinesDropdown;
	}
	get techAreaDropdown() : Array<TechAreaModel>
	{		
		return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
	}
	get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
	{
		return SectionStore.SupplyTipologiesDropdown;
	}

	get Errors() : Map<string, Array<string>>
	{
		return SectionStore.EditAdditionalCostsPostsale.Errors;
	}
}
