















	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import PgaBusinessStartupCustomerOrderDetails from './components/details/PgaBusinessStartupCustomerOrderDetails.vue';
    import { CustomerOrderStore } from "./store";
	import { BusinessStartupCustomerOrderStatus } from "@/services/iOrderService";

	@Component( {
		components:
		{
			PgaBusinessStartupCustomerOrderDetails,
		},
	} )
    export default class BusinessStartupPageCustomerOrderDetails extends mixins( SharedMixin )
    {
		private isLoaded = false;

		private get canEdit() : boolean
		{
			return CustomerOrderStore.CustomerOrderDetails.Status !== BusinessStartupCustomerOrderStatus.Closed;
		}

		private Edit() : void
		{
			const id = CustomerOrderStore.CustomerOrderDetails.Id.toString();
			this.$router.push( { name: "businessstartupcustomeredit", params: { id } } );
		}

		async mounted() : Promise<void>
		{
			const id: Guid = Guid.parse(this.$route.params.id);
			this.isLoaded = false;
			await CustomerOrderStore.GetCustomerOrdersById(id);
			this.isLoaded = true;
		}
    }
