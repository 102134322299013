




































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import { CreateOfferModule } from "./store";
import PgaValutazioneOffertaHeaderDetails from "./Components/PgaValutazioneOffertaHeaderDetails.vue";
import PgaValutazioneOffertaRisorseUmaneDetails from "./Components/PgaValutazioneOffertaRisorseUmaneDetails.vue";
import PgaValutazioneOffertaSubtotaliRisorseUmane from "./Components/PgaValutazioneOffertaSubtotaliRisorseUmane.vue";
import PgaValutazioneOffertaAltreComponentiDetails from "./Components/PgaValutazioneOffertaAltreComponentiDetails.vue";
import PgaValutazioneOffertaSubtotaliAltreComponenti from "./Components/PgaValutazioneOffertaSubtotaliAltreComponenti.vue";
import PgaValutazioneOffertaTotaliOffertaDetails from "./Components/PgaValutazioneOffertaTotaliOffertaDetails.vue";
import PgaValutazioneOffertaAggiuntiviPresaleDetails from "./Components/PgaValutazioneOffertaAggiuntiviPresaleDetails.vue";
import PgaValutazioneOffertaSubtotaliAggiuntiviPresale from "./Components/PgaValutazioneOffertaSubtotaliAggiuntiviPresale.vue";
import PgaValutazioneOffertaAltriPresaleDetails from "./Components/PgaValutazioneOffertaAltriPresaleDetails.vue";
import PgaValutazioneOffertaSubtotaliAltriPresale from "./Components/PgaValutazioneOffertaSubtotaliAltriPresale.vue";
import PgaValutazioneOffertaPostsaleDetails from "./Components/PgaValutazioneOffertaPostsaleDetails.vue";
import PgaValutazioneOffertaSubtotaliPostsale from "./Components/PgaValutazioneOffertaSubtotaliPostsale.vue";
import PgaValutazioneOffertaTotaliOffertaValutazione from "./Components/PgaValutazioneOffertaTotaliOffertaValutazione.vue";
import { PgaValutazioneOffertaSubtotaliPostsaleModel } from "./store";
import { PgaValutazioneOffertaTotaliOffertaValutazioneModel } from "./store";
import { PgaValutazioneOffertaSubtotaliAggiuntiviPresaleModel } from "./store";
import { PgaValutazioneOffertaSubtotaliAltriPresaleModel } from "./store";
import { PgaValutazioneOffertaTotaliOffertaCreateModel } from "./store";
import { PgaValutazioneOffertaSubtotaliRisorseUmaneModel } from "./store";
import { PgaValutazioneOffertaSubtotaliAltreComponentiModel } from "./store";

@Component({
  components: {
    PgaValutazioneOffertaHeaderDetails,
    PgaValutazioneOffertaRisorseUmaneDetails,
    PgaValutazioneOffertaSubtotaliRisorseUmane,
    PgaValutazioneOffertaAltreComponentiDetails,
    PgaValutazioneOffertaSubtotaliAltreComponenti,
    PgaValutazioneOffertaTotaliOffertaDetails,
    PgaValutazioneOffertaAggiuntiviPresaleDetails,
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale,
    PgaValutazioneOffertaAltriPresaleDetails,
    PgaValutazioneOffertaSubtotaliAltriPresale,
    PgaValutazioneOffertaTotaliOffertaValutazione,
    PgaValutazioneOffertaPostsaleDetails,
    PgaValutazioneOffertaSubtotaliPostsale
  },
  data: () => ({}),
  computed: {
 PgaValutazioneOffertaHeaderDetails: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaHeaderCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliRisorseUmane: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliRisorseUmane;
      }
    },
    PgaValutazioneOffertaTotaliOffertaDetails: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaTotaliOffertaCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliAltreComponenti: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAltreComponenti;
      }
    },
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAggiuntiviPresale;
      }
    },
    PgaValutazioneOffertaSubtotaliAltriPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliAltriPresale;
      }
    },
    PgaValutazioneOffertaSubtotaliPostsale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaSubtotaliPostsale;
      }
    },
    PgaValutazioneOffertaTotaliOffertaValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione;
      }
    }

    /*
    PgaValutazioneOffertaHeaderOffertaDetails: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaHeaderCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliRisorseUmane: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliRisorseUmane;
      }
    },
    PgaValutazioneOffertaTotaliOffertaDetails: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliAltreComponenti: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAltreComponenti;
      }
    },
    PgaValutazioneOffertaSubtotaliAggiuntiviPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAggiuntiviPresale;
      }
    },
    PgaValutazioneOffertaSubtotaliAltriPresale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliAltriPresale;
      }
    },
    PgaValutazioneOffertaSubtotaliPostsale: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaSubtotaliPostsale;
      }
    },
    PgaValutazioneOffertaTotaliOffertaValutazione: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaTotaliOffertaValutazione;
      }
    },
    PgaValutazioneOffertaSubtotaliRisorseUmaneModel: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaRisorseUmaneCreate;
      }
    },
    PgaValutazioneOffertaSubtotaliAltreComponentiModel: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta
          .PgaValutazioneOffertaAltreComponentiCreate;
      }
    }*/
  }
})
export default class OffertePageDetails extends Vue {}
