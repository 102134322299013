


















































































































































































    import { Component } from 'vue-property-decorator';
    import { clearTimeout, setTimeout } from 'timers';
    
    import { mixins } from "vue-class-component";
    import SharedMixin from "@/mixins/SharedMixin";
    
    import { CustomerOrderStore } from '../../../store';
    
    import { PlaceType } from '@/services/iOrderService';

    import PgaOtherCustomerReferralEdit from "../sub-components/PgaOtherCustomerReferralEdit.vue";
    import PgaDeliverableEdit from "../sub-components/PgaDeliverableEdit.vue";

    @Component({
        components:
        {
            PgaOtherCustomerReferralEdit,
            PgaDeliverableEdit,
        }
    })
    export default class PgaOrderRequirementsReviewCreate extends mixins(SharedMixin)
    {
        private dropdownLoading = false;
        private showDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly placeDropdown : Array<any> = 
        [
            {
                Name: 'Proge',
                Value: 1,
            },
            {
                Name: 'Customer',
                Value: 2,
            },
        ];

        get acom() : string
        {
            return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.ACOM;
        }

        get customer() : string
        {
            return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.Customer;
        }

        get finalCustomer() : string
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.FinalCustomer;
        }
        set finalCustomer(value: string)
        {
            this.showDropdown = false;
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_FINAL_CUSTOMER(value);
        }
        private setFinalCustomerManually(value: string)
		{
			CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_FINAL_CUSTOMER(value);

            // cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.customersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestCustomers(value);
                }, 500);
                this.showDropdown = true;
            }
            else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

        get description() : string
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Description;
        }
        set description(value: string)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_DESCRIPTION(value);
        }

        get advanceSupply() : boolean
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.AdvanceSupply;
        }
        set advanceSupply(value: boolean)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ADVANCE_SUPPLY(value)
        }

        get startDate() : Date
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.StartDate;
        }
        set startDate(value: Date)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_START_DATE(value);
        }

        get expectedEndDate() : Date
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.ExpectedEndDate;
        }
        set expectedEndDate(value: Date)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_END_DATE(value);
        }

        get place() : PlaceType
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Place;
        }
        set place(value: PlaceType)
        {
            if(value === 1)
                CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ADDRESS(null);
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_PLACE(value);
        }

        get address() : string | null
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Address;
        }
        set address(value: string | null)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ADDRESS(value);
        }

        get customerContractManager() : string
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.CustomerContractManager;
        }
        set customerContractManager(value: string)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_CUSTOMER_CONTRACT_MANAGER(value);
        }

        get otherCustomerReferralsCount() : number
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.OtherCustomerReferrals.length;
        }
        private addOtherCustomerReferral() : void
        {
            CustomerOrderStore.ADD_EDIT_ORDER_REQUIREMENTS_REVIEW_OTHER_CUSTOMER_REFERRALS();
        }

        get deliverableCount() : number
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Deliverables.length;
        }
        private addDeliverable() : void
        {
            CustomerOrderStore.ADD_EDIT_ORDER_REQUIREMENTS_REVIEW_DELIVERABLES();
        }

		get businessLine() : string
		{
			return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.BusinessLine;
		}

		get techArea() : string
		{
			return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.TechArea;
		}

		get supplyTipology() : string
		{
			return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.SupplyTipology;
		}

        get areaManager() : string
		{
			return CustomerOrderStore.CustomerOrderDetails.OrderRequirementsReview.AreaManager;
		}

        get privacy() : boolean
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Privacy;
        }
        set privacy(value: boolean)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_PRIVACY(value)
        }

        get notes() : string
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Command.Notes;
        }
        set notes(value: string)
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_NOTES(value);
        }

        //Dropdown Lists
		// get businessLineDropdown() : Array<BusinessLineTableView>
		// {
        //     return CustomerOrderStore.BusinessLinesDropdown;
		// }
		// get techAreaDropdown() : Array<TechAreaModel>
		// {		
        //     return CustomerOrderStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		// }
		// get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		// {
        //     return CustomerOrderStore.SupplyTipologiesDropdown;
		// }
        get customersDropdown() : Array<string>
        {
            return CustomerOrderStore.CustomersDropdown.map(c => c.BusinessName);
        }

		private async requestCustomers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await CustomerOrderStore.GetCustomersDropdown(search);
			this.dropdownLoading = false;
		}

        get Errors() : Map<string, Array<string>>
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Errors;
        }

        beforeDestroy() : void
        {
            CustomerOrderStore.SET_EDIT_ORDER_REQUIREMENTS_REVIEW_ERRORS(new Map<string, Array<string>>());
        }
    }
