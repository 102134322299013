


































	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { OfferEvaluationCustomerOrderTableView } from "@/services/iOrderService";
    import { CustomerOrderStore } from "../../CustomerOrderStore";
    import { getFileName } from "@/feature/Shared/store";

	@Component({})
	export default class PgaOfferEvaluationCustomerOrderTable extends mixins(SharedMixin)
	{
        private isLoading = false;
        private showClosed = false;
		private headers =
			[
                {
                    text: "ACOM",
                    align: 'start',
                    sortable: true,
					value: "ACOM",
                    keySearch: "ac",
				},
				{
                    text: "Customer",
                    align: 'start',
                    sortable: true,
					value: "CustomerName",
                    keySearch: "cn",
				},
				{
                    text: "Offer Description",
                    align: 'start',
                    sortable: true,
					value: "Description",
                    keySearch: "de",
                },
                {
                    text: "Order Protocol/Contract",
                    align: 'start',
                    sortable: true,
					value: "OrderProtocol",
                    keySearch: "op",
                },
                {
                    text: "CRM Deal Id",
                    align: 'start',
                    sortable: true,
					value: "CRMDealId",
                    keySearch: "crm",
                },    
                {
                    text: "Status",
                    align: 'start',
                    sortable: true,
					value: "Status",
                    keySearch: "st",
                },    
            ];

        private get offersEvalutaionCustomerOrderList() : Array<OfferEvaluationCustomerOrderTableView>
        {
            if(this.showClosed)
                return CustomerOrderStore.CustomerOrdersClosed;
            return CustomerOrderStore.CustomerOrders;
        }

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private details(item: any) : void
		{
            const id = item.Id;
			this.$router.push( { name: "offerevaluationcustomerdetails", params: { id } } );
        }
        
        private async getCustomerOrdersClosed() : Promise<void>
        {
            if(!CustomerOrderStore.CustomerOrdersClosed.some(co => co))
            {
                this.isLoading = true;
                await CustomerOrderStore.ListOfferEvaluationCustomerOrdersClosed();
                this.isLoading = false;
            }
        }
        
        private getFileName(fullFileName: string) : string
        {
            return getFileName(fullFileName);
        }
        
        async mounted() : Promise<void>
        {
            this.isLoading = true;
            await CustomerOrderStore.ListOfferEvaluationCustomerOrders();
            this.isLoading = false;
        }
	}
