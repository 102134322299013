























































































































































































































































    import { Component } from "vue-property-decorator";
    import { Guid } from "guid-typescript";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    import { CustomerOrderStore } from "../../CustomerOrderStore";
    import { SectionStore } from "../../SectionStore";

    import { deepComparison, IsMobile, SharedModule } from "@/feature/Shared/store";
    
    // Header
    import PgaOfferEvaluationHeaderCustomerOrderCreate from "./sub-components/PgaOfferEvaluationHeaderCustomerOrderCreate.vue";
    import PgaOfferEvaluationHeaderCustomerOrderEdit from "../edit/sub-components/PgaOfferEvaluationHeaderCustomerOrderEdit.vue";
    // Fixed Price Project
    import PgaOfferEvaluationFixedPriceProjectCreate from "./sub-components/PgaOfferEvaluationFixedPriceProjectCreate.vue";
    // Human Resources
    import PgaOfferEvaluationHumanResourcesCreate from"./sub-components/PgaOfferEvaluationHumanResourcesCreate.vue";
    import PgaOfferEvaluationHumanResourcesSubtotals from "./sub-components/sub-totals/PgaOfferEvaluationHumanResourcesSubtotals.vue";
    // Other Components
    import PgaOfferEvaluationOtherComponentsProductCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsProductCreate.vue";
    import PgaOfferEvaluationOtherComponentsSolutionCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsSolutionCreate.vue";
    import PgaOfferEvaluationOtherComponentsServiceCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsServiceCreate.vue";
    import PgaOfferEvaluationOtherComponentsTransferCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsTransferCreate.vue";
    import PgaOfferEvaluationOtherComponentsSecurityCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsSecurityCreate.vue";
    import PgaOfferEvaluationOtherComponentsOthersCreate from "./sub-components/other-components/PgaOfferEvaluationOtherComponentsOthersCreate.vue";
    import PgaOfferEvaluationOtherComponentsSubtotals from "./sub-components/sub-totals/PgaOfferEvaluationOtherComponentsSubtotals.vue";
    // TotalAmounts
    import PgaOfferEvaluationTotalAmountsCreate from "./sub-components/PgaOfferEvaluationTotalAmountsCreate.vue";
    import PgaOfferEvaluationTotalAmountsDiscountedCreate from "./sub-components/PgaOfferEvaluationTotalAmountsDiscountedCreate.vue";
    // Additional Costs Presale
    import PgaOfferEvaluationAdditionalCostsPresaleCreate from "./sub-components/PgaOfferEvaluationAdditionalCostsCreate.vue";
    import PgaOfferEvaluationAdditionalCostsPresaleSubtotals from "./sub-components/sub-totals/PgaOfferEvaluationAdditionCostsPresaleSubtotals.vue";
    // Other Costs
    import PgaOfferEvaluationOtherCostsPresaleCreate from "./sub-components/PgaOfferEvaluationOtherCostsCreate.vue";
    import PgaOfferEvaluationOtherCostsPresaleSubtotals from "./sub-components/sub-totals/PgaOfferEvaluationOtherCostsPresaleSubtotals.vue";
    // Total Costs Presale
    import PgaOfferEvaluationTotalCostsCreate from "./sub-components/PgaOfferEvaluationTotalCostsCreate.vue";
    // Additional Costs Postsale
    import PgaOfferEvaluationAdditionalCostsMaintenancePostsaleCreate from "./sub-components/additional-costs-postsale/PgaOfferEvaluationAdditionalCostsPostsaleMaintenanceCreate.vue";
    import PgaOfferEvaluationAdditionalCostsWarrantyPostsaleCreate from "./sub-components/additional-costs-postsale/PgaOfferEvaluationAdditionalCostsPostsaleWarrantyCreate.vue";
    import PgaOfferEvaluationAdditionalCostsSupportPostsaleCreate from "./sub-components/additional-costs-postsale/PgaOfferEvaluationAdditionalCostsPostsaleSupportCreate.vue";
    import PgaOfferEvaluationAdditionalCostsOthersPostsaleCreate from "./sub-components/additional-costs-postsale/PgaOfferEvaluationAdditionalCostsPostsaleOthersCreate.vue";
    // Totals Offer Evaluation
    import PgaOfferEvaluationTotalsOfferEvaluationCreate from "./sub-components/PgaOfferEvaluationTotalsOfferEvaluationCreate.vue";
    import PgaOfferEvaluationExpectedRevenueCreate from "./sub-components/PgaOfferEvaluationExpectedRevenueCreate.vue";
    // Attachments
    import PgaOfferEvaluationAttachmentsCreate from "./sub-components/PgaOfferEvaluationAttachmentsCreate.vue";

	@Component( {
        components:
        {
            PgaOfferEvaluationHeaderCustomerOrderCreate,
            PgaOfferEvaluationHeaderCustomerOrderEdit,

            PgaOfferEvaluationFixedPriceProjectCreate,

            PgaOfferEvaluationHumanResourcesCreate,
            PgaOfferEvaluationHumanResourcesSubtotals,

            PgaOfferEvaluationOtherComponentsProductCreate,
            PgaOfferEvaluationOtherComponentsSolutionCreate,
            PgaOfferEvaluationOtherComponentsServiceCreate,
            PgaOfferEvaluationOtherComponentsTransferCreate,
            PgaOfferEvaluationOtherComponentsSecurityCreate,
            PgaOfferEvaluationOtherComponentsOthersCreate,
            PgaOfferEvaluationOtherComponentsSubtotals,

            PgaOfferEvaluationTotalAmountsCreate,
            PgaOfferEvaluationTotalAmountsDiscountedCreate,

            PgaOfferEvaluationAdditionalCostsPresaleCreate,
            PgaOfferEvaluationAdditionalCostsPresaleSubtotals,

            PgaOfferEvaluationOtherCostsPresaleCreate,
            PgaOfferEvaluationOtherCostsPresaleSubtotals,

            PgaOfferEvaluationTotalCostsCreate,

            PgaOfferEvaluationAdditionalCostsMaintenancePostsaleCreate,
            PgaOfferEvaluationAdditionalCostsWarrantyPostsaleCreate,
            PgaOfferEvaluationAdditionalCostsSupportPostsaleCreate,
            PgaOfferEvaluationAdditionalCostsOthersPostsaleCreate,

            PgaOfferEvaluationTotalsOfferEvaluationCreate,
            PgaOfferEvaluationExpectedRevenueCreate,

            PgaOfferEvaluationAttachmentsCreate,
        }
    })
    export default class PgaOfferEvaluationCustomerOrderCreate extends mixins(SharedMixin)
    {
        private readonly customerOrderResource = "customerorder";
        private offerEvaluationCustomerOrderId : Guid | null = null;
        private isMiniVariant = this.isMobile;
        
        get isMobile() : boolean
        {
            return IsMobile(this.$vuetify.breakpoint.name);
        }

        get panelsOpened() : Array<number>
        {
            if(this.offerEvaluationCustomerOrderId === null)
                return [];
            return [0, 1, 2, 3, 4, 5, 6];
        }

        // Errors check
        get hasErrorsFixedPrice() : boolean
        {
            return SectionStore.EditFixedPriceProject.Errors.size > 0;
        }
        get hasErrorsHumanResources() : boolean
        {
            return SectionStore.EditHumanResources.Errors.size > 0;
        }
        get hasErrorsOtherComponents() : boolean
        {
            return SectionStore.EditOtherComponents.Errors.size > 0;
        }
        get hasErrorsAdditionalCostsPresale() : boolean
        {
            return SectionStore.EditAdditionalCostsPresale.Errors.size > 0;
        }
        get hasErrorsOtherCostsPresale() : boolean
        {
            return SectionStore.EditOtherCostsPresale.Errors.size > 0;
        }
        get hasErrorsAdditionalCostsPostsale() : boolean
        {
            return SectionStore.EditAdditionalCostsPostsale.Errors.size > 0;
        }
        get hasErrorsAttachments() : boolean
        {
            return SectionStore.EditAttachments.Errors.size > 0;
        }

        // Sections: handle single rows
        get humanResourcesCount() : number
        {
            return SectionStore.EditHumanResources.Command.Resources.length;
        }
        private addHumanResource()
        {
            SectionStore.SET_EDIT_HUMANRESOURCES_ADD_NEW_RESOURCE();
        }

        get otherComponentsCount() : number
        {
            return SectionStore.EditOtherComponents.Command.Others.length;
        }
        private addOtherComponent()
        {
            SectionStore.SET_EDIT_OTHERCOMPONENTS_ADD_NEW_COMPONENT();
        }

        get additionalCostsPresaleCount() : number
        {
            return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts.length;
        }
        private addAdditionalCostsPresale()
        {
            SectionStore.SET_EDIT_ADDITIONALCOSTSPRESALE_ADD_NEW_ELEMENT();
        }

        get otherCostsPresaleCount() : number
        {
            return SectionStore.EditOtherCostsPresale.Command.OtherCosts.length;
        }
        private addOtherCostsPresale()
        {
            SectionStore.SET_EDIT_OTHERCOSTSPRESALE_ADD_NEW_ELEMENT();
        }

        get otherCostsPostsaleCount() : number
        {
            return SectionStore.EditAdditionalCostsPostsale.Command.Others.length;
        }
        private addOtherCostsPostsale()
        {
            SectionStore.SET_EDIT_ADDITIONALCOSTSPOSTSALE_ADD_NEW_ELEMENT();
        }

        get showMiniVariant() : boolean
        {
            return this.isMobile && this.isMiniVariant;
        }

        private changeMiniVariant() : void
        {
            this.isMiniVariant = !this.isMiniVariant;
        }

        private async SaveAsDraft() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await CustomerOrderStore.EditOfferEvaluationCustomerOrder();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditFixedPriceProject.Command, SectionStore.EditFixedPriceProject.CommandCopy))
                    await SectionStore.EditFixedPriceSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditHumanResources.Command, SectionStore.EditHumanResources.CommandCopy))
                    await SectionStore.EditHumanResourcesSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditOtherComponents.Command, SectionStore.EditOtherComponents.CommandCopy))
                    await SectionStore.EditOtherComponentsSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditTotalAmountsSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditAdditionalCostsPresale.Command, SectionStore.EditAdditionalCostsPresale.CommandCopy))
                    await SectionStore.EditAdditionalCostsPresaleSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditOtherCostsPresale.Command, SectionStore.EditOtherCostsPresale.CommandCopy))
                    await SectionStore.EditOtherCostsPresaleSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditAdditionalCostsPostsale.Command, SectionStore.EditAdditionalCostsPostsale.CommandCopy))
                    await SectionStore.EditAdditionalCostsPostsaleSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditTotalExpectedRevenueByFeeSectionDraft();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                if(!deepComparison(SectionStore.EditAttachments.Command, SectionStore.EditAttachments.CommandCopy))
                    await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);
            if(allSaved)
            {
                const id = this.offerEvaluationCustomerOrderId?.toString() ?? "";
                this.$router.replace({ name: "offerevaluationcustomerdetails", params: { id } })
            }
        }

        private async Save() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await CustomerOrderStore.EditOfferEvaluationCustomerOrder();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditFixedPriceSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditHumanResourcesSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditOtherComponentsSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditTotalAmountsSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditAdditionalCostsPresaleSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditOtherCostsPresaleSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditAdditionalCostsPostsaleSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditTotalExpectedRevenueByFeeSection();
            }
            catch
            {
                allSaved = false;
            }
            try
            {
                await SectionStore.EditAttachmentsSection();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);

            if(allSaved)
            {
                await CustomerOrderStore.SaveOfferEvaluationCustomerOrder();
                const id = this.offerEvaluationCustomerOrderId?.toString() ?? "";
                this.$router.replace({ name: "offerevaluationcustomerdetails", params: { id } })
            }
        }

        private undo() : void
        {
            this.$router.go(-1);
        }

        private async createHeader() : Promise<void>
        {
            this.offerEvaluationCustomerOrderId = (await CustomerOrderStore.CreateOfferEvaluationCustomerOrder()).Id;
            SectionStore.SetOfferEvaluationIdToSections(this.offerEvaluationCustomerOrderId);
            CustomerOrderStore.SET_SAVE_CUSTOMERORDER_ID(this.offerEvaluationCustomerOrderId);
        }
        
        mounted() : void
        {
            SectionStore.SET_OFFER_EVALUATION_TYPE(this.customerOrderResource);
        }

        beforeDestroy() : void
		{
            SectionStore.ResetEditFixedPriceSectionCommand();
			SectionStore.ResetEditHumanResourcesSectionCommand();
            SectionStore.ResetEditOtherComponentsSectionCommand();
            SectionStore.ResetEditTotalAmountsSectionCommand();
            SectionStore.ResetEditAdditionalCostsPresaleSectionCommand();
            SectionStore.ResetEditOtherCostsPresaleSectionCommand();
            SectionStore.ResetEditAdditionalCostsPostsaleSectionCommand();
            SectionStore.ResetEditTotalExpectedRevenueByFeeSectionCommand();
            SectionStore.ResetEditAttachmentsSectionCommand();
            CustomerOrderStore.ResetSaveCustomerOrderCommand();
		}
    }
