
































	import { Component, Model } from 'vue-property-decorator';
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { AttachmentsSectionView, BlobMetaDataView } from '@/services/iOrderService';
	import { getFileName } from '@/feature/Shared/store';

	@Component( {} )
	export default class PgaOfferEvaluationAttachmentsDetails extends mixins(SharedMixin)
	{
		@Model("", { type: Object }) readonly Model!: AttachmentsSectionView;

		get notes() : string
		{
			return this.Model.Notes;
		}

		get blobs() : Array<BlobMetaDataView>
		{
			return this.Model.Blobs;
		}

		private getFileName(fullFileName: string) : string
        {
            return getFileName(fullFileName);
        }
	}
