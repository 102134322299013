
















	import { Component, Prop } from "vue-property-decorator";
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { SectionStore } from "../../../SectionStore";

	@Component( {} )
	export default class PgaOfferEvaluationFixedPriceProjectCreate extends mixins(SharedMixin)
	{
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;
		
		get totalTaxableAmount() : number
		{
			return SectionStore.EditFixedPriceProject.Command.TotalTaxableAmount;
		}
		set totalTaxableAmount(value: number)
		{
			SectionStore.SET_EDIT_FIXEDPRICEPROJECT_TOT_TAX_AMOUNT(value);
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditFixedPriceProject.Errors;
		}

		beforeDestroy() : void
        {
            SectionStore.SET_EDIT_FIXEDPRICEPROJECT_ERRORS(new Map<string, Array<string>>());
            SectionStore.ResetEditFixedPriceSectionCommand();
        }
	}
