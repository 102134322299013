





























































	import { Component, Model } from "vue-property-decorator";
	
	import { mixins } from 'vue-class-component';
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { HumanResourcesSectionView } from "@/services/iOrderService";

	@Component({})
	export default class PgaOfferEvaluationHumanResourcesSubtotalsDetails extends mixins(SharedMixin)
	{
        @Model("", { type: Object }) readonly Model!: HumanResourcesSectionView;

		get expectedMen (): number
		{
			return this.Model.ExpectedMen;
		}

		get offeredMen (): number
		{
			return this.Model.OfferedMen;
		}

		get riskMargin (): string
		{
			return this.Model.MarginRisk.toFixed(2);
		}

		get offerAmount (): number
		{
			return this.Model.OfferAmount;
		}

		get expectedCost() : number
		{
			return this.Model.ExpectedCost;
		}

		get expectedMargin() : number
		{
			return this.Model.ExpectedMargin;
		}
		
		get expectedMarginPerc() : string
		{
			return this.Model.ExpectedMarginPercentage.toFixed(2);
		}
	}
