















	import { Component, Model } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { TotalCostsPostsaleSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalCostsPostsaleDetails extends mixins( SharedMixin )
	{
        @Model("", { type: Object }) readonly Model!: TotalCostsPostsaleSectionView;

        // Total Costs 
		get totalCostsPostsale (): number
		{
			return this.Model.TotalCosts;
		}
	}
