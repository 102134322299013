






































    import { Component } from "vue-property-decorator";
    import { Guid } from "guid-typescript";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    //  Order Requirements Review section
    import PgaOrderRequirementsReviewCreate from "./sections/PgaOrderRequirementsReviewCreate.vue";
    import { CustomerOrderStore } from "../../store";
    

	@Component( {
        components:
        {
            PgaOrderRequirementsReviewCreate,
        }
    })
    export default class PgaBusinessStartupCustomerOrderCreate extends mixins(SharedMixin)
    {
        private customerOrderId : Guid | null = null;

        get panelsOpened() : Array<number>
        {
            if(this.customerOrderId === null)
                return [0];
            return [0];
        }

        // Errors check
        get hasErrorsOrderRequirementsReview() : boolean
        {
            return CustomerOrderStore.CustomerOrderCreate.Errors.size > 0;
        }

        private undo() : void
        {
            this.$router.go(-1);
        }

        private async createOrderRequirementsReview() : Promise<void>
        {
            this.customerOrderId = (await CustomerOrderStore.CreateBusinessStartupCustomerOrder()).Id;
            this.$router.go(-1);
        }

        beforeDestroy() : void
		{
            CustomerOrderStore.ResetBusinessStartupCustomerOrderCreate();
		}
    }
