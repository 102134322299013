



























import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { SectionStore } from "@/feature/Offerte/OfferEvaluation/SectionStore";
import { AdditionalCostPresaleDetailsCommand } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationAdditionalCostsPresaleSubtotals extends mixins( SharedMixin )
{
	get additionalCosts() : Array<AdditionalCostPresaleDetailsCommand>
	{
		return SectionStore.EditAdditionalCostsPresale.Command.AdditionalCosts;
	}

	get daysMen() : number
	{
		if(this.additionalCosts.length > 0)
		{
			return this.additionalCosts
				.map(ac => ac.DaysMen)
				.reduce((sum, current) => sum + current);
		}
		return 0;
	}

	get expectedCost() : number
	{
		if(this.additionalCosts.length > 0)
		{
			return this.additionalCosts
				.map(ac => ac.DaysMen * ac.DailyCost)
				.reduce((sum, current) => sum + current);
		}
		return 0;
	}
}
