































































    import { Component, Prop } from "vue-property-decorator";
    import { Guid } from 'guid-typescript';
    
    import { mixins } from "vue-class-component";
    import SharedMixin from "@/mixins/SharedMixin";
    
    import { InternalProjectStore } from "../../../InternalProjectStore";
    import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel } from "@/services/iOrderService";
    import { SectionStore } from "../../../SectionStore";

    @Component({})
    export default class PgaOfferEvaluationHeaderInternalProjectCreate extends mixins(SharedMixin)
    {
        @Prop({ type: Boolean, default: false }) readonly readonly! : boolean;

        get title() : string
        {
            return InternalProjectStore.CreateInternalProjectHeader.Command.Title;
        }
        set title(value: string)
        {
            InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_TITLE(value);
        }

        get date() : Date
        {
            return new Date(InternalProjectStore.CreateInternalProjectHeader.Command.Date);
        }
        set date(value: Date)
        {
            InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_DATE(value);
        }

        get businessLine() : Guid | null
		{
			return InternalProjectStore.CreateInternalProjectHeader.Command.BusinessLineId;
		}
		set businessLine(value: Guid | null)
		{
			InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_TECHAREAID(null);
			InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_BUSINESSLINEID(value);
		}

		get techArea() : Guid | null
		{
			return InternalProjectStore.CreateInternalProjectHeader.Command.TechAreaId;
		}
		set techArea(value: Guid | null)
		{
			InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_TECHAREAID(value);
		}

		get supplyTipology() : Guid | null
		{
			return InternalProjectStore.CreateInternalProjectHeader.Command.SupplyTipologyId;
		}
		set supplyTipology(value: Guid | null)
		{
			InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_SUPPLYTIPOLOGYID(value);
		}

        //Dropdown Lists
		get businessLineDropdown() : Array<BusinessLineTableView>
		{
			return SectionStore.BusinessLinesDropdown;
		}
		get techAreaDropdown() : Array<TechAreaModel>
		{		
			return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		}
		get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		{
			return SectionStore.SupplyTipologiesDropdown.filter(st => st.Code == "02" || st.Code == "03");
		}

        get Errors() : Map<string, Array<string>>
        {
            return InternalProjectStore.CreateInternalProjectHeader.Errors;
        }

        beforeDestroy() : void
        {
            InternalProjectStore.SET_CREATE_INTERNALPROJECT_HEADER_ERRORS(new Map<string, Array<string>>());
            InternalProjectStore.ResetCreateInternalProjectCommand();
        }
    }
