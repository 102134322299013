































    import { Component } from "vue-property-decorator";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    //  Order Requirements Review section
    import PgaOrderRequirementsReviewEdit from "./sections/PgaOrderRequirementsReviewEdit.vue";
    import { CustomerOrderStore } from "../../store";
    import { SharedModule } from "@/feature/Shared/store";
    

	@Component( {
        components:
        {
            PgaOrderRequirementsReviewEdit,
        }
    })
    export default class PgaBusinessStartupCustomerOrderEdit extends mixins(SharedMixin)
    {
        get panelsOpened() : Array<number>
        {
            return [0];
        }

        // Errors check
        get hasErrorsOrderRequirementsReview() : boolean
        {
            return CustomerOrderStore.OrderRequirementsReviewEdit.Errors.size > 0;
        }

        private undo() : void
        {
            this.$router.go(-1);
        }

        private async Save() : Promise<void>
        {
            SharedModule.SET_ISLOADING(true);
            let allSaved = true;
            try
            {
                await CustomerOrderStore.EditOrderRequirementsReview();
            }
            catch
            {
                allSaved = false;
            }
            SharedModule.SET_ISLOADING(false);
            if(allSaved)
            {
                this.$router.go(-1);
            }
        }

        beforeDestroy() : void
		{
            CustomerOrderStore.ResetOrderRequirementsReviewEdit();
		}
    }
