







































































    import { Component, Model } from "vue-property-decorator";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    import { OfferEvaluationInternalProjectDetailsView } from "@/services/iOrderService";
    
    // Fixed Price Project
    import PgaOfferEvaluationFixedPriceProjectDetails from "./sub-components/PgaOfferEvaluationFixedPriceProjectDetails.vue";
    // Details Internal Project
    import PgaOfferEvaluationDetailsInternalProjectDetails from "./sub-components/PgaOfferEvaluationDetailsInternalProjectDetails.vue";
    // Attachments
    import PgaOfferEvaluationAttachmentsDetails from "./sub-components/PgaOfferEvaluationAttachmentsDetails.vue";

	@Component( {
        components:
        {
            PgaOfferEvaluationFixedPriceProjectDetails,

            PgaOfferEvaluationDetailsInternalProjectDetails,

            PgaOfferEvaluationAttachmentsDetails,
        }
    })
    export default class PgaOfferEvaluationInternalProjectDetails extends mixins(SharedMixin)
    {
        @Model("", { type: Object }) readonly Model!: OfferEvaluationInternalProjectDetailsView;

        private panelsOpened = [0, 1, 2];

        get title() : string
        {
            return this.Model.Title;
        }
        get date() : Date
        {
            return new Date(this.Model.Date);
        }
        get businessLine() : string
        {
            return this.Model.BusinessLine;
        }
        get techArea() : string
        {
            return this.Model.TechArea;
        }
        get supplyTipology() : string
        {
            return this.Model.SupplyTipology;
        }

        //Approval
        get approvalProjectDG() : boolean
        {
            return this.Model.ApprovalProjectDG;
        }

        get approvalSpendingBudgetDG() : boolean
        {
            return this.Model.ApprovalSpendingBudgetDG;
        }

        get reviewSG() : boolean
        {
            return this.Model.ReviewSG;
        }
    }
