










































import Vue from "vue";
import { Component, Model} from "vue-property-decorator";
import { PgaValutazioneOffertaSubtotaliAltreComponentiModel } from "../store";

@Component({
  computed: {
    subImportoOffertaComponenti: {
      get() {
        return this.$props.Model.SubImportoOffertaComponenti;
      }
    },
    subCostoAttesoComponenti: {
      get() {
         return this.$props.Model.SubCostoAttesoComponenti;
      }
    },
    subMargineAttesoComponenti: {
      get() {
         return this.$props.Model.SubMargineAttesoComponenti;
      }
    },
    subMargineAttesoPercComponenti: {
      get() {
         return this.$props.Model.SubMargineAttesoPercComponenti;
      }
    }
  }
})
export default class PgaValutazioneOffertaSubtotaliAltreComponenti extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaSubtotaliAltreComponentiModel;
}
