















	import { Component, Model } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { TotalCostsPresaleSectionView } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalCostsPresaleDetails extends mixins( SharedMixin )
	{
        @Model("", { type: Object }) readonly Model!: TotalCostsPresaleSectionView;

        // Total Amounts 
		get totalCostsPresale (): number
		{
			return this.Model.TotalCosts;
		}
	}
