
































































































































































































    import { Component } from 'vue-property-decorator';
    import { clearTimeout, setTimeout } from 'timers';
    import { Guid } from 'node_modules/guid-typescript/dist/guid';
    
    import { mixins } from "vue-class-component";
    import SharedMixin from "@/mixins/SharedMixin";
    
    import { CustomerOrderStore } from '../../../store';
    import { SharedModule } from "@/feature/Shared/store";
    
    import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel, PlaceType } from '@/services/iOrderService';

    import PgaOtherCustomerReferralCreate from "../sub-components/PgaOtherCustomerReferralCreate.vue";
    import PgaDeliverableCreate from "../sub-components/PgaDeliverableCreate.vue";

    @Component({
        components:
        {
            PgaOtherCustomerReferralCreate,
            PgaDeliverableCreate,
        }
    })
    export default class PgaOrderRequirementsReviewCreate extends mixins(SharedMixin)
    {
        private dropdownLoading = false;
        private showFirstDropdown = false;
        private showSecondDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly placeDropdown : Array<any> = 
        [
            {
                Name: 'Proge',
                Value: 1,
            },
            {
                Name: 'Customer',
                Value: 2,
            },
        ];

        get acom() : string
        {
            return SharedModule.Account?.name?.toUpperCase() ?? "";
        }

        get customer() : string
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Customer;
        }
        set customer(value: string)
        {
            this.showFirstDropdown = false;
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_CUSTOMER(value);
        }
        private setCustomerManually(value: string)
		{
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_CUSTOMER(value);

            // cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.customersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestCustomers(value);
                }, 500);
                this.showFirstDropdown = true;
            }
            else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

        get finalCustomer() : string
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.FinalCustomer;
        }
        set finalCustomer(value: string)
        {
            this.showSecondDropdown = false;
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_FINAL_CUSTOMER(value);
        }
        private setFinalCustomerManually(value: string)
		{
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_FINAL_CUSTOMER(value);

            // cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.customersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestCustomers(value);
                }, 500);
                this.showSecondDropdown = true;
            }
            else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

        get description() : string
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Description;
        }
        set description(value: string)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_DESCRIPTION(value);
        }

        get advanceSupply() : boolean
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.AdvanceSupply;
        }
        set advanceSupply(value: boolean)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_ADVANCE_SUPPLY(value)
        }

        get startDate() : Date
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.StartDate;
        }
        set startDate(value: Date)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_START_DATE(value);
        }

        get expectedEndDate() : Date
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.ExpectedEndDate;
        }
        set expectedEndDate(value: Date)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_EXPECTED_END_DATE(value);
        }

        get place() : PlaceType
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Place;
        }
        set place(value: PlaceType)
        {
            if(value === 1)
                CustomerOrderStore.SET_CREATE_CUSTOMERORDER_ADDRESS(null);
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_PLACE(value);
        }

        get address() : string | null
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Address;
        }
        set address(value: string | null)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_ADDRESS(value);
        }

        get customerContractManager() : string
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.CustomerContractManager;
        }
        set customerContractManager(value: string)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_CUSTOMER_CONTRACT_MANAGER(value);
        }

        get otherCustomerReferralsCount() : number
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.OtherCustomerReferrals.length;
        }
        private addOtherCustomerReferral() : void
        {
            CustomerOrderStore.ADD_CREATE_CUSTOMERORDER_OTHER_CUSTOMER_REFERRALS();
        }

        get deliverableCount() : number
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Deliverables.length;
        }
        private addDeliverable() : void
        {
            CustomerOrderStore.ADD_CREATE_CUSTOMERORDER_DELIVERABLES();
        }

		get businessLine() : Guid | null
		{
			return CustomerOrderStore.CustomerOrderCreate.Command.BusinessLineId;
		}
		set businessLine(value: Guid | null)
		{
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_TECHAREAID(null);
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_BUSINESSLINEID(value);
		}

		get techArea() : Guid | null
		{
			return CustomerOrderStore.CustomerOrderCreate.Command.TechAreaId;
		}
		set techArea(value: Guid | null)
		{
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_TECHAREAID(value);
		}

		get supplyTipology() : Guid | null
		{
			return CustomerOrderStore.CustomerOrderCreate.Command.SupplyTipologyId;
		}
		set supplyTipology(value: Guid | null)
		{
			CustomerOrderStore.SET_CREATE_CUSTOMERORDER_SUPPLYTIPOLOGYID(value);
		}

        //TODO: add Deliverables list

        get privacy() : boolean
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Privacy;
        }
        set privacy(value: boolean)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_PRIVACY(value)
        }

        get notes() : string
        {
            return CustomerOrderStore.CustomerOrderCreate.Command.Notes;
        }
        set notes(value: string)
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_NOTES(value);
        }

        //Dropdown Lists
		get businessLineDropdown() : Array<BusinessLineTableView>
		{
            return CustomerOrderStore.BusinessLinesDropdown;
		}
		get techAreaDropdown() : Array<TechAreaModel>
		{		
            return CustomerOrderStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		}
		get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		{
            return CustomerOrderStore.SupplyTipologiesDropdown;
		}
        get customersDropdown() : Array<string>
        {
            return CustomerOrderStore.CustomersDropdown.map(c => c.BusinessName);
        }

		private async requestCustomers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await CustomerOrderStore.GetCustomersDropdown(search);
			this.dropdownLoading = false;
		}

        get Errors() : Map<string, Array<string>>
        {
            return CustomerOrderStore.CustomerOrderCreate.Errors;
        }

        beforeDestroy() : void
        {
            CustomerOrderStore.SET_CREATE_CUSTOMERORDER_ERRORS(new Map<string, Array<string>>());
        }
    }
