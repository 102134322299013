















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
@Component({
  data: () => ({}),
  computed: {
    ImponibileComplessivo: {
      get() {
        return CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaProgettoACorpoCreate.ImponibileComplessivo;
      },
      set(value) {
        CreateOfferModule.SET_IMPORTONIBILECOMPLESSIVO(value);
      }
    }
  }
})
export default class PgaValutazioneOffertaProgettoACorpoCreate extends Vue {}
