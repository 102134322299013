























































	import { Component, Model } from 'vue-property-decorator';
	
	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	import { SectionStore } from "../../../SectionStore";
	import { OtherCostPresaleDetailsView } from "@/services/iOrderService";

	@Component({})
	export default class PgaOfferEvaluationOtherCostsPresaleDetails extends mixins(SharedMixin)
	{
        @Model("", { type: Object }) readonly Model!: OtherCostPresaleDetailsView;

		get description() : string
		{
			return this.Model.Description;
		}

		get supplierCode() : string
		{
			return this.Model.SupplierCode;
		}

		get supplierBusinessName() : string
		{
			return this.Model.SupplierBusinessName;
		}

		get expectedCost() : number
		{
			return this.Model.ExpectedCost;
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditOtherCostsPresale.Errors;
		}
	}
