













































	import { Component } from "vue-property-decorator";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

    import { SectionStore } from "../../../SectionStore";
    import { EditFixedPriceProjectCommand, EditHumanResourcesCommand, EditOtherComponentsCommand } from "@/services/iOrderService";

	@Component( {} )
	export default class PgaOfferEvaluationTotalAmountsCreate extends mixins( SharedMixin )
	{
        get editFixedPrice() : EditFixedPriceProjectCommand
        {
            return SectionStore.EditFixedPriceProject.Command;
        }
        get editHumanResources() : EditHumanResourcesCommand
        {
            return SectionStore.EditHumanResources.Command;
        }
        get editOtherComponents() : EditOtherComponentsCommand
        {
            return SectionStore.EditOtherComponents.Command;
        }

        // Total Amounts 
		get offerAmounts (): number
		{
            const totalOfferAmountsHR = this.editHumanResources.Resources.length > 0 ?
                    this.editHumanResources.Resources
                        .map(r => r.DailyPriceOffer * r.OfferedMen)
                        .reduce((sum, current) => sum + current) : 0;
            const totalOfferAmountsOC = (this.editOtherComponents.Others.length > 0 ?
					this.editOtherComponents.Others
						.map(o => o.OfferAmount)
						.reduce((sum, current) => sum + current) : 0)
				+ this.editOtherComponents.ProductsPurchase.OfferAmount
				+ this.editOtherComponents.SolutionsPurchase.OfferAmount
				+ this.editOtherComponents.ServicesPurchase.OfferAmount
				+ this.editOtherComponents.TransferCosts.OfferAmount
				+ this.editOtherComponents.SecurityCosts.OfferAmount;
			return this.editFixedPrice.TotalTaxableAmount + totalOfferAmountsHR + totalOfferAmountsOC;
		}

		get expectedCosts (): number
		{
            const totalExpectedCostsHR = this.editHumanResources.Resources.length > 0 ?
                this.editHumanResources.Resources
                    .map(r => 
                    {
                        if(r.ExpectedMen > 0)
							return r.MiniumDailyPrice * r.ExpectedMen;
						else
							return r.MiniumDailyPrice * r.OfferedMen;
                    })
                    .reduce((sum, current) => sum + current) : 0;
            const totalExpectedCostsOC = (this.editOtherComponents.Others.length > 0 ?
                this.editOtherComponents.Others
					.map(o => o.ExpectedCost)
					.reduce((sum, current) => sum + current) : 0)
				+ this.editOtherComponents.ProductsPurchase.ExpectedCost
				+ this.editOtherComponents.SolutionsPurchase.ExpectedCost
				+ this.editOtherComponents.ServicesPurchase.ExpectedCost
				+ this.editOtherComponents.TransferCosts.ExpectedCost
				+ this.editOtherComponents.SecurityCosts.ExpectedCost;
			return totalExpectedCostsHR + totalExpectedCostsOC;
		}

        get expectedMargins (): number
		{
			return this.offerAmounts - this.expectedCosts;
		}

        get expectedMarginsPerc (): string
		{
            if(this.offerAmounts > 0)
               return ((this.expectedMargins / this.offerAmounts) * 100).toFixed(2);
            return "0";
		}

		beforeDestroy (): void
		{
			SectionStore.ResetEditTotalAmountsSectionCommand();
		}
	}
