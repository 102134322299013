




























































































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateOfferModule } from "../store";
import { OfferServiceModule } from "../../store";
import { PgaValutazioneOffertaAggiuntiviPresaleCreateModel } from "../store";

@Component({
  data: () => ({
  }),

  computed: {
    ItemsNomeRisorsaPresale: {
      get() {
        return OfferServiceModule.getNOMERISORSAPRESALE;
      }
    },
    NomeRisorsaPresale: {
      get() {
        return this.$props.Model.NomeRisorsaPresale;
      },
      set(value: string) {
        CreateOfferModule.SET_NOMERISORSAPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    BusinessLinePresaleItems: {
      get() {
        return OfferServiceModule.getBUSINESSLINEPRESALE;
      }
    },
    BusinessLinePresale: {
      get() {
        return this.$props.Model.BusinessLinePresale;
      },
      set(value: string) {
        CreateOfferModule.SET_BUSINESSLINEPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TechAreaPresaleItems: {
      get() {
        return OfferServiceModule.getTECHAREAPRESALE(
          CreateOfferModule.ModuloValutazioneOfferta.PgaValutazioneOffertaAggiuntiviPresaleCreate[
            this.$props.index
          ].BusinessLinePresale
        );
      }
    },
    TechAreaPresale: {
      get() {
        return this.$props.Model.TechAreaPresale;
      },
      set(value: string) {
        CreateOfferModule.SET_TECHAREAPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    TipologiaFornituraPresaleItems: {
      get() {
        return OfferServiceModule.getTIPOLOGIAFORNITURAPRESALE;
      }
    },
    TipologiaFornituraPresale: {
      get() {
        return this.$props.Model.TipologiaFornituraPresale;
      },
      set(value: string) {
        CreateOfferModule.SET_TIPOLOGIAFORNITURAPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    FiguraProfessionalePresale: {
      get() {
        return this.$props.Model.FiguraProfessionalePresale;
      },
      set(value: string) {
        CreateOfferModule.SET_FIGURAPROFESSIONALEPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    SocietaPresale: {
      get() {
        return this.$props.Model.SocietaPresale;
      },
      set(value: string) {
        CreateOfferModule.SET_SOCIETAPRESALE({
          index: this.$props.index,
          value: value
        });
      }
    },
    GGUomoOffPresale: {
      get() {
        return this.$props.Model.GGUomoOffPresale;
      },
      set(value: number) {
        CreateOfferModule.UpdateGiorniUomoOffertaPresale({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    CostoGiornalieroPresale: {
      get() {
        return this.$props.Model.CostoGiornalieroPresale;
      },
      set(value: number) {
        CreateOfferModule.UpdateCostoGiornalieroPresale({
          index: this.$props.index,
          value: parseFloat(value.toString())
        });
      }
    },
    CostoAttesoPresale: {
      get() {
        return this.$props.Model.CostoAttesoPresale;
      }
    },
  },
})
export default class PgaValutazioneOffertaAggiuntiviPresaleCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaAggiuntiviPresaleCreateModel;
  async mounted() {
   const a = await OfferServiceModule.RetrievePokemons();
  }

  @Emit()
  removePgaValutazioneOffertaAggiuntiviPresaleCreate() {
    CreateOfferModule.REMOVE_AGGIUNTIVI_PRESALE(this.index);
  }
}
