




























































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaValutazioneOffertaTotaliOffertaCreateModel } from "../store";

@Component({
  computed: {
    importoOffertaTotale: {
      get() {
        return this.$props.Model.ImportoOffertaTotale;
      }
    },
    costoAttesoTotale: {
      get() {
        return this.$props.Model.CostoAttesoTotale;
      }
    },
    margineAttesoTotale: {
      get() {
        return this.$props.Model.MargineAttesoTotale;
      }
    },
    margineAttesoPercTotale: {
      get() {
        return this.$props.Model.MargineAttesoPercTotale;
      }
    },
    sconto: {
      get() {
        return this.$props.Model.Sconto;
      }
    },
    importoOffertaTotaleScontato: {
      get() {
        return this.$props.Model.ImportoOffertaTotaleScontato;
      }
    },
    costoAttesoTotaleScontato: {
      get() {
        return this.$props.Model.CostoAttesoTotaleScontato;
      }
    },
    margineAttesoTotaleScontato: {
      get() {
        return this.$props.Model.MargineAttesoTotaleScontato;
      }
    },
    margineAttesoPercTotaleScontato: {
      get() {
        return this.$props.Model.MargineAttesoPercTotaleScontato;
      }
    }
  }
})
export default class PgaValutazioneOffertaTotaliOffertaCreate extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaTotaliOffertaCreateModel;
}
