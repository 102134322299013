






















































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { DetailsInternalProjectView } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationDetailsInternalProjectDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: DetailsInternalProjectView;
	
	get description() : string
	{
		return this.Model.Description;
	}

	get daysMenExpected() : number
	{
		return this.Model.DaysMenExpected;
	}

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}

	get market() : boolean
	{
		return this.Model.Market;
	}

	get internalUse() : boolean
	{
		return this.Model.InternalUse;
	}

	get acom() : string
	{
		return this.Model.ACOM;
	}
}
