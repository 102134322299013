



















import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaValutazioneOffertaSubtotaliPostsaleModel } from "../store";

@Component({
  computed: {
    SubCostoAttesoPostsale: {
      get() {
        return this.$props.Model.SubCostoAttesoPostsale;
      }
    }
  }
})
export default class PgaValutazioneOffertaSubtotaliPostsale extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaValutazioneOffertaSubtotaliPostsaleModel;
}
