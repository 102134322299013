



































































import { Component, Model, Prop } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { AdditionalCostPostsaleDetailsView } from "@/services/iOrderService";

@Component({})
export default class PgaOfferEvaluationAdditionalCostsPostsaleDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: AdditionalCostPostsaleDetailsView;
	@Prop({ type: String, default: ""}) readonly tipology!: string;

	get businessLine() : string
	{
		return this.Model.BusinessLine;
	}

	get techArea() : string
	{
		return this.Model.TechArea;
	}

	get supplyTipology() : string
	{
		return this.Model.SupplyTipology;
	}

	get description() : string
	{
		return this.Model.Description;
	}

	get expectedCost() : number
	{
		return this.Model.ExpectedCost;
	}
}
