










































import { Guid } from 'guid-typescript';

import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';
import { CustomerOrderStore } from '../../CustomerOrderStore';
import { OfferEvaluationCustomerOrderTableView, ReassignmentSingleACOMCommand } from '@/services/iOrderService';
import { UserSimple } from '@/services/iIdentityService';

@Component({})
export default class PgaReassignmentAcom extends mixins(SharedMixin)
{
    private isLoading = false;
	private ListCustomerOrders = new Array<OfferEvaluationCustomerOrderTableView>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private headers =
		[
			{
				text: "ACOM",
				align: 'start',
				sortable: true,
				value: "ACOM",
				keySearch: "ac",
			},
			{
				text: "Customer",
				align: 'start',
				sortable: true,
				value: "CustomerName",
				keySearch: "cn",
			},
			{
				text: "Offer Description",
				align: 'start',
				sortable: true,
				value: "Description",
				keySearch: "de",
			},
			{
				text: "Order Protocol/Contract",
				align: 'start',
				sortable: true,
				value: "OrderProtocol",
				keySearch: "op",
			},
			{
				text: "CRM Deal Id",
				align: 'start',
				sortable: true,
				value: "CRMDealId",
				keySearch: "crm",
			},
			{
				text: "ACOM Incoming",
				align: 'start',
				sortable: false,
				value: "ACOMIncoming",
			},
		];

	// ACOM Outcoming
	get ACOMOutcomingId() : Guid
	{
		return CustomerOrderStore.ReassignACOMCustomerOrder.Command.ACOMOutcomingId;
	}
	set ACOMOutcomingId(value: Guid)
	{
		CustomerOrderStore.SET_REASSIGN_ACOM_CUSTOMERORDER_ACOM_OUT_ID(value);
	}

	get ACOMDropdown() : Array<UserSimple>
	{
		return CustomerOrderStore.ACOMDropdown;
	}

	// ACOM Incoming
    get listCustomerOrders() : Array<OfferEvaluationCustomerOrderTableView>
    {
        return this.ListCustomerOrders;
    }
	set listCustomerOrders(value: Array<OfferEvaluationCustomerOrderTableView>)
	{
		this.ListCustomerOrders = value;
	}

    get selectedCustomerOrders() : Array<OfferEvaluationCustomerOrderTableView>
    {
		const customerOrdersSelected: Array<OfferEvaluationCustomerOrderTableView> = new Array<OfferEvaluationCustomerOrderTableView>();
        CustomerOrderStore.ReassignACOMCustomerOrder.Command?.Reassignments.forEach(r => customerOrdersSelected.push(
			{
				Id: r.CustomerOrderId,
				ACOM: "",
				CustomerName: "",
				Description: '',
				OrderProtocol: '',
				Blobs: []
			}));
        return customerOrdersSelected ?? new Array<OfferEvaluationCustomerOrderTableView>();
    }
    set selectedCustomerOrders(value: Array<OfferEvaluationCustomerOrderTableView>)
    {
        const reassignments: Array<ReassignmentSingleACOMCommand> = new Array<ReassignmentSingleACOMCommand>();
        value.forEach(co => reassignments.push(
			{
				CustomerOrderId: co.Id,
				ACOMIncomingId: this.ACOMOutcomingId,
			}));

        CustomerOrderStore.SET_REASSIGN_ACOM_CUSTOMERORDER_REASSIGNMENTS(reassignments);
    }

    private getACOMIncoming(customerOrderId: Guid) : Guid
    {
        const reassignment = CustomerOrderStore.ReassignACOMCustomerOrder.Command?.Reassignments
            .find(r => r.CustomerOrderId.toString() === customerOrderId.toString());
        return reassignment?.ACOMIncomingId ?? this.ACOMOutcomingId;
    }

    private showACOMIncoming(customerOrderId: Guid): boolean
    {
        return this.selectedCustomerOrders
			.some(co => co.Id.toString() === customerOrderId.toString());
    }

    private setACOMIncoming(customerOrderId: Guid, ACOMIncomingId: Guid): void
    {
        const reassignment: ReassignmentSingleACOMCommand = 
        {
            CustomerOrderId: customerOrderId,
            ACOMIncomingId: ACOMIncomingId,
        };
        CustomerOrderStore.SET_REASSIGN_ACOM_CUSTOMERORDER_REASSIGNMENT_ROW(reassignment);
    }

	private async getCustomerOrdersByACOMId(acomId: Guid) : Promise<void>
	{
		this.isLoading = true;
		this.ListCustomerOrders = await CustomerOrderStore.ListOfferEvaluationCustomerOrdersByACOMId(acomId);
		CustomerOrderStore.SET_REASSIGN_ACOM_CUSTOMERORDER_REASSIGNMENTS(new Array<ReassignmentSingleACOMCommand>());
		this.isLoading = false;
	}

	private getErrorKeyACOMIncoming(customerOrderId: Guid) : string
	{
		const keyIndex = CustomerOrderStore.ReassignACOMCustomerOrder.Command.Reassignments
			.findIndex(r => r.CustomerOrderId === customerOrderId);
		return `Reassignments[${keyIndex}].ACOMIncomingId`;
	}

    get Errors() : Map<string, Array<string>>
    {
        return CustomerOrderStore.ReassignACOMCustomerOrder.Errors;
    }
}
