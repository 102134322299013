


















    import { Component } from "vue-property-decorator";
    
    import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
    
    //  Order Requirements Review section
    import PgaProjectRequirementsReviewDetails from "./sections/PgaProjectRequirementsReviewDetails.vue";
    import { BusinessStartupInternalProjectDetailsView } from "@/services/iOrderService";
    import { InternalProjectStore } from "../../store";
    

	@Component( {
        components:
        {
            PgaProjectRequirementsReviewDetails,
        }
    })
    export default class PgaBusinessStartupInternalProjectDetails extends mixins(SharedMixin)
    {
        private get internalProject() : BusinessStartupInternalProjectDetailsView
        {
            return InternalProjectStore.InternalProjectDetails;
        }

        get panelsOpened() : Array<number>
        {
            return [0];
        }
    }
