

























































































































































































































	import { Component, Prop } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';
	import { clearTimeout, setTimeout } from 'timers';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	
	import { BusinessLineTableView, TechAreaModel, TipologiaFornituraModel } from '@/services/iOrderService';
	import { UserSimple } from "@/services/iIdentityService";
	import { SectionStore } from "../../../SectionStore";

	@Component({})
	export default class PgaOfferEvaluationHumanResourcesCreate extends mixins( SharedMixin )
	{
		@Prop({ type: Number, default: 0 }) readonly index!: number;
		@Prop({ default: false, type: Boolean }) readonly restricted!: boolean;

		private dropdownLoading = false;
		private showDynamicDropdown = false;
        private _timerId!: ReturnType<typeof setTimeout>;

		private IsExternalResource = false;
		get isExternalResource() : boolean
		{
			return this.IsExternalResource;
		}
		set isExternalResource(value: boolean)
		{
			this.IsExternalResource = value;
			SectionStore.SET_EDIT_HUMANRESOURCES_RESOURCENAME_ID({ index: this.index, resourceNameId: null});
			SectionStore.SET_EDIT_HUMANRESOURCES_RESOURCENAME({ index: this.index, resourceName: ""});
		}

		get errorSubSection() : string
		{
			let color = "";
			this.Errors.forEach((value, key) =>
			{
				if(key.includes(`Resources[${this.index}]`))
					color = "error";
			});
			return color;
		}

		//Details Human Resource: first row
		get businessLine() : Guid | null
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].BusinessLineId;
		}
		set businessLine(value: Guid | null)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_TECHAREA_ID({ index: this.index, techAreaId: null });
			SectionStore.SET_EDIT_HUMANRESOURCES_BUSINESSLINE_ID({ index: this.index, businessLineId: value });
		}

		get techArea() : Guid | null
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].TechAreaId;
		}
		set techArea(value: Guid | null)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_TECHAREA_ID({ index: this.index, techAreaId: value });
		}

		get supplyTipology() : Guid | null
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].SupplyTipologyId;
		}
		set supplyTipology(value: Guid | null)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_SUPPLYTIPOLOGY_ID({ index: this.index, supplyTipologyId: value });
		}

		//Details Human Resource: second row
		get professionalFigure() : string
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].Professional;
		}
		set professionalFigure(value: string)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_PROFESSIONAL({ index: this.index, professional: value });
		}

		get company() : string
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].Company;
		}
		set company(value: string)
		{
			this.showDynamicDropdown = false;
			SectionStore.SET_EDIT_HUMANRESOURCES_COMPANY({ index: this.index, company: value });
		}
		private setCompanyManually(value: string)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_COMPANY({ index: this.index, company: value });

            // cancel pending call
            clearTimeout(this._timerId)

            if(value.length >= 3 && !this.suppliersDropdown.includes(value) && this._timerId != undefined)
            {
                // delay new call 500ms
                this._timerId = setTimeout(() =>
                {
                    this.requestSuppliers(value);
                }, 500);
				this.showDynamicDropdown = true;
            }
			else
            {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this._timerId = setTimeout(function() { }, 0);
            }
		}

		get user() : UserSimple | null
		{
			return this.usersDropdown
				.find(u => SectionStore.EditHumanResources.Command.Resources[this.index].ResourceNameId == u.Id) ?? null;
		}
		set user(value: UserSimple | null)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_RESOURCENAME_ID({ index: this.index, resourceNameId: value?.Id ?? null});
		}
		get userName() : string
		{
			return this.usersDropdown.find(u => u.Id === this.user?.Id)?.FullName 
				?? SectionStore.EditHumanResources.Command.Resources[this.index].ResourceName;
		}

		get externalUser() : string
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].ResourceName;
		}
		set externalUser(value: string)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_RESOURCENAME({ index: this.index, resourceName: value});
		}

		//Details Human Resource: third row
		get expectedMen() : number
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].ExpectedMen;
		}
		set expectedMen(value: number)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_EXPECTEDMEN({ index: this.index, expectedMen: value });
		}

		get offeredMen() : number
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].OfferedMen;
		}
		set offeredMen(value: number)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_OFFEREDMEN({ index: this.index, offeredMen: value });
		}

		get riskMargin() : string
		{
			if(this.offeredMen == 0)
				return "0";
			return ((this.offeredMen - this.expectedMen) / this.offeredMen * 100).toFixed(2);
		}

		get dailyPriceOffer() : number
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].DailyPriceOffer;
		}
		set dailyPriceOffer(value: number)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_DAILYPROCEOFFER({ index: this.index, dailyPriceOffer: value });
		}

		get offerAmount() : number
		{
			return this.dailyPriceOffer * this.offeredMen;
		}

		get miniumDailyPrice() : number
		{
			return SectionStore.EditHumanResources.Command.Resources[this.index].MiniumDailyPrice;
		}
		set miniumDailyPrice(value: number)
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_MINIUMDAILYPRICE({ index: this.index, miniumDailyPrice: value });
		}

		//Details Human Resource: fourth row
		get expectedCost() : number
		{
			if(this.expectedMen > 0)
				return this.miniumDailyPrice * this.expectedMen;
			return this.miniumDailyPrice * this.offeredMen;
		}

		get expectedMargin() : number
		{
			return this.offerAmount - this.expectedCost;
		}

		get expectedMarginPerc() : string
		{	
			if(this.offerAmount == 0)
				return "0";
			return ((this.expectedMargin / this.offerAmount) * 100).toFixed(2);
		}

		//Dropdown Lists
		get businessLineDropdown() : Array<BusinessLineTableView>
		{
			return SectionStore.BusinessLinesDropdown;
		}
		get techAreaDropdown() : Array<TechAreaModel>
		{		
			return SectionStore.TechAreasDropdown.filter(ta => ta.BusinessLine.Id === this.businessLine);
		}
		get supplyTipologyDropdown() : Array<TipologiaFornituraModel>
		{
			return SectionStore.SupplyTipologiesDropdown;
		}
		get usersDropdown() : Array<UserSimple>
		{
			return SectionStore.UsersDropdown;
		}
		get suppliersDropdown() : Array<string>
		{
			return SectionStore.SuppliersDropdown.map(s => s.BusinessName);
		}
		get professionalsDropdown() : Array<string>
		{
			return SectionStore.ProfessionalsDropdown;
		}

		private async requestSuppliers(search: string) : Promise<void>
		{
			this.dropdownLoading = true;
			await SectionStore.GetSuppliersDropdown(search);
			this.dropdownLoading = false;
		}

		get Errors() : Map<string, Array<string>>
		{
			return SectionStore.EditHumanResources.Errors;
		}

		private removeResource() : void
		{
			SectionStore.SET_EDIT_HUMANRESOURCES_REMOVE_RESOURCE(this.index);
		}
	}
